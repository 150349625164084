import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import RiseLoading from "../Loader/RiseLoader";
export default function PaymentConfirmation() {
    const [showloader, setshowLoader] = useState(true);
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        if (location.pathname.split("/").length === 4) {
            localStorage.setItem("redirectId", location.pathname.split("/")[3]);
        }
        setTimeout(() => {
            setshowLoader((showloader) => false);
            history.replace('/flight/review-booking');
        }, 2000);
    }, [])

    return (
        <>   {
            showloader ?
                <RiseLoading />
                : ''}
        </>
    );
}