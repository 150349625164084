import React, { useState, useEffect } from "react";
import "./SearchCategoryConfiguration.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import { useParams } from "react-router-dom";
import SearchCategoryConfigurationTable from "./SearchCategoryConfigurationTable/SearchCategoryConfigurationTable";
import SearchCategoryConfigurationDrawer from "./SearchCategoryConfigurationDrawer/SearchCategoryConfigurationDrawer";
import AddSearchCategoryConfigurationCard from "./AddSearchCategoryConfigurationCard/AddSearchCategoryConfigurationCard";
import { createTheme } from "@material-ui/core/styles";
import axios from "axios";
import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { isUserHasPermission } from "../../../services/AuthService";
import RiseLoading from "../../Loader/RiseLoader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SearchCategoryConfiguration() {
  let { id } = useParams();
  let { name } = useParams();
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [categoryAction, setCategoryAction] = useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [categoryConfiguration, setcategoryConfiguration] = useState([]);
  const [supplierName, setSupplierName] = useState("");
  const [pcc, setPCC] = useState("");
  const [isActive, setIsActive] = useState("")
  const [travelType, settravelType] = useState("");
  const [cabinClass, setcabinClass] = useState("");
  const [filteredSearchCategory, setFilteredSearchCategory] = useState([]);
  const [pccList, setPccList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [reset, setReset] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [openErrorMessageDialog, setOpenErrorMessageDialog] = useState(false);

  const history = useHistory();
  useEffect(() => {
    loadCategoryConfiguration(false);
    loadPCCDetails();
  }, []);

  const theme = createTheme({
    overrides: {
      MuiDrawer: {
        paperAnchorDockedRight: {
          styleOverrides: {
            width: "200% !important",
          },
        },
      },
    },
  });

  async function loadCategoryConfiguration(search) {
    setShowLoader(true);
    let filter;
    if (search) {
      filter =
        "SearchCategoryId==" +
        id +
        ",supplierNameType==" +
        '' +
        ",pcc==" +
        '' +
        ",travelType==" +
        '';
    } else {
      filter = "SearchCategoryId==" + id;
    }

    await axios
      .get(
        process.env.REACT_APP_CORE_URL +
        "api/Configuration/SearchCategoryConfiguration?filters=" +
        filter
      )
      .then((response) => {
        response.data.map((item) => {
          if (item.searchCategoryConfigurations.isActive) {
            item.searchCategoryConfigurations.status = "active";
          } else {
            item.searchCategoryConfigurations.status = "inactive";
          }
        });
        setcategoryConfiguration(response.data);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log("Error on Search Category Block =>", err);
        setOpenErrorMessageDialog(true);
        setShowLoader(false);
      });
  }

  async function loadPCCDetails() {
    let vendorArray = [];
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          vendorArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        axios
          .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
          .then((response) => {
            let tmpArray = [];
            for (var i = 0; i < response.data.length; i++) {
              if (
                vendorArray.some(
                  (item) => item.id === response.data[i].pccConfigs.vendorId
                )
              ) {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label:
                    response.data[i].pccConfigs.pccCode +
                    " (" +
                    vendorArray.find(
                      (st) => st.id === response.data[i].pccConfigs.vendorId
                    ).name +
                    ")",
                  status: response.data[i].pccConfigs.isActive,
                  supplierName: response.data[i].pccConfigs.supplierName
                });
              } else {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label: response.data[i].pccConfigs.pccCode,
                  status: response.data[i].pccConfigs.isActive
                });
              }
            }
            // console.log(tmpArray);
            setPccList(tmpArray);
          });
      });
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(value);
    if (value === false) {
      loadCategoryConfiguration(false);
    }
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = () => {
    setDrawer(false);
    setSnackbarOpen(true);
    loadCategoryConfiguration(false);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const filterSearchCategory = () => {

    const filteredSearchCategories = categoryConfiguration.filter((block) => {
      const category = block.searchCategoryConfigurations;
      const supplierCheck = supplierName === "" || category.supplierNameType.toLowerCase().includes(supplierName.toLowerCase());
      const pccCodeCheck = pcc === "" || category.pcc.toLowerCase().includes(pcc.toLowerCase());
      const isActiveCheck = isActive === undefined || isActive === null || isActive === "" || isActive.toLowerCase() === category.status.toString();
      return (
        supplierCheck &&
        pccCodeCheck &&
        isActiveCheck
      );
    });

    setReset(false);
    setFilteredSearchCategory(filteredSearchCategories);
    setPage(0);
  };

  const ClearFilters = () => {
    setSupplierName(""); // Reset supplierName
    setPCC("");          // Reset pcc
    setIsActive("");     // Reset isActive
    setReset(true);     // Set reset flag to false  

  };

  useEffect(() => {
    if (reset) {
      loadCategoryConfiguration(true);
      setFilteredSearchCategory([]);

    }
  }, [reset])


  useEffect(() => {
    filterSearchCategory()
  }, [categoryConfiguration])

  return (
    <div className="search-container ">
      <div className="search-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-search-container">
              <div className="search-table-container">
                <div className="search-title-add-button-container">
                  <div className="search-heading">
                    <IconButton
                      aria-label="Back"
                      onClick={() => history.goBack()}
                    >
                      <ArrowBack />
                    </IconButton>
                    <p> Search Category Configuration - {name}</p>
                  </div>
                  <div className="search-icon-button-container">
                    <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon>
                    {/* {isUserHasPermission("SearchCategoryWrite") ? ( */}
                    <div
                      className="add-search-button mt-0px"
                      onClick={() => {
                        setAction("new");
                        setDrawer(true);
                      }}
                    >
                      Add New Block
                    </div>
                    {/* ) : (
                      ""
                    )} */}
                  </div>
                </div>


                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="search-card-container">
                    <AddSearchCategoryConfigurationCard
                      supplierName={supplierName}
                      setSupplierName={setSupplierName}
                      pcc={pcc}
                      setPCC={setPCC}
                      // settravelType={settravelType}
                      // setcabinClass={setcabinClass}
                      isActive={isActive}
                      setIsActive={setIsActive}
                      loadCategoryConfiguration={loadCategoryConfiguration}
                      statusList={["Active", "Inactive"]}
                      ClearFilters={ClearFilters}
                      filterSearchCategory={filterSearchCategory}
                    ></AddSearchCategoryConfigurationCard>
                  </div>
                </Collapse>
                <SearchCategoryConfigurationTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  categoryConfiguration={categoryConfiguration}
                  pccList={pccList}
                  name={name}
                  filteredSearchCategory={filteredSearchCategory}
                  page={page}
                  setPage={setPage}
                ></SearchCategoryConfigurationTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        theme={theme}
        anchor="right"
        open={drawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <SearchCategoryConfigurationDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
            pccList={pccList}
            catid={id}
            name={name}
          ></SearchCategoryConfigurationDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {`Setting has been ${action === "new" ? "created" : "updated"}`}
        </Alert>
      </Snackbar>

      <Dialog
        open={openErrorMessageDialog}
        onClose={() => setOpenErrorMessageDialog(false)}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
        className="error-dialog-box"
      >
        <DialogTitle id="error-dialog-title" className="main-heading-cls">
          Notification
        </DialogTitle>
        <DialogContent className="mt-3 py-2">
          <DialogContentText id="error-dialog-description">
            <Row>
              Something Went Wrong!
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="error-dialog-action text-center my-2">
          <Button variant="outlined" onClick={() => setOpenErrorMessageDialog(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {showLoader && <RiseLoading />}
    </div>
  );
}

export default SearchCategoryConfiguration;
