import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import "./Header.css";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
export function Megamenu({ closeMenu }) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const handleMenuClick = (event) => {
    if (event.target.className == "menu_item") {
      closeMenu();
    } else {
      return;
    }
  };

  const handleLinkClick = (event) => {

    if (location.pathname === '/OrderTraceReport') {
      localStorage.removeItem('orderTraceReportData');
      window.location.reload();
    } else {
      history.push('/OrderTraceReport');
    }
  };

  return (
    <Container onClick={handleMenuClick}>
      <Row className="menu_row">
        <Col>
          <h5 className="menu_sub_header">Queue &amp; Others</h5>
          <Link className="menu_item" to="/PendingApprovalQueue">
            {t("Pending Approval Queue")}
          </Link>
          <Link className="menu_item" to="/QueueTable">
            {t("My Booking Queue")}
          </Link>
          <Link className="menu_item" to="/FSH">
            {t("FSH")}
          </Link>
          <Link className="menu_item" to="/importbooking">
            {t("Import Booking")}
          </Link>
          <Link className="menu_item" to="/OfflineBooking">
            {t("Offline Booking")}
          </Link>
          <Link className="menu_item" to="/flight/ViewCancellation">
            {t("View Cancellation")}
          </Link>
          <Link className="menu_item" to="/ImportPNR">
            {t("Import PNR Ticketing")}
          </Link>
        </Col>
        <Col>
          <h5 className="menu_sub_header">Finance</h5>
          <Link className="menu_item" to="/DebitTransactions/0">
            {t(" Debit Transactions")}
          </Link>
          <Link className="menu_item" to="/Reco">
            {t("Flight Reconcilliation")}
          </Link>
          <Link className="menu_item" to="/BankInfo">
            {t("Bank Info")}
          </Link>
          <Link className="menu_item" to="/OperatingOND">
            {t(" Operating OND")}
          </Link>
          <Link className="menu_item" to="/Recharge">
            {t("Recharge Transactions")}
          </Link>
        </Col>

        <Col>
          <h5 className="menu_sub_header">Configurations</h5>
          <Link className="menu_item" to="/Supplier">
            {t("PCC")}
          </Link>
          <Link className="menu_item" to="/SupplierCommissionGroup">
            {t("Supplier Commission")}
          </Link>
          <Link className="menu_item" to="/SearchCategory">
            {t("Search Category")}
          </Link>
          <Link className="menu_item" to="/L1CacheGroup">
            {t("L1 Cache Group")}
          </Link>
          <Link className="menu_item" to="/TicketingConfiguration">
            {t("Ticketing Configuration")}
          </Link>
          <Link className="menu_item" to="/MarkupGroup">
            {t("Markup")}
          </Link>
          <Link className="menu_item" to="/PassthroughConfiguration">
            {t("Passthrough Configuration")}
          </Link>
        </Col>

        <Col>
          <h5 className="menu_sub_header">{t("Administration")}</h5>
          <Link className="menu_item" to="/ManageClients">
            {t("Clients")}
          </Link>
          <Link className="menu_item" to="/ManageUser/0">
            {t("Staffs")}
          </Link>
          <Link className="menu_item" to="/Vendor">
            {t("Vendors")}
          </Link>
          <Link className="menu_item" to="/RoleRights">
            {t("Roles & Rights")}
          </Link>
          <Link className="menu_item" to="/ClientOnBoardingQueue">
            {t("Client Onboarding Queue")}
          </Link>
        </Col>
        <Col>
          <h5 className="menu_sub_header">Void</h5>
          <Link className="menu_item" to="/VoidAdmin">
            {t("Void Management")}
          </Link>
          <Link className="menu_item" to="/VoidRulesConfiguration">
            {t("Add Void Configuration")}
          </Link>
          <Link className="menu_item" to="/VoidQuote">
            {t("Void Quote")}
          </Link>
          <Link className="menu_item" to="/Payment">
            {t("Payment")}
          </Link>
        </Col>
        <Col>
          <h5 className="menu_sub_header">Analytics</h5>
          <Link className="menu_item" to="/Dashboard">
            {t("My Dashboard")}
          </Link>
          <Link className="menu_item" to="/ClientBalanceReport">
            {t("Client Balance Report")}
          </Link>
          <Link className="menu_item" to="/OrderTraceReport" onClick={() => handleLinkClick()}>
            {t("Order Trace Report")}
          </Link>
          <Link className="menu_item" to="/OfflineImportReport">
            {t("Booking Report")}
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
