import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import TopUp from "./TopUp";
import RechargeTransactions from "./RechargeTransactions";
import "./TopUp.css";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PaymentStatus from "../../data/usePaymentStatus";
import Grid from "@mui/material/Grid";
import axios from "axios";
import CurrencyCodeComponent from "../../providers/CurrencyCode";
import getTransactionDetails from "../../functions/GetTransactionDetails";
import getClientData from "../../functions/getClientData";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function WalletTopUp() {
  const [open, setOpen] = React.useState(false);
  const [paymentGatewayId, setPaymentGatewayId] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState({
    paymentId: "",
    paymentStatus: "",
    rechargeRefNo: "",
    pgRefNo: "",
    amount: 0,
    currency: "",
    failureMessage: "",
  });
  const location = useLocation();
  const { t } = useTranslation("translation");
  React.useEffect(() => {
    if (location.pathname.split("/").length === 3) {
      let temp = location.pathname.split("/");
      getTransactionDetails(temp[2]).then((response) => {
        setState((prev) => {
          return {
            ...prev,
            paymentId: response.paymentId,
            paymentStatus: response.paymentStatus,
            rechargeRefNo: response.rechargeRefNo,
            pgRefNo: response.pgRefNo,
            amount: response ? response.amount : 0,
            currency: response ? response.currency : "",
            failureMessage: response ? response.failureMessage : "",
          };
        });
        setOpen(true);
      });
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    getClients();
  })
  const handleClose = () => {
    window.history.replaceState(null, null, `${window.location.origin}/Recharge`);
    setOpen(false);
  };
  const getClients = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null && user !== undefined) {
      let response = await getClientData(user["clientref_id"]);
      setPaymentGatewayId((prev) => response?.data[0]?.clientpforile.paymentGateWayListId);
    }

  }
  return (
    <Grid container className=" topupContainer p-2">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h3
            className={PaymentStatus[state.paymentStatus]}
            style={{ textAlign: "center" }}
          >
            {" "}
            {t("Top up")} {PaymentStatus[state.paymentStatus]}{" "}
          </h3>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <p style={{ color: "#000" }} id="alert-dialog-description">
            {t("your wallet top up")} {PaymentStatus[state.paymentStatus]} {t("for amount")}{" "}
            <span className="walletTopupAmountText">
              <strong>
                <CurrencyCodeComponent
                  style={{ float: "none" }}
                  currencyCode={state.currency}
                />{" "}
                {state.amount}
              </strong>
            </span>
          </p>
          <p style={{ color: "#000" }} id="alert-dialog-description">
            {t("Order Reference")}: <strong>{state.paymentId}</strong>
            <br />
            {t("Transaction ID")}: <strong>{state.pgRefNo}</strong>
            <br />
            {PaymentStatus[state.paymentStatus] === "Success" && state.rechargeRefNo ? (
              <>
                {t("Recharge Ref No")}: <strong>{state.rechargeRefNo}</strong>
              </>
            ) : (
              <> {
                state.failureMessage ? <>
                  {t("Fail Reason")}: <strong>{state.failureMessage}</strong>
                </>
                  : ''
              }</>

            )}
            {
              (PaymentStatus[state.paymentStatus] === "Success" && paymentGatewayId === 2) &&
              <span className="walletTopupAmountText" style={{ textTransform: "capitalize" }}> {t("Payment Gateway Charge of")}
                <strong>
                  <CurrencyCodeComponent
                    style={{ float: "none" }}
                    currencyCode={state.currency}
                  />{" "}
                  {(state.amount * 0.02).toFixed(2)}
                </strong> {t("have been Collected")}</span>
            }
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            autoFocus
            sx={{ textTransform: "capitalize" }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        style={{ Height: "100%", Width: "100%" }}
        sx={{ flexGrow: 1, display: "flex" }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            Width: "100%",
            backgroundColor: "#cfcccc47",
            borderRight: 1,
            borderColor: "white",
            borderWidth: "5px",
            flexGrow: 1,
          }}
        >
          <Tab
            className={value === 0 ? "tab-select" : "tab-unselect"}
            label={t("Top Up My Wallet")}
            {...a11yProps(0)}
          >
            <Button variant="contained">{t("Top Up My Wallet")}</Button>
          </Tab>
          <Tab
            className={value === 1 ? "tab-select" : "tab-unselect"}
            label={t("Recharge Transactions Details")}
            {...a11yProps(1)}
          >
            <Button variant="contained">{t("Recharge Transactions Details")}</Button>
          </Tab>
        </Tabs>
        <TabPanel value={value} index={0}>
          <TopUp paymentState={state} paymentGatewayId={paymentGatewayId} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RechargeTransactions clientid={state.paymentId} paymentGatewayId={paymentGatewayId} />
        </TabPanel>
      </Box>
    </Grid>
  );
}
