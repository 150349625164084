import { useTranslation } from "react-i18next";

const usePaymentStatus = () => {
  const { t } = useTranslation();

  return {
    1: t("Pending"),
    2: t("Success"),
    3: t("Failure"),
    4: t("Aborted"),
    5: t("Invalid"),
    6: t("Timeout"),
    7: t("Auto_Cancelled"),
    8: t("Auto_Reversed"),
    9: t("Awaited"),
    10: t("Cancelled"),
    11: t("Fraud"),
    12: t("Initiated"),
    13: t("Refunded"),
    14: t("Shipped"),
    15: t("System_refund"),
    16: t("Unsuccessful"),
    17: t("Refused"),
  };
};

export default usePaymentStatus;