import React, { useState, useEffect } from "react";
import "./SupplierCommissionBlock.css";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import { useParams } from "react-router-dom";
import SupplierCommissionBlockTable from "./SupplierCommissionBlockTable/SupplierCommissionBlockTable";
import SupplierCommissionBlockDrawer from "./SupplierCommissionBlockDrawer/SupplierCommissionBlockDrawer";
import AddSupplierCommissionBlockCard from "./AddSupplierCommissionBlockCard/AddSupplierCommissionBlockCard";
import { createTheme } from "@material-ui/core/styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import RiseLoading from "../../Loader/RiseLoader";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SupplierCommissionBlock() {
  let { id } = useParams();
  let { name } = useParams();
  const [drawer, setDrawer] = useState(false);
  const [action, setAction] = useState("new");
  const [categoryAction, setCategoryAction] = useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [commissionBlocks, setCommissionBlocks] = useState(null);
  const [pccList, setPccList] = useState([]);
  const [page, setPage] = React.useState(1);
  const [alertText, setalertText] = useState("");
  const [mode, setMode] = useState("");
  const [filters, setFilters] = useState({
    travelType: [],
    isActive: "",
    airline: [],
    fareType: [],
    pccCode: "",
    cabinClass: [],
    supplierID: "",
    reset: false,
  });
  const [filteredCommissionBlocks, setFilteredCommissionBlocks] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    loadCommissionBlocks(false);
    loadPCCDetails();
  }, []);
  useEffect(() => {
  }, [alertText, mode]);
  const theme = createTheme({
    overrides: {
      MuiDrawer: {
        paperAnchorDockedRight: {
          styleOverrides: {
            width: "200% !important",
          },
        },
      },
    },
  });

  async function loadCommissionBlocks(search) {
    setShowLoader(true);
    let filter,
      supplierCommIDFilter = "",
      fareTypeFilter = "",
      pccCodeFilter = "";
    if (filters.supplierID) {
      supplierCommIDFilter = ",id==" + "";
    }
    if (filters.fareType) {
      fareTypeFilter = ",faretype@=" + "";
    }
    if (filters.pccCode) {
      pccCodeFilter = ",pccCode@=" + "";
    }

    if (filters.supplierID !== "" || filters.fareType !== "" || filters.pccCode !== "") {
      if (search) {
        setPage(1);
      }
      filter =
        "supplierCommissionGroupId==" +
        id +
        supplierCommIDFilter +
        fareTypeFilter +
        pccCodeFilter +
        "&page=" +
        page;
    } else {
      filter = "supplierCommissionGroupId==" + id + "&page=" + page;
    }

    // if(search){
    //   filter = "supplierCommissionGroupId=="+id+supplierCommIDFilter+fareTypeFilter+pccCodeFilter;      
    // } else {
    //   filter = "supplierCommissionGroupId==" + id;
    // }

    try {
      await axios
        .get(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/SupplierCommissionBlock?filters=" +
          filter
        )
        .then((response) => {
          setCommissionBlocks(response.data);
          setShowLoader(false);
        });
    } catch (err) {
      console.log("Error on searching commission Block =>", err);
      setShowLoader(false);
    }

  }

  async function loadPCCDetails() {
    let vendorArray = [];
    await axios
      .get(process.env.REACT_APP_CORE_URL + "api/Configuration/Vendor")
      .then((response) => {
        for (var i = 0; i < response.data.length; i++) {
          vendorArray.push({
            id: response.data[i].bookingSwitches.id,
            name: response.data[i].bookingSwitches.vendorName,
          });
        }
        axios
          .get(process.env.REACT_APP_CORE_URL + "api/Configuration/PccConfig")
          .then((response) => {
            let tmpArray = [];
            for (var i = 0; i < response.data.length; i++) {
              if (
                vendorArray.some(
                  (item) => item.id === response.data[i].pccConfigs.vendorId
                )
              ) {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label: response.data[i].pccConfigs.pccCode,
                  label:
                    response.data[i].pccConfigs.pccCode +
                    " (" +
                    vendorArray.find(
                      (st) => st.id === response.data[i].pccConfigs.vendorId
                    ).name +
                    ")",
                });
              } else {
                tmpArray.push({
                  value:
                    response.data[i].pccConfigs.pccCode +
                    "," +
                    response.data[i].pccConfigs.supplierName,
                  label: response.data[i].pccConfigs.pccCode,
                  label: response.data[i].pccConfigs.pccCode,
                });
              }
            }
            // console.log(tmpArray);
            setPccList(tmpArray);
          });
      });
  }

  const toggleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const toggleDrawer = (value) => {
    setDrawer(value);
  };

  const openDrawer = (selectedItem) => {
    setSelectedItem(selectedItem);
    setDrawer(true);
  };

  const handleSnackbarToggle = (text, mode) => {
    setalertText(text);
    setMode(mode);
    setDrawer(false);
    setSnackbarOpen(true);
    loadCommissionBlocks(false);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (commissionBlocks && commissionBlocks[0]?.supplierCommissionBlocks) {
      filterCommissionBlocks();
    }
  }, [commissionBlocks])

  const filterCommissionBlocks = () => {
    const filteredCommissionBlocks = commissionBlocks.filter((block) => {
      const commission = block.supplierCommissionBlocks;
      const supplierCheck = !filters.supplierID || commission.id.toString().includes(filters.supplierID);
      const pccCodeCheck = !filters.pccCode || commission.pccCode.toLowerCase().includes(filters.pccCode.toLowerCase());
      const isActiveCheck = filters.isActive === undefined || filters.isActive === null || filters.isActive === "" || (filters.isActive === "Active" ? "true" : "false") === commission.isActive.toString();

      const airlineCodes = commission.airline.split(",");
      const airlineCheck = !filters.airline.length ||
        filters.airline.some((air) => {
          const matches = airlineCodes.includes(air.Code);
          return matches;
        });

      const fareTypes = commission.fareType.split(",");
      const fareTypeCheck = !filters.fareType.length ||
        filters.fareType.some((fare) => {
          const matches = fareTypes.includes(fare);
          return matches;
        });

      const cabinClasses = commission.cabinClass.split(",");
      const cabinClassCheck = !filters.cabinClass.length ||
        filters.cabinClass.some((cabin) => {
          const matches = cabinClasses.includes(cabin.value);
          return matches;
        });

      const commissionTravelTypes = commission.travelType.split(",");
      const travelTypeCheck = !filters.travelType.length ||
        filters.travelType.some((type) => commissionTravelTypes.includes(type));

      return (
        supplierCheck &&
        pccCodeCheck &&
        isActiveCheck &&
        airlineCheck &&
        fareTypeCheck &&
        cabinClassCheck &&
        travelTypeCheck
      );
    });

    setFilters((prevState) => ({
      ...prevState,
      reset: false
    }));

    setFilteredCommissionBlocks(filteredCommissionBlocks);
  };

  const ClearFilters = () => {
    setFilters({
      travelType: [],
      isActive: "",
      airline: [],
      fareType: [],
      pccCode: "",
      cabinClass: [],
      supplierID: "",
      reset: true
    });
  }

  useEffect(() => {
    if (filters.reset) {
      loadCommissionBlocks(true);
      setFilteredCommissionBlocks([]);
    }
  }, [filters])


  return (
    <div className="supplier-container">
      <div className="supplier-content-container">
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div className="search-supplier-container">
              <div className="supplier-table-container">
                <div className="supplier-title-add-button-container">
                  <div className="supplier-heading">
                    <IconButton aria-label="Back" onClick={() => history.goBack()}>
                      <ArrowBack />
                    </IconButton>
                    <p>Supplier Commission Blocks - {name}</p>
                  </div>
                  <div className="search-icon-button-container">
                    <SearchIcon
                      fontSize="small"
                      className="search-icon-margin "
                      onClick={() => toggleOpenSearch()}
                    ></SearchIcon>
                    <div
                      className="add-supplier-button mt-0px pointer"
                      onClick={() => {
                        setAction("new");
                        setDrawer(true);
                      }}
                    >
                      Add New Block
                    </div>
                  </div>
                </div>
                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                  <div className="supplier-card-container">
                    <AddSupplierCommissionBlockCard
                      filters={filters}
                      setFilters={setFilters}
                      ClearFilters={ClearFilters}
                      filterCommissionBlocks={filterCommissionBlocks}
                      setPage={setPage}
                    ></AddSupplierCommissionBlockCard>
                  </div>
                </Collapse>
                <SupplierCommissionBlockTable
                  openDrawer={openDrawer}
                  setAction={setAction}
                  commissionBlocks={commissionBlocks}
                  name={name}
                  setPage={setPage}
                  page={page}
                  pccList={pccList}
                  loadCommissionBlocks={loadCommissionBlocks}
                  filters={filters}
                  filteredCommissionBlocks={filteredCommissionBlocks}
                ></SupplierCommissionBlockTable>
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>
      <Drawer
        theme={theme}
        anchor="right"
        open={drawer}
        onClose={() => toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
        variant="persistent"
        className="drawer-width"
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <div className="drawer-min-width-40">
          <SupplierCommissionBlockDrawer
            selectedItem={selectedItem}
            toggleDrawer={toggleDrawer}
            action={action}
            setAction={setAction}
            handleSnackbarToggle={handleSnackbarToggle}
            pccList={pccList}
            name={name}
            groupId={id}
            commissionBlocks={commissionBlocks}
          ></SupplierCommissionBlockDrawer>
        </div>
      </Drawer>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
      >
        {alertText && mode == "error" ? (
          <Alert onClose={handleSnackBarClose} severity="error">
            {alertText}
          </Alert>
        ) : (
          <Alert onClose={handleSnackBarClose} severity="success">
            {alertText}
          </Alert>
        )}
      </Snackbar>
      {showLoader && <RiseLoading />}
    </div>
  );
}

export default SupplierCommissionBlock;
