import logFrontEndData from "../utils/logFrontEndData";
const getIP = async () => {
    try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();
        return response && data && data?.ip ? data?.ip : "0.0.0.0";
    } catch (error) {
        console.error("Error fetching IP address:", error);
        logFrontEndData(0, 0, '', 'Fetching Ip', '', JSON.stringify(error), '', '');
        return "0.0.0.0";
    }
};

export default getIP;