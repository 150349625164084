import React, { useState, useEffect } from "react";
import "./AddUser.css";
import CloseIcon from "@material-ui/icons/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { FormControl, CircularProgress, IconButton, InputLabel, NativeSelect, } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { isUserHasPermission } from "../../../services/AuthService";
import RiseLoading from "../../Loader/RiseLoader";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import Otp from "../../Login/Otp/Otp";
import { useTranslation } from "react-i18next";
import AntTabs from "../../../hooks/useTabsStyles";
import AntTab from "../../../hooks/useTabStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="ml-8 mr-8">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function AddUser({
  toggleAddUserDrawer,
  selectedItem,
  setSelectedItem,
  action,
  setAction,
  handleSnackbarToggle,
  handleSubUserSnackbarToggle,
  getClients,
  allRoles,
  clientIdForClientStaff,
  flag,
  setaddUserdrawer
}) {
  const [title, settitle] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [dob, setDob] = useState("");


  const [role, setRole] = useState([]);
  const [roles, setRoles] = useState([]);

  const [isEmailConfirmed, setisEmailConfirmed] = useState(false);
  const [clinetId, setclinetId] = useState(0);
  const [clientRoleId, setclientRoleId] = useState(0);
  const [id, setId] = useState("");
  const [autocompleteValues, setAutocompleteValues] = useState([]);
  const [isImportBtnDisabled, setisImportBtnDisabled] = useState(false);
  const [isreSendBtnDisabled, setisreSendBtnDisabled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [otpPopUp, setOtpPopUp] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [resOtpTime, setResOtpTime] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [expired, setExpired] = useState(0);
  const [resMsg, setResMsg] = useState("");
  const [notValid, setNotValid] = useState(false);
  const [disableOtpInput, setDisableOtpInput] = useState(false);
  const [retryCount, setRetryCount] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [ibossemployeeidIN, setIbossemployeeidIN] = useState("");
  const [ibossemployeeidIT, setIbossemployeeidIT] = useState("");
  const [ibossemployeeidUS, setIbossemployeeidUS] = useState("");
  const [ibossemployeeidUAE, setIbossemployeeidUAE] = useState("");
  const [salesRepCodeIN, setSalesRepCodeIN] = useState("");
  const [salesRepCodeIT, setSalesRepCodeIT] = useState("");
  const [salesRepCodeUS, setSalesRepCodeUS] = useState("");
  const [salesRepCodeUAE, setSalesRepCodeUAE] = useState("");
  const [clientProfile, setClientProfile] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  useEffect(() => {
    if ((window.location.href).includes("ManageClients")) {
      setClientProfile(true);
    }
    else {
      setClientProfile(false);
    }
    setisImportBtnDisabled(false);
    setisreSendBtnDisabled(false);

    if (selectedItem && (action === "edit" || action === "view")) {
      setFormValues(selectedItem);
    } else {
      resetFormValues();
    }

    return () => { };
  }, [selectedItem]);

  const setFormValues = (item) => {
    settitle(item.title);
    setfirstName(item.firstName);
    setlastName(item.lastName);
    setEmail(item.email);
    setContactNumber(item.phoneNumber);
    setDob(item.dob);
    setisEmailConfirmed(item.emailConfirmed);
    setclinetId(item.clinetId);
    setclientRoleId(item.clientRoleId);
    setId(item.id);
    setIsActive(item.isEnabled);
    getUserRoles(item.id, item.clinetId);
    setAutocompleteValues(
      allRoles && role && filterObjectArray(allRoles, role)
    );
    setSalesRepCodeIN(item.salesRepCodeIN)
    setSalesRepCodeIT(item.salesRepCodeIT)
    setSalesRepCodeUAE(item.salesRepCodeUAE)
    setSalesRepCodeUS(item.salesRepCodeUS)
    setIbossemployeeidIN(item.ibossemployeeidIN)
    setIbossemployeeidUS(item.ibossemployeeidUS)
    setIbossemployeeidUAE(item.ibossemployeeidUAE)
    setIbossemployeeidIT(item.ibossemployeeidIT)
    setSelectedTab(0);
  };

  const resetFormValues = () => {
    settitle("");
    setfirstName("");
    setlastName("");
    setEmail("");
    setContactNumber("");
    setDob("");
    setisEmailConfirmed("");
    setSelectedTab(0);
  };


  async function getUserRoles(selectdUserId, userClientid) {
    if (userClientid === undefined || userClientid === null || userClientid === 0) {
      await axios
        .get(
          process.env.REACT_APP_IDENTITY_SERVER +
          "ids/UserRoles/GetRoles/" +
          selectdUserId
        )
        .then((response) => {
          setRole(response.data.roles);
          // console.log(response.data.isActive)
          // setIsActive(response.data.isActive);
        });
    }
    else {
      await axios
        .get(process.env.REACT_APP_CORE_URL + "api/Configuration/ClientPermissions/GetClientRoles/" + userClientid)
        .then((response) => {
          let rolesArr = [];
          for (let i = 0; i < response.data.length; i++) {
            rolesArr.push({
              roleId: response.data[i].roleId,
              roleName: response.data[i].roleName,
            });
          }
          setRoles(rolesArr);
        });
    }
  }

  function handleRegisterUser() {

    setisreSendBtnDisabled(true);
    const headers = {
      "Content-Type": "application/json",
    };
    let url =
      process.env.REACT_APP_IDENTITY_SERVER + "ids/User/RegisterNewClient/";

    axios
      .post(
        url,
        JSON.stringify({
          email: email,
          isStaff: clientIdForClientStaff > 0 ? false : true,
          clientId: clientIdForClientStaff,
        }),
        {
          headers,
        }
      )
      .then((res) => {
        // console.log(res);
        res.data.statusCode == 400 ? handleSnackbarToggle(res.data.message, 'error') : handleSnackbarToggle(res.data.message, 'success');
        updateUserRole();
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarToggle(err.response.data.detail, 'error');
      });

    getClients();
  }

  const filterObjectArray = (arr, filterArr) =>
    arr.filter((el) => filterArr.some((f) => f === el.name));

  const handleChange = (event, value) => {
    // console.log(value);
    setAutocompleteValues(value);
    // console.log(autocompleteValues);
  };


  useEffect(() => {
    if ((isActive == "true" || isActive == true) && showOtp) {
      setOtpPopUp(true);
      setShowLoader(false);
    }
    else {
      setOtpPopUp(false);
      setOtp(new Array(4).fill(''));
    }
  }, [showOtp])

  let text = 'User detail updated successfully';
  async function updateUserRole() {
    setShowLoader(true);
    setisImportBtnDisabled(true);
    const user = JSON.parse(localStorage.getItem("user"));

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      let url;
      let data;
      if (clinetId === undefined || clinetId === null || clinetId === 0) {
        url = process.env.REACT_APP_IDENTITY_SERVER + "ids/UserRoles/AddToRoles/";
        data = {
          userId: selectedItem.id,
          roles: autocompleteValues.map(role => role.name),
          isActive: JSON.parse(isActive),
          MainUserId: user["user_id"]
        };
        await axios.post(url, JSON.stringify(data), { headers }).then((response) => {

          handleSnackbarToggle(t(text), 'success');
          // getClients();
          setaddUserdrawer(false);
          setShowLoader(false);
          setAction("view");
        })
          .catch((err) => {
            console.log(err)
          });
      } else {
        url = process.env.REACT_APP_IDENTITY_SERVER + `ids/UserRoles/AddRolesToUser/${id}/${user["user_id"]}/${clientRoleId}/${isActive}/${otp.join('') === '' ? 0 : otp.join('')}`;
        await axios.put(url, {}, { headers }).then((response) => {
          const updatedItem = { ...selectedItem, isEnabled: isActive, clientRoleId };

          setShowLoader(false);

          if (otp.join('') === '' && (isActive === "true") && response.data.toLowerCase().includes('not activated')) {
            const dateTimeString = response.data.slice(16);
            const expiryTime = new Date(dateTimeString);
            const expiryTimeUTC = new Date(expiryTime.getTime() - expiryTime.getTimezoneOffset() * 60000);
            setResOtpTime(expiryTimeUTC);
            setOtpPopUp(true);
            setShowOtp(true);
            setisImportBtnDisabled(false);
          }
          else if (otp.join('') !== '' && (isActive === "true")) {
            handleSubUserSnackbarToggle(t(text), 'success');
            setOtpPopUp(false);
            setOtp(new Array(4).fill(''));
            // setSelectedItem(updatedItem);
            // setFormValues(updatedItem);
            setisImportBtnDisabled(false);
            getClients();
            setAction("view");
            setaddUserdrawer(false);
          }
          else if (response && response?.data && response?.data?.result && response?.data?.result?.succeeded && response?.data?.result?.succeeded === true) {
            handleSubUserSnackbarToggle(t(text), 'success');
            setOtpPopUp(false);
            // setOtp(new Array(4).fill(''));
            // setSelectedItem(updatedItem);
            // setFormValues(updatedItem);
            setShowOtp(false);
            getClients();
            setaddUserdrawer(false);
            setAction("view");
          }
          else {
            handleSubUserSnackbarToggle('User detail updated successfully', 'success');
            setAction("view");
            setaddUserdrawer(false);
            getClients();
          }
        })
          .catch((err) => {
            setShowLoader(false);
            console.log(err)
            if ((err && err.response && err.response.data).toLowerCase().includes("invalid otp")) {
              setResMsg(t("Invalid OTP"));
            }
            else if ((err && err.response && err.response.data).toLowerCase().includes("retry count:")) {
              setResMsg(t("too_many_attempts"));
              localStorage.setItem("otpFail", true);
              setDisableOtpInput(true);
              setRetryCount(true);
            }
            setOtp(new Array(4).fill(''));
            setNotValid(true);
            setisImportBtnDisabled(false);
          });;
      }
    } catch (err) {
      console.log(err);
      setShowLoader(false);
      setAction("view");
      handleSnackbarToggle(err?.response?.data?.detail, 'error');
    }
  }

  const handleOtpPopClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOtpPopUp(false);
    setOtp(new Array(4).fill(''));
  }

  useEffect(() => {
    const otpInvalid = localStorage.getItem("otpFail");
    if (otpInvalid && otpPopUp) {
      setDisableOtpInput(true);
      setNotValid(true);
      setResMsg(t("Invalid OTP"));
    }
  }, [otpPopUp])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const updateIbossDetails = async () => {
    setShowLoader((showLoader) => true);
    const user = JSON.parse(localStorage.getItem("user"));
    const headers = {
      "Content-Type": "application/json",
    };
    await axios
      .post(
        process.env.REACT_APP_IDENTITY_SERVER +
        "ids/User/UpdateIbossDetails",
        JSON.stringify({
          userId: id,
          MainUserId: user["user_id"],
          SalesRepCodeIN: salesRepCodeIN,
          SalesRepCodeIT: salesRepCodeIT,
          SalesRepCodeUAE: salesRepCodeUAE,
          SalesRepCodeUS: salesRepCodeUS,
          IbossEmployeeIdIN: parseFloat(ibossemployeeidIN || 0),
          IbossEmployeeIdUS: parseFloat(ibossemployeeidUS || 0),
          IbossEmployeeIdUAE: parseFloat(ibossemployeeidUAE || 0),
          IbossEmployeeIdIT: parseFloat(ibossemployeeidIT || 0)
        }),
        { headers }
      )
      .then((response) => {
        setShowLoader((showLoader) => false);
        handleSubUserSnackbarToggle(t(text), 'success');
        setaddUserdrawer(false);
        getClients();
      })
      .catch((error) => {
        setShowLoader((showLoader) => false);
      });
  }

  return (
    <div className="ticket-drawer-container">
      <div className="close-button-container">
        <CloseIcon
          className="icon-fill pointer"
          fontSize="medium"
          onClick={() => {
            setaddUserdrawer(false);
            setAction("view")
          }}
        ></CloseIcon>
      </div>
      <div className="">
        {!clientProfile && <AntTabs value={selectedTab} onChange={handleTabChange}>
          <AntTab label={t("User Details")} />
          <AntTab label={t("Iboss Details")} />
        </AntTabs>}

        <TabPanel value={selectedTab} index={0}>
          <div>
            <div className="configuration-heading-container configuration-title-flex-container">
              <div>{t("User Details")}</div>
              {action === "view"
                // && isEmailConfirmed 
                && (
                  <div className="configuration-heading-icons">
                    <IconButton fontSize="small">
                      <EditRoundedIcon
                        fontSize="small"
                        className="icon-blue"
                        onClick={() => setAction("edit")}
                      ></EditRoundedIcon>
                    </IconButton>
                  </div>
                )}
            </div>

            <div className="">
              <ValidatorForm
                onError={(errors) => console.log(errors)}
                onSubmit={updateUserRole}
              >
                <div className="configuration-heading-container configuration-title-flex-container">
                  <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                    <div className="view-configuration-drawer-title">{t("Title")}</div>
                    <div className="view-configuration-drawer-description ">
                      {title}
                    </div>
                  </div>
                  <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                    <div className="view-configuration-drawer-title">
                      {t("First Name")}
                    </div>
                    <div className="view-configuration-drawer-description ">
                      {firstName}
                    </div>
                  </div>
                  <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                    <div className="view-configuration-drawer-title">{t("Last Name")}</div>
                    <div className="view-configuration-drawer-description ">
                      {lastName}
                    </div>
                  </div>
                </div>
                <div className="configuration-heading-container configuration-title-flex-container">
                  <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                    <div className="view-configuration-drawer-title">{t("Email")}</div>
                    <div className="view-configuration-drawer-description ">
                      {email}
                    </div>
                  </div>
                  <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                    <div className="view-configuration-drawer-title">{t("Contact Number")}</div>
                    <div className="view-configuration-drawer-description ">
                      {contactNumber}
                    </div>
                  </div>
                  <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                    <div className="view-configuration-drawer-title">{t("DOB")}</div>
                    <div className="view-configuration-drawer-description ">
                      {moment(dob).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </div>
                <div className="configuration-heading-container configuration-title-flex-container">
                  {action !== "view" && (clinetId === undefined || clinetId === 0) && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        color="secondary"
                        value={autocompleteValues && autocompleteValues}
                        onChange={handleChange}
                        size="small"
                        multiple
                        id="tags-outlined"
                        options={allRoles}
                        getOptionLabel={(options) => options.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t("User Roles")}
                            placeholder={t("User Roles")}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action !== "view" && clinetId > 0 && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <Autocomplete
                        id="role select"
                        value={roles.find((v) => v.roleId === clientRoleId) || null}
                        onChange={(event, newValue) => {
                          setclientRoleId(newValue ? newValue.roleId : "");
                        }}
                        options={roles}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(options) => options.roleName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={t("User Roles")}
                            placeholder={t("User Roles")}
                            color="secondary"
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  {action === "view" && (clinetId === undefined || clinetId === 0) && (
                    <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        {t("User Roles")}
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {role && role.map((row) => <p>{row}</p>)}
                      </div>
                    </div>
                  )}
                  {action === "view" && clinetId > 0 && (
                    <div className="view-configuration-drawer margin-right-16 config-input-flex-1">
                      <div className="view-configuration-drawer-title">
                        {t("User Roles")}
                      </div>
                      <div className="view-configuration-drawer-description ">
                        {roles && roles.map((row) => <p>{row.roleId === clientRoleId ? row.roleName : ""}</p>)}
                      </div>
                    </div>
                  )}
                </div>
                <div className="configuration-heading-container">
                  {action !== "view" && (
                    <div className="configuration-view-edit-drawer-input margin-right-16 config-input-flex-1">
                      <FormControl fullWidth>
                        <InputLabel
                          variant="standard"
                          htmlFor="uncontrolled-native"
                        >
                          {t("Status")}
                        </InputLabel>
                        <NativeSelect
                          variant="outlined"
                          label={t("Status")}
                          size="small"
                          color="secondary"
                          value={isActive}
                          onChange={(event) => setIsActive(event.target.value)}
                        >
                          <option value={true}>{t("Active")}</option>
                          <option value={false}>{t("In-Active")}</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  )}
                  {action === "view" && (
                    <div className="view-vendor-drawer config-input-flex-1">
                      <div className="view-vendor-drawer-title">{t("Status")}</div>
                      <div className="view-vendor-drawer-description">
                        {isActive === "true" || isActive === true ? t("Active") : t("In-Active")}
                      </div>
                    </div>
                  )}
                </div>
                {action !== "view" &&
                  <div className="configuration-heading-container configuration-title-flex-container">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      style={{
                        textTransform: "none",
                      }}
                      type="submit"
                    // disabled={isImportBtnDisabled}
                    >
                      {t("Update")}
                    </Button>
                    {showLoader && (
                      <RiseLoading StaffUser={showLoader} />
                    )}
                  </div>
                }
              </ValidatorForm>

            </div>
          </div>
        </TabPanel>

        <TabPanel value={selectedTab} index={1}>
          <div className="m-4">
            <div className="mb-2">{t("IBoss Details")}</div>
            <ValidatorForm
              onSubmit={updateIbossDetails}
            >
              <div className="">
                <div className="d-flex align-items-center">
                  <div className="me-3 mt-2">
                    <TextField className="border"
                      label={t("Employee ID (India)")}
                      value={ibossemployeeidIN}
                      onChange={(e) => setIbossemployeeidIN(e.target.value)}
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="me-3 mt-2">
                    <TextField className="border"
                      label={t("Employee ID (Italy)")}
                      value={ibossemployeeidIT}
                      onChange={(e) => setIbossemployeeidIT(e.target.value)}
                      variant="standard"
                      size="small"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3 mt-2">
                    <TextField className="border"
                      label={t("Employee ID (US)")}
                      value={ibossemployeeidUS}
                      onChange={(e) => setIbossemployeeidUS(e.target.value)}
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="me-3 mt-2">
                    <TextField className="border"
                      label={t("Employee ID (UAE)")}
                      value={ibossemployeeidUAE}
                      onChange={(e) => setIbossemployeeidUAE(e.target.value)}
                      variant="standard"
                      size="small"
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="me-3 mt-3">
                    <TextField className="border"
                      label={t("Sales Rep Code (India)")}
                      value={salesRepCodeIN}
                      onChange={(e) => setSalesRepCodeIN(e.target.value)}
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="me-3 mt-3">
                    <TextField className="border"
                      label={t("Sales Rep Code (Italy)")}
                      value={salesRepCodeIT}
                      onChange={(e) => setSalesRepCodeIT(e.target.value)}
                      variant="standard"
                      size="small"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3 mt-3">
                    <TextField className="border"
                      label={t("Sales Rep Code (US)")}
                      value={salesRepCodeUS}
                      onChange={(e) => setSalesRepCodeUS(e.target.value)}
                      variant="standard"
                      size="small"
                    />
                  </div>
                  <div className="me-3 mt-3">
                    <TextField className="border"
                      label={t("Sales Rep Code (UAE)")}
                      value={salesRepCodeUAE}
                      onChange={(e) => setSalesRepCodeUAE(e.target.value)}
                      variant="standard"
                      size="small"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  style={{
                    textTransform: "none",
                  }}
                  type="submit"
                >
                  {t("Update Iboss Details")}
                </Button>
              </div>
              {showLoader && (
                <RiseLoading StaffUser={showLoader} />
              )}
            </ValidatorForm>
          </div>
        </TabPanel>
      </div>
      <Dialog
        open={otpPopUp}
        // onClose={() => setOtpPopUp(false)}
        onClose={handleOtpPopClose}
      >
        <Otp
          setOtpPopUp={setOtpPopUp}
          otp={otp}
          setOtp={setOtp}
          subUserStatus={isActive}
          updateUserRole={updateUserRole}
          resOtpTime={resOtpTime}
          setResOtpTime={setResOtpTime}
          setExpired={setExpired}
          resMsg={resMsg}
          setResMsg={setResMsg}
          notValid={notValid}
          setNotValid={setNotValid}
          disableOtpInput={disableOtpInput}
          setDisableOtpInput={setDisableOtpInput}
          userName={email}
          retryCount={retryCount}
          setRetryCount={setRetryCount}
          mode={"edit"}
        />
      </Dialog>
    </div>
  );
}

export default AddUser;
