import React, { useState, useEffect } from "react";
import { Row, Col, Nav, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import CancelIcon from "@mui/icons-material/Cancel";
import DateRangeIcon from "@mui/icons-material/DateRange";
// import FlightModal from "../Modals/FlightModal";
import DescriptionIcon from "@mui/icons-material/Description";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LuggageIcon from "@mui/icons-material/Luggage";
import findAirlinebyCode from "../../../functions/findAirlineByCode";
import findAirportbyCode from "../../../functions/findAirportByCode";
import getTotalFare from "../../../functions/getTotalFare";
import { useHistory } from "react-router";
import moment from "moment";
import getLayoverTime from "../../../functions/getLayoverTime";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import { Button, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import getSSRInfo from "../../../functions/getSSRInfo";
import { useLocation } from "react-router-dom";
import RiseLoading from "../../Loader/RiseLoader";
import {
  isClientUserHasPermission,
  checkIfUserStaff,
  getCurrentRole,
} from "../../../services/AuthService";
import Tickets from "../../SearchResultsPage/Results/Row/Tickets";
import CurrencyCodeComponent from "../../../providers/CurrencyCode";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import airportDetails from "../../../data/AirportDetails";
import BrandedFareComponent from "../../BrandedFareComponents/BrandedFareComponent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AirlineLogoForSearch } from "../../../functions/airlineLogo";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MiniRulesComponent from "../../MiniRules/MiniRulesComponent";
import { filterAndSortBaggage } from "../../../functions/filterAndSortBaggage";
import logFrontEndData from "../../../utils/logFrontEndData";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px",
  maxHeight: "550px",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 2,
  whiteSpace: "break-spaces",
};
export default function ItineraryDetails({
  result,
  search,
  arrayOf,
  currencyCode,
  traceId,
  clientId,
  toggleFDIcon,
  showNetfare,
  itineraryMarkup,
  setItineraryMarkup,
  adultcount,
  childcount,
  infantcount,
  fsData,
  additionalClientmarkup,
  flag,
  adtCount, chdCount, infCount, bookingDetails
}) {
  const { t } = useTranslation();

  const location = useLocation();
  const [showLoader, setShowLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("fareSummary");
  const [selectedItinerary, setSelectedItinerary] = useState(0);
  //Show/Hide FLight Details
  const [toggleFlightDetails, setToggleFlightDetails] = useState(false);
  //Show/Hide Fare Details
  const [toggleFareDetails, setToggleFareDetails] = useState(false);
  //Show/Hide Fare
  const [toggleShowHideFareDtls, setToggleShowHideFareDtls] = useState(false);
  const images = require.context("../../../airline_logo", true);
  const history = useHistory();
  const [fareSegGroups, setFareSegGroups] = useState([]);
  const [baggageList, setBaggageList] = useState([]);
  const [miniRules, setMiniRules] = useState([]);
  const [orgDestDateDisplay, setOrgDestDateDisplay] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [isBrandedFare, setIsBrandedFare] = useState(false);
  const [baggageError, setBaggageError] = useState("");
  const [miniRuleError, setMiniRuleError] = useState("");
  const [
    airportChangeAlertSelectedOnwardText,
    setAirportChangeAlertSelectedOnwardText,
  ] = useState("");
  const [
    airportChangeAlertSelectedReturnText,
    setAirportChangeAlertSelectedReturnText,
  ] = useState("");
  const [airportChangeAlertSearchText, setAirportChangeAlertSearchText] =
    useState("");
  const [airportChangeAlertOpen, setAirportChangeAlertOpen] = useState(false);
  const [eqpTypeAlertOpen, setEqpTypeAlertOpen] = useState(false);
  const [eqpType, setEqpType] = useState("");
  const [markupSnackbar, setMarkupSnackbar] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [bookingAction, setBookingAction] = useState("");
  const path = location.pathname
  const user = JSON.parse(localStorage.getItem("user"));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    setUserRole(getCurrentRole());
  }, [])

  useEffect(() => {
    setToggleShowHideFareDtls(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });

    let count = 0;
    result.fareGroups.forEach((item, index) => {
      if (item.pricedDescription && item.pricedDescription.length > 0) {
        ++count;
      }
    });
    let temp = count > 0 ? true : false;
    setIsBrandedFare((isBrandedFare) => temp);
  }, [result, search]);

  useEffect(() => {
    // console.log("isBrandedFare", isBrandedFare);
    if (isBrandedFare) {
      setActiveTab("fareDescription");
    }
  }, [isBrandedFare]);

  useEffect(() => {
    if (itineraryMarkup > 0) {
      setMarkupSnackbar(true);
      const timer = setTimeout(() => {
        setMarkupSnackbar(false);
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      setMarkupSnackbar(false);
    }
  }, [itineraryMarkup]);

  function handleToggleFDIc(e) {
    if (toggleFlightDetails == true) {
      setToggleFlightDetails(false);
    }
  }

  if (toggleFDIcon.length > 0) {
    handleToggleFDIc();
  }

  function handleToggleClick(event, index) {
    const getAttrData = event.currentTarget.getAttribute("data");
    if (result.fareGroups[index].miniRules != undefined) {
      setMiniRules((mini) =>
        result.fareGroups[index].miniRules
          ? result.fareGroups[index].miniRules
          : []
      );
    } else {
      setMiniRuleError("");
    }

    if (document.getElementById(getAttrData).classList.length == 1) {
      setActiveTab("fareSummary");
    }
    setTimeout(() => {
      document.getElementById(getAttrData).classList.toggle("faredtls_sh_cls");
    }, 200);
  }
  const continueToReval = (index) => {
    logFrontEndData(traceId, clientId, location.pathname, `User clicked Proceed for the popup, "Eqptype Change"`, '', '', '', '');
    const itineraryToUse = index !== undefined ? index : selectedItinerary;
    let bookingActions = localStorage.getItem("bookAction");

    const additionalClientmarkup =
      parseFloat(itineraryMarkup) * search.adult +
      parseFloat(itineraryMarkup) * search.children;

    localStorage.removeItem("paymentState");
    localStorage.removeItem("travelers");
    localStorage.removeItem("reValTimer");
    localStorage.removeItem("currentTicket");
    localStorage.removeItem("paymentAttempt");
    localStorage.removeItem("currentTicket");
    localStorage.removeItem("isDeclarationMandate");
    localStorage.setItem("revalState", JSON.stringify({
      activeSearch: search,
      searchResults: fsData,
      traceId: traceId,
      purchaseId: result.fareGroups[itineraryToUse].purchaseId,
      additionalClientmarkup: additionalClientmarkup,
      clientId: clientId,
      hold: bookingActions === "Book" ? false : result.isHold,
    }));
    history.replace({
      pathname: "/flight/review-booking"
    });
  };

  const checkforEqpTypeAlert = () => {
    logFrontEndData(traceId, clientId, location.pathname, `User clicked Proceed for the popup, "Sector Change Alert"`, '', '', '', '')
    if (eqpType == "Train" || eqpType == "Bus") {
      setEqpTypeAlertOpen((eqpTypeAlertOpen) => true);
      cancelContinueToReval();
    } else {
      continueToReval();
    }
  };

  const cancelContinueToReval = () => {
    logFrontEndData(traceId, clientId, location.pathname, `User clicked cancel for the popup, "Sector Change Alert"`, '', '', '', '');
    setAirportChangeAlertOpen((airportChangeAlertOpen) => false);
  };

  const eqptypecancelContinueToReval = () => {
    logFrontEndData(traceId, clientId, location.pathname, `User clicked cancel for the popup, "Eqptype Change"`, '', '', '', '');
    setEqpTypeAlertOpen((eqpTypeAlertOpen) => false);
  };


  const proceedToBook = (bookAction, index) => {
    let from,
      fromAirport,
      toAirport,
      selectedFromAirport,
      selectedToAirport,
      to,
      onwardFromTo,
      returnFromTo,
      selectedonward,
      selectedReturn,
      seg1eqptype;

    setBookingAction(bookAction);
    localStorage.setItem("bookAction", bookAction);

    result?.segGroups.forEach((segGroup) => {
      if (!seg1eqptype) {
        const busSegment = segGroup.segs.find((item) => item.eqpType === "BUS");
        if (busSegment) {
          seg1eqptype = "BUS";
        }
      }
      if (!seg1eqptype) {
        const trainSegment = segGroup.segs.find(
          (item) => item.eqpType === "TRN"
        );
        if (trainSegment) {
          seg1eqptype = "TRN";
        }
      }
    });

    if (seg1eqptype == "BUS") {
      setEqpType("Bus");
    } else if (seg1eqptype == "TRN") {
      setEqpType("Train");
    } else {
      setEqpTypeAlertOpen(false);
    }

    from = search?.from?.city_code;
    to = search?.to?.city_code;
    onwardFromTo = from + to;
    returnFromTo = to + from;
    fromAirport =
      getAirportData(from)?.airport_name +
      "," +
      getAirportData(from).city +
      " (" +
      from +
      ")";
    toAirport =
      getAirportData(to)?.airport_name +
      "," +
      getAirportData(to).city +
      " (" +
      to +
      ")";
    if (result?.segGroups?.length > 1) {
      selectedonward =
        result?.segGroups[0]?.origin + result?.segGroups[0]?.destination;
      selectedReturn =
        result?.segGroups[1]?.origin + result?.segGroups[1]?.destination;
      if (selectedonward === onwardFromTo && selectedReturn === returnFromTo) {
        if (seg1eqptype == "TRN" || seg1eqptype == "Bus") {
          setEqpTypeAlertOpen(true);
        } else {
          continueToReval(index);
        }
      } else if (
        selectedonward !== onwardFromTo &&
        selectedReturn !== returnFromTo
      ) {
        selectedFromAirport =
          getAirportData(result?.segGroups[0]?.origin)?.airport_name +
          "," +
          getAirportData(result?.segGroups[0]?.origin)?.city +
          " (" +
          result?.segGroups[0]?.origin +
          ") - " +
          getAirportData(result?.segGroups[0]?.destination)?.airport_name +
          "," +
          getAirportData(result?.segGroups[0]?.destination)?.city +
          " (" +
          result?.segGroups[0]?.destination +
          ")";
        selectedToAirport =
          getAirportData(result?.segGroups[1]?.origin)?.airport_name +
          "," +
          getAirportData(result?.segGroups[1]?.origin)?.city +
          " (" +
          result?.segGroups[1]?.origin +
          ") - " +
          getAirportData(result?.segGroups[1]?.destination)?.airport_name +
          "," +
          getAirportData(result?.segGroups[1]?.destination)?.city +
          " (" +
          result?.segGroups[1]?.destination +
          ")";
        // let text1 = `Onward: ${selectedFromAirport}`;
        // let text3 = `Return: ${selectedToAirport}`
        // let text2 = `${fromAirport} - ${toAirport}`;
        setAirportChangeAlertSearchText(
          (airportChangeAlertSearchText) => `${fromAirport} - ${toAirport}`
        );
        setAirportChangeAlertSelectedOnwardText(
          (airportChangeAlertSelectedOnwardText) => `${selectedFromAirport}`
        );
        setAirportChangeAlertSelectedReturnText(
          (airportChangeAlertSelectedReturnText) => `${selectedToAirport}`
        );
        setAirportChangeAlertOpen((airportChangeAlertOpen) => true);
      } else if (
        selectedonward !== onwardFromTo &&
        selectedReturn === returnFromTo
      ) {
        selectedFromAirport =
          getAirportData(result?.segGroups[0]?.origin)?.airport_name +
          "," +
          getAirportData(result?.segGroups[0]?.origin)?.city +
          " (" +
          result?.segGroups[0]?.origin +
          ") - " +
          getAirportData(result?.segGroups[0]?.destination)?.airport_name +
          "," +
          getAirportData(result?.segGroups[0]?.destination)?.city +
          " (" +
          result?.segGroups[0]?.destination +
          ")";
        let text1 = `${selectedFromAirport}`;
        let text2 = `${fromAirport} - ${toAirport}`;
        setAirportChangeAlertSearchText(
          (airportChangeAlertSearchText) => text2
        );
        setAirportChangeAlertSelectedOnwardText(
          (airportChangeAlertSelectedOnwardText) => `${selectedFromAirport}`
        );
        setAirportChangeAlertSelectedReturnText(
          (airportChangeAlertSelectedReturnText) => ""
        );
        setAirportChangeAlertOpen((airportChangeAlertOpen) => true);
      } else if (
        selectedonward === onwardFromTo &&
        selectedReturn !== returnFromTo
      ) {
        selectedToAirport =
          getAirportData(result?.segGroups[1]?.origin)?.airport_name +
          "," +
          getAirportData(result?.segGroups[1]?.origin)?.city +
          " (" +
          result?.segGroups[1]?.origin +
          ") - " +
          getAirportData(result?.segGroups[1]?.destination)?.airport_name +
          "," +
          getAirportData(result?.segGroups[1]?.destination)?.city +
          " (" +
          result?.segGroups[1]?.destination +
          ")";
        let text1 = `${selectedToAirport}`;
        let text2 = `${fromAirport} - ${toAirport}`;
        setAirportChangeAlertSearchText(
          (airportChangeAlertSearchText) => text2
        );
        setAirportChangeAlertSelectedOnwardText(
          (airportChangeAlertSelectedOnwardText) => ``
        );
        setAirportChangeAlertSelectedReturnText(
          (airportChangeAlertSelectedReturnText) => `${selectedToAirport}`
        );
        setAirportChangeAlertOpen((airportChangeAlertOpen) => true);
      }
    } else if (result?.segGroups?.length === 1) {
      selectedonward =
        result?.segGroups[0]?.origin + result?.segGroups[0]?.destination;
      if (selectedonward === onwardFromTo) {
        if (seg1eqptype == "TRN" || seg1eqptype == "Bus") {
          setEqpTypeAlertOpen(true);
        } else {
          continueToReval(index);
        }
      } else if (selectedonward !== onwardFromTo) {
        selectedFromAirport =
          getAirportData(result?.segGroups[0]?.origin)?.airport_name +
          "," +
          getAirportData(result?.segGroups[0]?.origin)?.city +
          " (" +
          result?.segGroups[0]?.origin +
          ")";
        selectedToAirport =
          getAirportData(result?.segGroups[0]?.destination)?.airport_name +
          "," +
          getAirportData(result?.segGroups[0]?.destination)?.city +
          " (" +
          result?.segGroups[0]?.destination +
          ")";
        let text1 = `${selectedFromAirport} - ${selectedToAirport}`;
        let text2 = `${fromAirport} - ${toAirport}`;
        setAirportChangeAlertSearchText(
          (airportChangeAlertSearchText) => text2
        );
        setAirportChangeAlertSelectedOnwardText(
          (airportChangeAlertSelectedOnwardText) => text1
        );
        setAirportChangeAlertOpen((airportChangeAlertOpen) => true);
      }
    }

    // console.log("result", result);
    // console.log("search", search);
  };

  useEffect(() => {
    if (eqpTypeAlertOpen == true && airportChangeAlertOpen == true) {
      setEqpTypeAlertOpen((eqpTypeAlertOpen) => false);
    }

    if (eqpTypeAlertOpen == true && airportChangeAlertOpen == false) {
      setEqpTypeAlertOpen((eqpTypeAlertOpen) => true);
    }
  }, [eqpTypeAlertOpen, airportChangeAlertOpen]);

  const getDifferenceInDays = (dateA, dateB) => {
    const date1 = new Date(
      dateA.includes("+00:00") ? dateA.replace("+00:00", "") : dateA
    ),
      date2 = new Date(
        dateB.includes("+00:00") ? dateB.replace("+00:00", "") : dateB
      );
    const diffInMs = Math.abs(date1 - date2);
    const getDiffInDay = Math.round(diffInMs / (1000 * 60 * 60 * 24));
    let getDiffInDay_msg = "";
    if (getDiffInDay !== "" || getDiffInDay !== 0) {
      if (getDiffInDay === 1) {
        getDiffInDay_msg = "Arrives next day";
      } else if (getDiffInDay > 1) {
        getDiffInDay_msg = "Arrives " + getDiffInDay + " days later";
      }
    }
    return getDiffInDay_msg;
  };

  const getAdultPrice = () => {
    let adultTotalPrice = 0;
    const ticketDetails = result;
    if (
      ticketDetails &&
      ticketDetails.fareGroups[selectedItinerary] !== undefined
    ) {
      ticketDetails.fareGroups[selectedItinerary]?.fares.forEach((fare) => {
        if (fare.paxType === "ADT") {
          adultTotalPrice = adultTotalPrice + fare.base;
        }
      });
    }
    return adultTotalPrice;
  };

  const getChildrenPrice = () => {
    let childrenTotalPrice = 0;
    const ticketDetails = result;
    if (
      ticketDetails &&
      ticketDetails.fareGroups[selectedItinerary] !== undefined
    ) {
      ticketDetails.fareGroups[selectedItinerary]?.fares.forEach((fare) => {
        if (fare.paxType === "CHD") {
          childrenTotalPrice = childrenTotalPrice + fare.base;
        }
      });
    }
    return childrenTotalPrice;
  };

  const getInfantPrice = () => {
    let infantTotalPrice = 0;
    const ticketDetails = result;
    if (
      ticketDetails &&
      ticketDetails.fareGroups[selectedItinerary] !== undefined
    ) {
      ticketDetails.fareGroups[selectedItinerary]?.fares.forEach((fare) => {
        if (fare.paxType === "INF") {
          infantTotalPrice = infantTotalPrice + fare.base;
        }
      });
    }
    return infantTotalPrice;
  };

  const getAdultTaxPrice = () => {
    let adultTotalPrice = 0;
    const ticketDetails = result;
    if (
      ticketDetails &&
      ticketDetails.fareGroups[selectedItinerary] !== undefined
    ) {
      ticketDetails.fareGroups[selectedItinerary]?.fares.forEach((fare) => {
        if (fare.paxType === "ADT") {
          fare.taxes.forEach((item) => {
            adultTotalPrice = adultTotalPrice + item.amt;
          });
        }
      });
    }
    return adultTotalPrice;
  };

  const getChildrenTaxPrice = () => {
    let childrenTotalPrice = 0;
    const ticketDetails = result;
    if (
      ticketDetails &&
      ticketDetails.fareGroups[selectedItinerary] !== undefined
    ) {
      ticketDetails.fareGroups[selectedItinerary]?.fares.forEach((fare) => {
        if (fare.paxType === "CHD") {
          fare.taxes.forEach((item) => {
            childrenTotalPrice = childrenTotalPrice + item.amt;
          });
        }
      });
    }
    return childrenTotalPrice;
  };

  const getInfantTaxPrice = () => {
    let infantTotalPrice = 0;
    const ticketDetails = result;
    if (
      ticketDetails &&
      ticketDetails.fareGroups[selectedItinerary] !== undefined
    ) {
      ticketDetails.fareGroups[selectedItinerary]?.fares.forEach((fare) => {
        if (fare.paxType === "INF") {
          fare.taxes.forEach((item) => {
            infantTotalPrice = infantTotalPrice + item.amt;
          });
        }
      });
    }
    return infantTotalPrice;
  };

  const AirlineLogo = (details) => {
    let imgSrc;
    try {
      // imgSrc = images(`./${details.val}.svg`).default;
      // imgSrc = images(`./${details.val}.png`);
      imgSrc = AirlineLogoForSearch(details);
    } catch (err) {
      console.log(err);
    }

    return (
      <>
        {imgSrc !== undefined ? (
          <>
            <img className="airline_img" src={imgSrc} />
            &nbsp; {result.airline + " | " + findAirlinebyCode(result.airline)}
          </>
        ) : (
          <>
            <FlightTakeoffIcon className="primary-fill" fontSize="small" />{" "}
            &nbsp;
          </>
        )}
      </>
    );
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const BootstrapTooltip1 = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.grey,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.grey,
    },
  }));

  const getDeparture = (departure) => {
    let temp = new Date(
      departure.includes("+00:00") ? departure.replace("+00:00", "") : departure
    );
    return moment(temp).format("HH:mm");
  };

  const getArrival = (arrival) => {
    let temp = new Date(
      arrival.includes("+00:00") ? arrival.replace("+00:00", "") : arrival
    );
    return moment(temp).format("HH:mm");
  };
  const getDuration = (duration) => {
    return `${String(Math.floor(duration / 60)).padStart(2, "0")} h ${String(
      duration % 60
    ).padStart(2, "0")} m`;
  };

  const getBaggageWeight = (baggages, origin, destination) => {
    let baggageWeights = "";
    const joinedPair = origin + destination;
    if (baggages != undefined) {
      const cityPairs = baggages
        .filter((item) => {
          return item.cityPair === joinedPair;
        })
        .sort(function (a, b) {
          if (a.paxType < b.paxType) {
            return -1;
          }
          if (a.paxType > b.paxType) {
            return 1;
          }
          return 0;
        });
      cityPairs.forEach((item, index) => {
        baggageWeights =
          baggageWeights +
          ` ${item.paxType} ` +
          ` ${item.checkInBag}${index !== cityPairs.length - 1 ? "," : " "}  `;
      });
    }
    return baggageWeights;
  };

  const getFareDetails = (k, traceId, purchaseId, clientId, index, arrayOf) => {
    if (result.fareGroups[selectedItinerary]?.miniRules != undefined) {
      setMiniRules((mini) =>
        result.fareGroups[selectedItinerary]?.miniRules
          ? result.fareGroups[selectedItinerary]?.miniRules
          : []
      );
    }

    if (isBrandedFare) {
      if (
        k === "baggages" &&
        result.vendor === "BU" &&
        (result.fareGroups[selectedItinerary]?.baggages === undefined ||
          result.fareGroups[selectedItinerary]?.baggages.length === 0)
      ) {
        setShowLoader(true);
        getSSRInfo(traceId, clientId, [purchaseId]).then((data) => {
          if (
            data.flightDetail != undefined &&
            data.flightDetail.fareFamilies != undefined
          ) {
            //For Baggage
            result.fareGroups[index].baggages =
              data.flightDetail.fareFamilies[0].baggage;
            setBaggageList(
              (baggageList) => data.flightDetail.fareFamilies[0].baggage
            );
            // For Minirules
            result.fareGroups[index].miniRule =
              data.flightDetail.fareFamilies[0].miniRule;
            setMiniRules(
              (miniRules) => data.flightDetail.fareFamilies[0].miniRules
            );

            setFareSegGroups((fareSegGroups) => result.segGroups);
          }
          setShowLoader(false);
          // setTimeout(() => {
          //   document
          //     .getElementById("fareDetails_" + arrayOf + "_" + index)
          //     .classList.toggle("faredtls_sh_cls");
          // }, 10);
          setActiveTab(k);
        });
      } else if (k === "baggages") {
        setBaggageList((baggageList) =>
          result.fareGroups[selectedItinerary]?.baggages
            ? result.fareGroups[selectedItinerary]?.baggages
            : []
        );
        setFareSegGroups((fareSegGroups) => result.segGroups);
        setShowLoader(false);

        // setTimeout(() => {
        //   if (
        //     window.getComputedStyle(
        //       document.getElementById("fareDetails_" + arrayOf + "_" + index)
        //     ).display == "none"
        //   ) {
        //     document
        //       .getElementById("fareDetails_" + arrayOf + "_" + index)
        //       .classList.toggle("faredtls_sh_cls");
        //   }
        // }, 10);

        setActiveTab(k);
      } else {
        setActiveTab(k);
        setShowLoader(false);
        // setTimeout(() => {
        //   if (
        //     window.getComputedStyle(
        //       document.getElementById("fareDetails_" + arrayOf + "_" + index)
        //     ).display == "none"
        //   ) {
        //     document
        //       .getElementById("fareDetails_" + arrayOf + "_" + index)
        //       .classList.toggle("faredtls_sh_cls");
        //   }
        // }, 10);
      }
    } else {
      if (
        (k === "baggages" &&
          result.vendor === "BU" &&
          (result.fareGroups[selectedItinerary]?.baggages === undefined ||
            result.fareGroups[selectedItinerary]?.baggages.length === 0))

      ) {
        setShowLoader(true);
        getSSRInfo(traceId, clientId, [purchaseId]).then((data) => {

          if (data != undefined) {
            if (data.baggages.length > 0) {
              result.fareGroups[index].baggages = data.baggages;
              setBaggageList((baggageList) => data.baggages);
              setBaggageError("");
            } else {
              setBaggageError("Unable to fetch details");
            }
            if (data.miniRules.length > 0) {
              setMiniRuleError("");
              result.fareGroups[index].miniRules = data.miniRules;
              setMiniRules((miniRules) => data.miniRules);
            } else {
              setMiniRuleError("Unable to fetch details");
            }

            setFareSegGroups((fareSegGroups) => result.segGroups);
          } else {

            setMiniRuleError("Unable to fetch Details");


          }
          setShowLoader(false);
          setTimeout(() => {
            document
              .getElementById("fareDetails_" + arrayOf + "_" + index)
              .classList.toggle("faredtls_sh_cls");
          }, 10);
          setActiveTab(k);
        });
      }
      else if (k === "baggages" &&
        result.vendor === "RT" &&
        (result.fareGroups[selectedItinerary]?.baggages === undefined ||
          result.fareGroups[selectedItinerary]?.baggages.find(
            (baggage) =>
              baggage.checkInBag === undefined ||
              baggage.checkInBag === "" ||
              baggage.checkInBag === "Nil" ||
              baggage.checkInBag === "Nill"
          ) !== undefined)) {
        setShowLoader(true);
        getSSRInfo(traceId, clientId, [purchaseId]).then((data) => {

          if (data != undefined) {
            if (data.baggages.length > 0) {
              result.fareGroups[index].baggages = data.baggages;
              setBaggageList((baggageList) => data.baggages);
              setBaggageError("");
            } else {
              setBaggageError("Unable to fetch details");
            }
            if (data.miniRules.length > 0) {
              setMiniRuleError("");
              result.fareGroups[index].miniRules = data.miniRules;
              setMiniRules((miniRules) => data.miniRules);
            } else {
              setMiniRuleError("Unable to fetch details");

            }

            setFareSegGroups((fareSegGroups) => result.segGroups);
          } else {
            setMiniRuleError("Unable to fetch Details");
            setBaggageList(result.fareGroups[selectedItinerary]?.baggages);
            setFareSegGroups(result.segGroups);
            setShowLoader(false);
            setActiveTab(k);

          }
          setShowLoader(false);
          setTimeout(() => {
            document
              .getElementById("fareDetails_" + arrayOf + "_" + index)
              .classList.toggle("faredtls_sh_cls");
          }, 10);
          setActiveTab(k);
        });


      }


      else if (
        (k === "baggages" || k === "cancel" || k == "change") &&
        result.vendor == "BI" &&
        result.fareGroups[selectedItinerary]?.miniRules.length === 0
      ) {
        setShowLoader(true);
        getSSRInfo(traceId, clientId, [purchaseId]).then((data) => {
          if (data != undefined) {
            if (data.baggages.length > 0) {
              result.fareGroups[index].baggages = data.baggages;
              setBaggageList((baggageList) => data.baggages);
              setBaggageError("");
            } else {
              setBaggageError("Unable to fetch details");
            }
            if (data.miniRules.length > 0) {
              result.fareGroups[index].miniRules = data.miniRules;
              setMiniRules((miniRules) => data.miniRules);
            } else {
              setMiniRuleError("Unable to fetch details");
            }

            setFareSegGroups((fareSegGroups) => result.segGroups);
          } else {
            setBaggageError("Unable to fetch Details");
            setMiniRuleError("Unable to fetch Details");
          }
          setShowLoader(false);
          setTimeout(() => {
            document
              .getElementById("fareDetails_" + arrayOf + "_" + index)
              .classList.toggle("faredtls_sh_cls");
          }, 10);
          setActiveTab(k);
        });
      } else if (k === "baggages") {
        if (baggageError === "") {
          setBaggageList((baggageList) =>
            result.fareGroups[selectedItinerary]?.baggages
              ? result.fareGroups[selectedItinerary]?.baggages
              : []
          );
        }

        setFareSegGroups((fareSegGroups) => result.segGroups);
        setShowLoader(false);

        setTimeout(() => {
          if (
            window.getComputedStyle(
              document.getElementById("fareDetails_" + arrayOf + "_" + index)
            ).display == "none"
          ) {
            document
              .getElementById("fareDetails_" + arrayOf + "_" + index)
              .classList.toggle("faredtls_sh_cls");
          }
        }, 10);

        setActiveTab(k);
      } else if (k === "change" || k === "cancel") {
        if (result.fareGroups[selectedItinerary]?.miniRules != undefined) {
          setMiniRules((mini) =>
            result.fareGroups[selectedItinerary]?.miniRules
              ? result.fareGroups[selectedItinerary]?.miniRules
              : []
          );
        } else {
          setMiniRuleError("Unable to fetch");
        }

        setFareSegGroups((fareSegGroups) => result.segGroups);
        setShowLoader(false);

        setTimeout(() => {
          if (
            window.getComputedStyle(
              document.getElementById("fareDetails_" + arrayOf + "_" + index)
            ).display == "none"
          ) {
            document
              .getElementById("fareDetails_" + arrayOf + "_" + index)
              .classList.toggle("faredtls_sh_cls");
          }
        }, 10);

        setActiveTab(k);
      } else {
        setActiveTab(k);
        setShowLoader(false);
        // if (!isBrandedFare) {
        setTimeout(() => {
          if (
            window.getComputedStyle(
              document.getElementById("fareDetails_" + arrayOf + "_" + index)
            ).display == "none"
          ) {
            document
              .getElementById("fareDetails_" + arrayOf + "_" + index)
              .classList.toggle("faredtls_sh_cls");
          }
        }, 10);
        // }
      }
    }
  };

  // Get Baggage inforamtion function
  const getBaggageInfo = (baggages, origin, destination) => {
    let setBaggageInfoDtls = [];
    let setBaggInfo = [];
    const joinedPair = origin + destination;
    if (baggages != undefined) {
      const cityPairs = baggages
        .filter((item) => {
          return item.cityPair === joinedPair;
        })
        .sort(function (a, b) {
          if (a.paxType < b.paxType) {
            return -1;
          }
          if (a.paxType > b.paxType) {
            return 1;
          }
          return 0;
        });
      //console.log(cityPairs)
      cityPairs.forEach((item, index) => {
        setBaggageInfoDtls[index] = {
          paxType: item.paxType,
          checkInBag: item.checkInBag ? item.checkInBag : "NIL",
          cabinBag: item.cabinBag ? item.cabinBag : "NIL",
        };
      });

      cityPairs.forEach((itemCabin, inx) => {
        if (itemCabin.hasOwnProperty("cabinBag")) {
          if (setBaggageInfoDtls[inx - 1] !== undefined) {
            setBaggageInfoDtls[inx - 1].cabinBag = itemCabin.cabinBag
              ? itemCabin.cabinBag
              : "NIL";
          }
        } else {
          setBaggageInfoDtls[inx - 1].cabinBag = "NIL";
        }
      });
    }
    //console.log(setBaggageInfoDtls)
    setBaggageInfoDtls.forEach((key, index) => {
      if (key.checkInBag != "") {
        let setValue = {
          paxType: key.paxType,
          checkInBag: key.checkInBag,
          cabinBag: key.cabinBag ? key.cabinBag : "NIL",
        };
        setBaggInfo.push(setValue);
      }
    });

    const removeDuplicateInfo = [
      ...new Map(
        setBaggInfo.map((item) => [JSON.stringify(item), item])
      ).values(),
    ];
    //console.log(setBaggInfo)

    return { removeDuplicateInfo };
  };

  const getDepartureTime = (item) => {
    let temp = new Date(
      item.segs[0].departureOn.includes("+00:00")
        ? item.segs[0].departureOn.replace("+00:00", "")
        : item.segs[0].departureOn
    );

    return moment(temp).format("HH:mm");
  };

  const getArrivalTime = (item) => {
    const segLength = item.segs.length;
    let temp = new Date(
      item.segs[segLength - 1].arrivalOn.includes("+00:00")
        ? item.segs[segLength - 1].arrivalOn.replace("+00:00", "")
        : item.segs[segLength - 1].arrivalOn
    );
    return moment(temp).format("HH:mm");
  };

  const getArrivalDate = (item) => {
    const segLength = item.segs.length;
    return moment(item.segs[segLength - 1].arrivalOn).format("DD MMM YYYY")
  };

  const getTotalTripDuration = (item) => {
    let totalDuration = 0;
    let totalLayoverTime = [];
    const segs = item.segs;
    segs.forEach((seg, index) => {
      totalDuration = totalDuration + seg.duration;
    });

    segs.forEach((new_item, new_index) => {
      item.segs.length - 1 !== new_index &&
        totalLayoverTime.push(
          getLayoverTime(item.segs[new_index], item.segs[new_index + 1])
        );
    });

    //console.log("Total Layover: "+totalLayoverTime)
    let totalLayoverTime_hours_array = [],
      totalLayoverTime_minutes_array = [];
    totalLayoverTime.forEach((array_item, array_index) => {
      const element = array_item.replace(/ /g, "");
      if (element !== "") {
        if (element.includes("h")) {
          var setHours = element.split("h");
          totalLayoverTime_hours_array.push(parseInt(setHours[0]));
        }

        if (element.includes("m")) {
          var setMinutes = element.split("m");
          if (setMinutes[0].includes("h")) {
            setMinutes = setMinutes[0].split("h");
            totalLayoverTime_minutes_array.push(parseInt(setMinutes[1]));
          }
        }
      }
    });

    //console.log("Total Hours: "+ totalLayoverTime_hours_array)
    //console.log("Total Minutes: "+ totalLayoverTime_minutes_array)

    let totalLayoverTime_hours = 0,
      totalLayoverTime_minutes = 0;

    totalLayoverTime_hours = totalLayoverTime_hours_array.reduce(function (
      hour_prev,
      hour_current
    ) {
      return hour_prev + hour_current;
    },
      0);
    totalLayoverTime_minutes = totalLayoverTime_minutes_array.reduce(function (
      minute_prev,
      minute_current
    ) {
      return minute_prev + minute_current;
    },
      0);
    //console.log("Total Hours: "+ totalLayoverTime_hours)
    //console.log("Total Minutes: "+ totalLayoverTime_minutes)
    //
    // if(totalLayoverTime !== '')
    // {
    //     if(totalLayoverTime.includes('hours'))
    //     {
    //         totalLayoverTime_hours = totalLayoverTime.split('hours');
    //         totalLayoverTime_hours = parseInt(totalLayoverTime_hours[0]);
    //     }
    //     else if(totalLayoverTime.includes('hour'))
    //     {
    //         totalLayoverTime_hours = totalLayoverTime.split('hour');
    //         totalLayoverTime_hours = parseInt(totalLayoverTime_hours[0]);
    //     }

    //     if(totalLayoverTime.includes('minutes'))
    //     {
    //         totalLayoverTime_minutes = totalLayoverTime.split('minutes');
    //         if(totalLayoverTime_minutes[0].includes('hours'))
    //         {
    //             totalLayoverTime_minutes = totalLayoverTime_minutes[0].split("hours");
    //             totalLayoverTime_minutes = parseInt(totalLayoverTime_minutes[1]);
    //         }
    //         else if(totalLayoverTime_minutes[0].includes('hour')){
    //             totalLayoverTime_minutes = totalLayoverTime_minutes[0].split("hour");
    //             totalLayoverTime_minutes = parseInt(totalLayoverTime_minutes[1]);
    //         }

    //     }
    //     else if(totalLayoverTime.includes('minute'))
    //     {
    //         totalLayoverTime_minutes = totalLayoverTime.split('minute');
    //         if(totalLayoverTime_minutes[0].includes('hours'))
    //         {
    //             totalLayoverTime_minutes = totalLayoverTime_minutes[0].split("hours");
    //             totalLayoverTime_minutes = parseInt(totalLayoverTime_minutes[1]);
    //         }
    //         else if(totalLayoverTime_minutes[0].includes('hour')){
    //             totalLayoverTime_minutes = totalLayoverTime_minutes[0].split("hour");
    //             totalLayoverTime_minutes = parseInt(totalLayoverTime_minutes[1]);
    //         }
    //     }
    // }

    let setLayoverTime =
      String(totalLayoverTime_hours).padStart(2, "0") +
      ":" +
      String(totalLayoverTime_minutes).padStart(2, "0");
    let setTripDuration =
      String(Math.floor(totalDuration / 60)).padStart(2, "0") +
      ":" +
      String(totalDuration % 60).padStart(2, "0");
    let checkTotalTime =
      moment.duration(setTripDuration).asMinutes() +
      moment.duration(setLayoverTime).asMinutes();
    //console(totalLayoverTime.replace(" hours ",":").replace(" minutes ",":"))
    //console.log("Duration "+checTotalTime);

    //let setTotalDuration =String((Math.floor(checTotalTime / 60))).padStart(2,'0')+' hrs '+ String((checTotalTime % 60)).padStart(2,'0')+' min';
    //console.log("Total Duration "+Math.floor(checTotalTime / 60)+ ':'+Math.floor(checTotalTime % 60))
    //console.log("total hour: "+setTotalDuration)
    //return `${Math.floor(totalDuration / 60)} hrs ${totalDuration % 60} min`;
    return `${String(Math.floor(checkTotalTime / 60)).padStart(
      2,
      "0"
    )} h ${String(checkTotalTime % 60).padStart(2, "0")} m`;
  };
  const getViaStops = (stops) => {
    let viaString = [];
    for (let stop of stops) {
      viaString.push(stop.origin);
      viaString.push(stop.destination);
    }
    viaString.shift();
    viaString.pop();

    for (const [index, value] of viaString.entries()) {
      let airportName = findAirportbyCode(value);
      viaString[index] = airportName;
    }

    return viaString.filter(onlyUnique).join().replace(/,/g, ", ");
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const miniRulesAvailability =
    result?.fareGroups[selectedItinerary]?.miniRules != undefined
      ? result.fareGroups[0].miniRules.length > 0
        ? true
        : false
      : false;
  const beforeCancel = miniRulesAvailability
    ? result.fareGroups[selectedItinerary]?.miniRules.filter(
      (entry) => entry?.apply === "Before" && entry?.cancelAllowed
    )
    : [];
  const afterCancel = miniRulesAvailability
    ? result.fareGroups[selectedItinerary]?.miniRules.filter(
      (entry) => entry?.apply === "After" && entry?.cancelAllowed
    )
    : [];

  const beforeChange = miniRulesAvailability
    ? result.fareGroups[selectedItinerary]?.miniRules.filter(
      (entry) => entry?.apply === "Before" && entry?.changeAllowed
    )
    : [];
  const afterChange = miniRulesAvailability
    ? result.fareGroups[selectedItinerary]?.miniRules.filter(
      (entry) => entry?.apply === "After" && entry?.changeAllowed
    )
    : [];

  const getMiniRulesData = (miniRulesResp, mr_type) => {
    const getMiniRuleRespData = [];
    if (miniRulesResp != undefined) {
      miniRulesResp.forEach(function (item) {
        if (getMiniRuleRespData.indexOf(item) < 0) {
          if (!getMiniRuleRespData.some((obj) => obj === item)) {
            getMiniRuleRespData.push(item);
          }
        }
      });
      //return getMiniRuleRespData;
    }

    const uniqueMiniRuleData = getMiniRuleRespData.filter((value, index) => {
      if (
        value.changeAllowed != false ||
        value.cancelAllowed != false ||
        value.canAmt != 0 ||
        value.exgAmt != 0
      ) {
        if (mr_type == "Cancellation") {
          if (value.cancelAllowed == true) {
            const _value = JSON.stringify(value);
            return (
              index ===
              getMiniRuleRespData.findIndex((obj) => {
                return JSON.stringify(obj) === _value;
              })
            );
          }
        } else {
          if (value.changeAllowed == true) {
            const _value = JSON.stringify(value);
            return (
              index ===
              getMiniRuleRespData.findIndex((obj) => {
                return JSON.stringify(obj) === _value;
              })
            );
          }
        }
      }
    });

    //console.log("uniqueMiniRuleData Len: "+uniqueMiniRuleData.length+" Type "+ mr_type)
    const removeDuplicateMiniruleInfo = [
      ...new Map(
        uniqueMiniRuleData.map((item) => [JSON.stringify(item), item])
      ).values(),
    ];
    // console.log('uniqueMiniRuleData',uniqueMiniRuleData)
    return removeDuplicateMiniruleInfo;
  };

  const ItineraryConstants = ({ details, localIndex, type }) => {
    const stop = `${details[localIndex].segs.length - 1} stop`
    const stops = `${details[localIndex].segs.length - 1} stops`
    const via = ` via ${getViaStops(details[localIndex].segs)}`
    return (
      <>
        <Col className={`individual_constants${type}`}>
          <Row className="constant_details">
            <Col className="constants_col">
              <span className="org_dest_timings d-flex align-items-end">
                {" "}
                {getDepartureTime(details[localIndex])}{" "}
                <Typography variant="body2" className="mb-1 ms-1" style={{ textTransform: 'none' }}>{moment(details[localIndex].segs[0].departureOn).format("DD MMM YYYY")}</Typography>
              </span>
              <span>
                {" "}
                {findAirportbyCode(details[localIndex].origin) +
                  " (" +
                  details[localIndex].origin +
                  ")"}{" "}
              </span>
            </Col>
            <Col className="constants_col text_center">
              <span> {getTotalTripDuration(details[localIndex])} </span>
              <span> - </span>
              <span>
                {details[localIndex].segs.length === 1 ? `${t("Non-stop")}` : ""}
                {details[localIndex].segs.length === 2
                  ? t(stop)
                  : ""}
                {details[localIndex].segs.length > 2
                  ? t(stops)
                  : ""}
                {details[localIndex].segs.length >= 2
                  ? t(via)
                  : ""}
              </span>
              {result && result.vendor && result.vendor === "ER" && <span className="mt-1 text-danger">{t("Group Fares")}</span>}
            </Col>
            <Col className="constants_col text_right last-item">
              <span className="org_dest_timings d-flex align-items-end ms-auto">
                {" "}
                {getArrivalTime(details[localIndex])}{" "}
                <Typography variant="body2" className="mb-1 ms-1" style={{ textTransform: 'none' }}>{getArrivalDate(details[localIndex])}</Typography>
              </span>
              <span className="badge flt-highlight-badge">
                {getDifferenceInDays(
                  moment(
                    new Date(
                      details[localIndex].segs[0].departureOn.includes("+00:00")
                        ? details[localIndex].segs[0].departureOn.replace(
                          "+00:00",
                          ""
                        )
                        : details[localIndex].segs[0].departureOn
                    )
                  ).format("YYYY-MM-DD"),
                  moment(
                    new Date(
                      details[localIndex].segs[
                        details[localIndex].segs.length - 1
                      ].arrivalOn.includes("+00:00")
                        ? details[localIndex].segs[
                          details[localIndex].segs.length - 1
                        ].arrivalOn.replace("+00:00", "")
                        : details[localIndex].segs[
                          details[localIndex].segs.length - 1
                        ].arrivalOn
                    )
                  ).format("YYYY-MM-DD")
                ) !== "Arrives Same Day"
                  ? getDifferenceInDays(
                    moment(
                      new Date(
                        details[localIndex].segs[0].departureOn.includes(
                          "+00:00"
                        )
                          ? details[localIndex].segs[0].departureOn.replace(
                            "+00:00",
                            ""
                          )
                          : details[localIndex].segs[0].departureOn
                      )
                    ).format("YYYY-MM-DD"),
                    moment(
                      new Date(
                        details[localIndex].segs[
                          details[localIndex].segs.length - 1
                        ].arrivalOn.includes("+00:00")
                          ? details[localIndex].segs[
                            details[localIndex].segs.length - 1
                          ].arrivalOn.replace("+00:00", "")
                          : details[localIndex].segs[
                            details[localIndex].segs.length - 1
                          ].arrivalOn
                      )
                    ).format("YYYY-MM-DD")
                  )
                  : ""}
              </span>
              <span>
                {" "}
                {findAirportbyCode(details[localIndex].destination) +
                  " (" +
                  details[localIndex].destination +
                  ")"}{" "}
              </span>
            </Col>
          </Row>
        </Col>
      </>
    );
  };
  const filterAdultCancellation = (miniRule) => {
    if (miniRule.paxType === "ADT" && miniRule.cancelAllowed) {
      return miniRule;
    }
  };
  const filterChildCancellation = (miniRule) => {
    if (miniRule.paxType === "CHD" && miniRule.cancelAllowed) {
      return miniRule;
    }
  };
  const filterInfantCancellation = (miniRule) => {
    if (miniRule.paxType === "INF" && miniRule.cancelAllowed) {
      return miniRule;
    }
  };
  const filterAdultChangeDate = (miniRule) => {
    if (miniRule.paxType === "ADT" && miniRule.changeAllowed) {
      return miniRule;
    }
  };
  const filterChildChangeDate = (miniRule) => {
    if (miniRule.paxType === "CHD" && miniRule.changeAllowed) {
      return miniRule;
    }
  };
  const filterInfantChangeDate = (miniRule) => {
    if (miniRule.paxType === "INF" && miniRule.changeAllowed) {
      return miniRule;
    }
  };

  const FareRows = ({ individualFare, index, showToggleBtn }) => {
    return (
      <>
        {selectedItinerary === index ? (
          <>
            <Row
              className={
                showToggleBtn
                  ? "individual_fares"
                  : selectedItinerary == index
                    ? "individual_fares selected_card"
                    : "individual_fares"
              }
            >
              <Col sm={2} className="card_class">
                {/* {" "}
                <InfoOutlinedIcon fontSize='small' /> &nbsp;{" "} */}
                <span>
                  {" "}
                  {showToggleBtn
                    ? result?.fareGroups[selectedItinerary]?.priceClass !==
                      undefined
                      ? t(result?.fareGroups[selectedItinerary]?.priceClass)
                      : t("Economy")
                    : individualFare?.priceClass !== undefined
                      ? t(individualFare?.priceClass)
                      : t("Economy")}{" "}
                </span>{" "}
              </Col>
              <Col sm={10} className="card_details">
                <Row className="reset_rows">
                  {((result.fareGroups[selectedItinerary]?.baggages ||
                    result.fareGroups[index].baggages) &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ) &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag !== "Nil" &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag !== "0PC" &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag !== "0KG" &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag !== "0 Kg") ||
                    ((result.fareGroups[selectedItinerary]?.baggages ||
                      result.fareGroups[index].baggages) &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ) &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag !== "Nil" &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag !== "0KG" &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag !== "0PC" &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag !== "0 Kg") ? (
                    <Col sm={3} className="fare_breakdown_container">
                      {showToggleBtn ? (
                        <h6>
                          {result.fareGroups[selectedItinerary].refundable
                            ? t("Refundable")
                            : t("Non-Refundable")}{" "}
                        </h6>
                      ) : (
                        <h6>
                          {individualFare.refundable
                            ? t("Refundable")
                            : t("Non-Refundable")}{" "}
                        </h6>
                      )}
                      {/* {filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    )} */}
                      <div className="flight-confirmation-details-item">
                        {filterAndSortBaggage(
                          showToggleBtn
                            ? result.fareGroups[selectedItinerary]?.baggages
                            : result.fareGroups[index].baggages
                        ).checkInBag &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).checkInBag !== "Nil" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).checkInBag !== "0PC" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).checkInBag !== "0KG" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).checkInBag !== "0 Kg" && (
                            <>
                              <p className="bagText ">
                                {t("Check In")} :{" "}
                                {spliceBagtext(
                                  filterAndSortBaggage(
                                    showToggleBtn
                                      ? result.fareGroups[selectedItinerary]
                                        .baggages
                                      : result.fareGroups[index].baggages
                                  ).checkInBag
                                    ? filterAndSortBaggage(
                                      showToggleBtn
                                        ? result.fareGroups[selectedItinerary]
                                          .baggages
                                        : result.fareGroups[index].baggages
                                    ).checkInBag
                                    : ""
                                )}
                              </p>
                            </>
                          )}
                        {filterAndSortBaggage(
                          showToggleBtn
                            ? result.fareGroups[selectedItinerary]?.baggages
                            : result.fareGroups[index].baggages
                        ).cabinBag &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).cabinBag !== "Nil" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).cabinBag !== "0PC" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).cabinBag !== "0KG" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).cabinBag !== "0 Kg" && (
                            <>
                              <p className="bagText ">
                                {t("Cabin")} :{" "}
                                {spliceBagtext(
                                  filterAndSortBaggage(
                                    showToggleBtn
                                      ? result.fareGroups[selectedItinerary]
                                        .baggages
                                      : result.fareGroups[index].baggages
                                  ).cabinBag
                                    ? filterAndSortBaggage(
                                      showToggleBtn
                                        ? result.fareGroups[selectedItinerary]
                                          .baggages
                                        : result.fareGroups[index].baggages
                                    ).cabinBag
                                    : ""
                                )}
                              </p>
                            </>
                          )}
                      </div>
                    </Col>
                  ) : (
                    <Col sm={3} className="fare_breakdown_container textCenter">
                      {showToggleBtn ? (
                        <h6>
                          {result.fareGroups[selectedItinerary].refundable
                            ? t("Refundable")
                            : t("Non-Refundable")}{" "}
                        </h6>
                      ) : (
                        <h6>
                          {individualFare.refundable
                            ? t("Refundable")
                            : t("Non-Refundable")}{" "}
                        </h6>
                      )}
                    </Col>
                  )}

                  <Col sm={9} className="padd_0">
                    <Row className="reset_rows">
                      <Col
                        {...(showToggleBtn ? "" : `sm={12}`)}
                        className="card_price"
                      >
                        {showToggleBtn ? (
                          <>
                            <p>
                              <CurrencyCodeComponent
                                currencyCode={currencyCode}
                              />
                              {/* {currencyCode} &nbsp; */}
                              {(
                                getTotalFare(
                                  result.fareGroups[selectedItinerary],
                                  search.adult,
                                  search.children,
                                  search.infant
                                ).total +
                                (itineraryMarkup
                                  ? itineraryMarkup * adultcount
                                  : 0) +
                                (itineraryMarkup
                                  ? itineraryMarkup * childcount
                                  : 0)
                              ).toFixed(2)}
                            </p>

                            <Button
                              variant="contained"
                              className="srn-btn"
                              // data-bs-toggle="collapse"
                              // data-bs-target={`#multiFareDetails_` + arrayOf}
                              onClick={() =>
                                setToggleShowHideFareDtls(
                                  (toggleShowHideFareDtls) =>
                                    !toggleShowHideFareDtls
                                )
                              }
                            >
                              {toggleShowHideFareDtls ? (
                                <span>
                                  {t("Hide Fares")} &nbsp;{" "}
                                  <KeyboardArrowUpRoundedIcon fontSize="medium" />
                                </span>
                              ) : (
                                <span>
                                  {t("Show Fares")} &nbsp;{" "}
                                  <KeyboardArrowDownRoundedIcon fontSize="medium" />
                                </span>
                              )}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              // variant='contained'
                              className="test srn-btn-fare"
                              data={`fareDetails_` + arrayOf + `_` + index}
                              onClick={(e) => handleToggleClick(e, index)}
                            >
                              {t("Fare Details")}
                            </Button>
                            <i
                              className={
                                toggleShowHideFareDtls
                                  ? selectedItinerary == index
                                    ? "fa fa-check check_selected"
                                    : "fa fa-check hide_check"
                                  : ""
                              }
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp;
                            {result.fareGroups[index].netFare > 0 && (
                              <BootstrapTooltip1
                                title={
                                  <>
                                    <p
                                      style={{
                                        borderBottom: "2px solid grey",
                                      }}
                                    >
                                      {t("Fare Details")}
                                    </p>

                                    {((!checkIfUserStaff() && (isClientUserHasPermission("IsYouEarn") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                      showNetfare == true &&
                                      result?.fareGroups[index]?.clientMarkup >
                                      0 && (
                                        <div className="d-flex justify-content-between">
                                          <div>{t("You Earn")}</div>
                                          <div>
                                            <CurrencyCodeComponent
                                              currencyCode={currencyCode}
                                            />

                                            {(
                                              parseFloat(
                                                result.fareGroups[index]
                                                  .clientMarkup
                                              ) +
                                              (result?.fareGroups[index]
                                                ?.commission > 0
                                                ? parseFloat(
                                                  result?.fareGroups[index]
                                                    ?.commission
                                                )
                                                : 0) +
                                              (itineraryMarkup
                                                ? itineraryMarkup
                                                : 0) *
                                              adultcount +
                                              (itineraryMarkup
                                                ? itineraryMarkup
                                                : 0) *
                                              childcount
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      )}
                                    {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                      showNetfare == true &&
                                      result?.fareGroups[index]?.clientMarkup >
                                      0 && (
                                        <div
                                          className="d-flex justify-content-between"
                                          style={{
                                            fontSize: "10px",
                                            color: "lightgray",
                                          }}
                                        >
                                          <div>{t("Agent Mark-up")}</div>

                                          <div>
                                            <CurrencyCodeComponent
                                              currencyCode={currencyCode}
                                            />
                                            {(
                                              result.fareGroups[index]
                                                .clientMarkup +
                                              (itineraryMarkup
                                                ? itineraryMarkup * adultcount
                                                : 0) +
                                              (itineraryMarkup
                                                ? itineraryMarkup * childcount
                                                : 0)
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      )}
                                    {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                      showNetfare == true &&
                                      result?.fareGroups[index]?.commission >
                                      0 && (
                                        <div
                                          className="d-flex justify-content-between"
                                          style={{
                                            fontSize: "10px",
                                            color: "lightgray",
                                          }}
                                        >
                                          <div>{t("Commission")}</div>
                                          <div>
                                            <CurrencyCodeComponent
                                              currencyCode={currencyCode}
                                            />
                                            0.0
                                          </div>
                                        </div>
                                      )}
                                    <div
                                      className="d-flex justify-content-between"
                                      style={{
                                        borderTop: "2px solid grey",
                                      }}
                                    >
                                      <div>{t("Total Amount")}</div>
                                      <div>
                                        <CurrencyCodeComponent
                                          currencyCode={currencyCode}
                                        />
                                        {result.fareGroups[index].grossFare +
                                          (itineraryMarkup
                                            ? itineraryMarkup * adultcount
                                            : 0) +
                                          (itineraryMarkup
                                            ? itineraryMarkup * childcount
                                            : 0)}
                                      </div>
                                    </div>
                                    {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                      showNetfare == true && (
                                        <div
                                          className="d-flex justify-content-between"
                                        // style={{ fontSize: "9px" }}
                                        >
                                          <div>{t("Net Fare")}</div>
                                          <div>
                                            <CurrencyCodeComponent
                                              currencyCode={currencyCode}
                                            />
                                            {result.fareGroups[index].netFare}
                                          </div>
                                        </div>
                                      )}
                                  </>
                                }
                              >
                                <InfoOutlinedIcon />
                              </BootstrapTooltip1>
                            )}
                            <div className="pb-3">
                              <p className="pt-4">
                                <CurrencyCodeComponent
                                  currencyCode={currencyCode}
                                />
                                {/* {currencyCode} &nbsp; */}
                                {(
                                  getTotalFare(
                                    result.fareGroups[index],
                                    search.adult,
                                    search.children,
                                    search.infant
                                  ).total +
                                  (itineraryMarkup
                                    ? itineraryMarkup * adultcount
                                    : 0) +
                                  (itineraryMarkup
                                    ? itineraryMarkup * childcount
                                    : 0)
                                ).toFixed(2)}
                              </p>
                              {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                showNetfare == true &&
                                result.fareGroups[index].netFare > 0 && (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      fontSize: "13px",
                                      color: "gray",
                                    }}
                                  >
                                    <div>{t("Net Fare")} : </div>
                                    <div>
                                      <CurrencyCodeComponent
                                        currencyCode={currencyCode}
                                      />
                                      {result.fareGroups[index].netFare}
                                    </div>
                                  </div>
                                )}
                            </div>
                            {
                              // !checkIfUserStaff() &&
                              //   !isUserHasPermission("FlightBook") ? null : (
                              <div className="d-flex align-items-center ">
                                {result.isHold === true && <Button
                                  variant="contained"
                                  disabled={(!checkIfUserStaff() && !isClientUserHasPermission("IsPrice", "FlightbookDetail") && userRole !== "ClientSuperAdmin") || (isBrandedFare && selectedItinerary !== index)}
                                  className="srn-btn marginBR5"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    proceedToBook("Hold", index);
                                    localStorage.removeItem("reValTimer");
                                  }}
                                >
                                  {t("Hold")}
                                </Button>}
                                <Button
                                  variant="contained"
                                  className="srn-btn marginBR5"
                                  disabled={((!checkIfUserStaff() && !isClientUserHasPermission("IsPrice", "FlightbookDetail") && userRole !== "ClientSuperAdmin") || (isBrandedFare && selectedItinerary !== index))}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    proceedToBook("Book", index);
                                    localStorage.removeItem("reValTimer");
                                  }}
                                >
                                  {t("Book")}
                                </Button>
                                {/* {clientProfile.holdBookings &&
                                  result.content === "GDS" &&
                                  (result.vendor === "1S" ||
                                    result.vendor === "1A" ||
                                    result.vendor === "1G") && (
                                    <Button
                                      variant="contained"
                                      className="srn-btn-hold marginBR5"
                                      onClick={() => {
                                        history.push({
                                          pathname: "/review-booking",
                                          state: {
                                            activeSearch: search,
                                            traceId: traceId,
                                            purchaseId:
                                              result.fareGroups[index]
                                                .purchaseId,
                                            clientId: clientId,
                                            hold: "true",
                                          },
                                        });
                                      }}
                                    >
                                      Hold
                                    </Button>
                                  )} */}
                              </div>
                              // )
                            }
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row
              className={
                showToggleBtn
                  ? "individual_fares"
                  : selectedItinerary == index
                    ? "individual_fares selected_card"
                    : "individual_fares"
              }
              onClick={() => setSelectedItinerary(index)}
              data-id={index}
            >
              <Col sm={2} className="card_class">
                {/* {" "}
                <InfoOutlinedIcon fontSize='small' /> &nbsp;{" "} */}
                <span>
                  {" "}
                  {showToggleBtn
                    ? result?.fareGroups[selectedItinerary]?.priceClass !==
                      undefined
                      ? t(result?.fareGroups[selectedItinerary]?.priceClass)
                      : t("Economy")
                    : individualFare?.priceClass !== undefined
                      ? t(individualFare?.priceClass)
                      : t("Economy")}{" "}
                </span>{" "}
              </Col>
              <Col sm={10} className="card_details">
                <Row className="reset_rows">
                  {((result.fareGroups[selectedItinerary]?.baggages ||
                    result.fareGroups[index].baggages) &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ) &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag !== "Nil" &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag !== "0PC" &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag !== "0KG" &&
                    filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    ).checkInBag !== "0 Kg") ||
                    ((result.fareGroups[selectedItinerary]?.baggages ||
                      result.fareGroups[index].baggages) &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ) &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag !== "Nil" &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag !== "0KG" &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag !== "0PC" &&
                      filterAndSortBaggage(
                        showToggleBtn
                          ? result.fareGroups[selectedItinerary]?.baggages
                          : result.fareGroups[index].baggages
                      ).cabinBag !== "0 Kg") ? (
                    <Col sm={3} className="fare_breakdown_container">
                      {showToggleBtn ? (
                        <h6>
                          {result.fareGroups[selectedItinerary].refundable
                            ? t("Refundable")
                            : t("Non-Refundable")}{" "}
                        </h6>
                      ) : (
                        <h6>
                          {individualFare.refundable
                            ? t("Refundable")
                            : t("Non-Refundable")}{" "}
                        </h6>
                      )}
                      {/* {filterAndSortBaggage(
                      showToggleBtn
                        ? result.fareGroups[selectedItinerary]?.baggages
                        : result.fareGroups[index].baggages
                    )} */}
                      <div className="flight-confirmation-details-item">
                        {filterAndSortBaggage(
                          showToggleBtn
                            ? result.fareGroups[selectedItinerary]?.baggages
                            : result.fareGroups[index].baggages
                        ).checkInBag &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).checkInBag !== "Nil" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).checkInBag !== "0PC" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).checkInBag !== "0KG" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).checkInBag !== "0 Kg" && (
                            <>
                              <p className="bagText ">
                                {t("Check In")} :{" "}
                                {spliceBagtext(
                                  filterAndSortBaggage(
                                    showToggleBtn
                                      ? result.fareGroups[selectedItinerary]
                                        .baggages
                                      : result.fareGroups[index].baggages
                                  ).checkInBag
                                    ? filterAndSortBaggage(
                                      showToggleBtn
                                        ? result.fareGroups[selectedItinerary]
                                          .baggages
                                        : result.fareGroups[index].baggages
                                    ).checkInBag
                                    : ""
                                )}
                              </p>
                            </>
                          )}
                        {filterAndSortBaggage(
                          showToggleBtn
                            ? result.fareGroups[selectedItinerary]?.baggages
                            : result.fareGroups[index].baggages
                        ).cabinBag &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).cabinBag !== "Nil" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).cabinBag !== "0PC" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).cabinBag !== "0KG" &&
                          filterAndSortBaggage(
                            showToggleBtn
                              ? result.fareGroups[selectedItinerary]?.baggages
                              : result.fareGroups[index].baggages
                          ).cabinBag !== "0 Kg" && (
                            <>
                              <p className="bagText ">
                                {t(" Cabin")} :{" "}
                                {spliceBagtext(
                                  filterAndSortBaggage(
                                    showToggleBtn
                                      ? result.fareGroups[selectedItinerary]
                                        .baggages
                                      : result.fareGroups[index].baggages
                                  ).cabinBag
                                    ? filterAndSortBaggage(
                                      showToggleBtn
                                        ? result.fareGroups[selectedItinerary]
                                          .baggages
                                        : result.fareGroups[index].baggages
                                    ).cabinBag
                                    : ""
                                )}
                              </p>
                            </>
                          )}
                      </div>
                    </Col>
                  ) : (
                    <Col sm={3} className="fare_breakdown_container textCenter">
                      {showToggleBtn ? (
                        <h6>
                          {result.fareGroups[selectedItinerary].refundable
                            ? t("Refundable")
                            : t("Non-Refundable")}{" "}
                        </h6>
                      ) : (
                        <h6>
                          {individualFare.refundable
                            ? t("Refundable")
                            : t("Non-Refundable")}{" "}
                        </h6>
                      )}
                    </Col>
                  )}

                  <Col sm={9} className="padd_0">
                    <Row className="reset_rows">
                      <Col
                        {...(showToggleBtn ? "" : `sm={12}`)}
                        className="card_price"
                      >
                        {showToggleBtn ? (
                          <>
                            {/* {currencyCode} &nbsp; */}
                            <p>
                              <CurrencyCodeComponent
                                currencyCode={currencyCode}
                              />
                              {(
                                getTotalFare(
                                  result.fareGroups[selectedItinerary],
                                  search.adult,
                                  search.children,
                                  search.infant
                                ).total +
                                (itineraryMarkup
                                  ? itineraryMarkup * adultcount
                                  : 0) +
                                (itineraryMarkup
                                  ? itineraryMarkup * childcount
                                  : 0)
                              ).toFixed(2)}
                            </p>

                            <Button
                              variant="contained"
                              className="srn-btn"
                              // data-bs-toggle="collapse"
                              // data-bs-target={`#multiFareDetails_` + arrayOf}
                              onClick={() =>
                                setToggleShowHideFareDtls(
                                  (toggleShowHideFareDtls) =>
                                    !toggleShowHideFareDtls
                                )
                              }
                            >
                              {toggleShowHideFareDtls ? (
                                <span>
                                  {t("Hide Fares")} &nbsp;{" "}
                                  <KeyboardArrowUpRoundedIcon fontSize="medium" />
                                </span>
                              ) : (
                                <span>
                                  {t("Show Fares")} &nbsp;{" "}
                                  <KeyboardArrowDownRoundedIcon fontSize="medium" />
                                </span>
                              )}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              // variant='contained'
                              className="test srn-btn-fare"
                              data={`fareDetails_` + arrayOf + `_` + index}
                              onClick={(e) => handleToggleClick(e, index)}
                            >
                              {t("Fare Details")}
                            </Button>
                            <i
                              className={
                                selectedItinerary == index
                                  ? "fa fa-check check_selected"
                                  : "fa fa-check hide_check"
                              }
                              aria-hidden="true"
                            ></i>{" "}
                            &nbsp;
                            {result.fareGroups[index].netFare > 0 && (
                              <BootstrapTooltip1
                                title={
                                  <>
                                    <p
                                      style={{
                                        borderBottom: "2px solid grey",
                                      }}
                                    >
                                      {t("Fare Details")}
                                    </p>

                                    {((!checkIfUserStaff() && (isClientUserHasPermission("IsYouEarn") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                      showNetfare == true &&
                                      result?.fareGroups[index]?.clientMarkup >
                                      0 && (
                                        <div className="d-flex justify-content-between">
                                          <div>{t("You Earn")}</div>
                                          <div>
                                            <CurrencyCodeComponent
                                              currencyCode={currencyCode}
                                            />

                                            {(
                                              parseFloat(
                                                result.fareGroups[index]
                                                  .clientMarkup
                                              ) +
                                              (result?.fareGroups[index]
                                                ?.commission > 0
                                                ? parseFloat(
                                                  result?.fareGroups[index]
                                                    ?.commission
                                                )
                                                : 0) +
                                              (itineraryMarkup
                                                ? itineraryMarkup
                                                : 0) *
                                              adultcount +
                                              (itineraryMarkup
                                                ? itineraryMarkup
                                                : 0) *
                                              childcount
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      )}
                                    {((!checkIfUserStaff() && (isClientUserHasPermission("IsYouEarn") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                      showNetfare == true &&
                                      result?.fareGroups[index]?.clientMarkup >
                                      0 && (
                                        <div
                                          className="d-flex justify-content-between"
                                          style={{
                                            fontSize: "10px",
                                            color: "lightgray",
                                          }}
                                        >
                                          <div>{t("Agent Mark-up")}</div>

                                          <div>
                                            <CurrencyCodeComponent
                                              currencyCode={currencyCode}
                                            />
                                            {(
                                              result.fareGroups[index]
                                                .clientMarkup +
                                              (itineraryMarkup
                                                ? itineraryMarkup * adultcount
                                                : 0) +
                                              (itineraryMarkup
                                                ? itineraryMarkup * childcount
                                                : 0)
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      )}
                                    {((!checkIfUserStaff() && (isClientUserHasPermission("IsYouEarn") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                      showNetfare == true &&
                                      result?.fareGroups[index]?.commission >
                                      0 && (
                                        <div
                                          className="d-flex justify-content-between"
                                          style={{
                                            fontSize: "10px",
                                            color: "lightgray",
                                          }}
                                        >
                                          <div>{t("Commission")}</div>
                                          <div>
                                            <CurrencyCodeComponent
                                              currencyCode={currencyCode}
                                            />
                                            0.0
                                          </div>
                                        </div>
                                      )}
                                    <div
                                      className="d-flex justify-content-between"
                                      style={{
                                        borderTop: "2px solid grey",
                                      }}
                                    >
                                      <div>{t("Total Amount")}</div>
                                      <div>
                                        <CurrencyCodeComponent
                                          currencyCode={currencyCode}
                                        />
                                        {result.fareGroups[index].grossFare +
                                          (itineraryMarkup
                                            ? itineraryMarkup * adultcount
                                            : 0) +
                                          (itineraryMarkup
                                            ? itineraryMarkup * childcount
                                            : 0)}
                                      </div>
                                    </div>
                                    {((!checkIfUserStaff() && (isClientUserHasPermission("IsYouEarn") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                                      showNetfare == true && (
                                        <div
                                          className="d-flex justify-content-between"
                                        // style={{ fontSize: "9px" }}
                                        >
                                          <div>{t("Net Fare")}</div>
                                          <div>
                                            <CurrencyCodeComponent
                                              currencyCode={currencyCode}
                                            />
                                            {result.fareGroups[index].netFare}
                                          </div>
                                        </div>
                                      )}
                                  </>
                                }
                              >
                                <InfoOutlinedIcon />
                              </BootstrapTooltip1>
                            )}
                            <p>
                              <CurrencyCodeComponent
                                currencyCode={currencyCode}
                              />
                              {/* {currencyCode} &nbsp; */}
                              {(
                                getTotalFare(
                                  result.fareGroups[index],
                                  search.adult,
                                  search.children,
                                  search.infant
                                ).total +
                                (itineraryMarkup
                                  ? itineraryMarkup * adultcount
                                  : 0) +
                                (itineraryMarkup
                                  ? itineraryMarkup * childcount
                                  : 0)
                              ).toFixed(2)}
                            </p>
                            {((!checkIfUserStaff() && (isClientUserHasPermission("IsShowNetfares") || userRole === "ClientSuperAdmin")) || checkIfUserStaff()) &&
                              showNetfare == true &&
                              result.fareGroups[index].netFare > 0 && (
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    fontSize: "13px",
                                    color: "gray",
                                  }}
                                >
                                  <div>{t("Net Fare")} : </div>
                                  <div>
                                    <CurrencyCodeComponent
                                      currencyCode={currencyCode}
                                    />
                                    {result.fareGroups[index].netFare}
                                  </div>
                                </div>
                              )}
                            {result.isHold === true && <Button
                              variant="contained"
                              disabled={(!checkIfUserStaff() && !isClientUserHasPermission("IsPrice", "FlightbookDetail")) || (isBrandedFare && selectedItinerary !== index)}
                              className="srn-btn marginBR5"
                              onClick={(e) => {
                                e.stopPropagation();
                                proceedToBook("Hold", index);
                                localStorage.removeItem("reValTimer");
                              }}
                            >
                              {t("Hold")}
                            </Button>}
                            <Button
                              variant="contained"
                              className="srn-btn marginBR5"
                              disabled={(!checkIfUserStaff() && !isClientUserHasPermission("IsPrice", "FlightbookDetail")) || (isBrandedFare && selectedItinerary !== index)}
                              onClick={(e) => {
                                e.stopPropagation();
                                proceedToBook("Book", index);
                                localStorage.removeItem("reValTimer");
                              }}
                            >
                              {t(" Book")}
                            </Button>
                            {/* {clientProfile.holdBookings &&
                              result.content === "GDS" &&
                              (result.vendor === "1S" ||
                                result.vendor === "1A" ||
                                result.vendor === "1G") && (
                                <Button
                                  variant="contained"
                                  className="srn-btn-hold marginBR5"
                                  onClick={() => {
                                    history.push({
                                      pathname: "/review-booking",
                                      state: {
                                        activeSearch: search,
                                        traceId: traceId,
                                        purchaseId:
                                          result.fareGroups[index].purchaseId,
                                        clientId: clientId,
                                        hold: "true",
                                      },
                                    });
                                  }}
                                >
                                  Hold
                                </Button>
                              )} */}
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {showToggleBtn ? (
          ""
        ) : (
          <Row
            className=""
            id={`fareDetails_` + arrayOf + `_` + index}
            style={{ display: "none", height: "0" }}
          >
            <Tab.Container
              defaultActiveKey={activeTab}
              onSelect={(k) =>
                getFareDetails(
                  k,
                  traceId,
                  result.fareGroups[index].purchaseId,
                  clientId,
                  index,
                  arrayOf
                )
              }
            >
              <Row className="fare_tab_container px-0">
                <Col sm={3} className="flex-column fare_header_container">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="fareSummary">
                        {" "}
                        <SummarizeIcon fontSize="small" /> {t("Fare Summary")}{" "}
                      </Nav.Link>
                    </Nav.Item>
                    {result.fareGroups[index].priceRemarks &&
                      result.fareGroups[index].priceRemarks.length ? (
                      <Nav.Item>
                        <Nav.Link eventKey="priceRemarks">
                          {" "}
                          <DescriptionIcon fontSize="small" /> {t("Brand Description")}{" "}
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      ""
                    )}
                    <Nav.Item>
                      <Nav.Link eventKey="baggages">
                        {" "}
                        <LuggageIcon fontSize="small" /> {t("Baggages")}{" "}
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="cancel">
                        {" "}
                        <CancelIcon fontSize="small" /> {t("Cancellation")}{" "}
                      </Nav.Link>
                    </Nav.Item>


                    <Nav.Item>
                      <Nav.Link eventKey="change">
                        {" "}
                        <DateRangeIcon fontSize="small" />{t("Date Change")}{" "}
                      </Nav.Link>
                    </Nav.Item>

                    {/* <Nav.Item>
                                            <Nav.Link eventKey="additionalInfo" onClick={handleOpen}> <DateRangeIcon fontSize="small" /> {t("Additional Info")} </Nav.Link>
                                        </Nav.Item> */}
                  </Nav>
                </Col>
                <Col sm={9} className="fare_tab_contents_wrapper px-0">
                  <Tab.Content className="fullHeight">
                    <Tab.Pane
                      eventKey="fareSummary"
                      className="fare_tabs"
                      transition={true}
                    >
                      <div className="fullHeight">
                        <table className="fullHeight fareDetailsTable">
                          <tr>
                            <th className="text-start" rowSpan={2}>
                              {t("Travellers")}
                            </th>
                            <th colSpan={3}>{t("Per Pax")} ({currencyCode})</th>
                            {/* <th colSpan={2}>Sub Total ({currencyCode})</th> */}
                            <th colSpan={2}>{t("Gross Total")} ({currencyCode})</th>
                          </tr>
                          <tr>
                            <th>{t("Base")}</th>
                            <th>{t("Tax")}</th>
                            <th>{t("Total")}</th>
                            {/* <th>Base</th>
                            <th>Tax</th> */}
                            <th>{`(${t("Base")} + ${t("Tax")})`}</th>
                          </tr>
                          {search.adult > 0 && (
                            <tr>
                              <td className="text-start">
                                {search.adult} x {t("Adult(s)")}
                              </td>
                              <td>{getAdultPrice().toFixed(2)}</td>
                              <td>{getAdultTaxPrice().toFixed(2)}</td>
                              <td>{(Number(getAdultPrice()) + Number(getAdultTaxPrice())).toFixed(2)}</td>
                              <td>
                                {(Number(search.adult * getAdultPrice()) + Number(search.adult * getAdultTaxPrice())).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {search.children > 0 && (
                            <tr>
                              <td className="text-start">
                                {search.children} x {t("Children")}
                              </td>
                              <td>{getChildrenPrice().toFixed(2)}</td>
                              <td>{getChildrenTaxPrice().toFixed(2)}</td>
                              <td>
                                {(Number(getChildrenPrice()) + Number(
                                  getChildrenTaxPrice()
                                )).toFixed(2)}
                              </td>
                              <td>
                                {(Number(search.children * getChildrenPrice()
                                ) + Number(
                                  search.children * getChildrenTaxPrice()
                                )).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {search.infant > 0 && (
                            <tr>
                              <td className="text-start">
                                {search.infant} x {t("Infant")}
                              </td>
                              <td>{getInfantPrice().toFixed(2)}</td>
                              <td>{getInfantTaxPrice().toFixed(2)}</td>
                              <td>
                                {(Number(getInfantPrice()) + Number(getInfantTaxPrice())).toFixed(
                                  2
                                )}
                              </td>
                              <td>
                                {(Number(search.infant * getInfantPrice()) + Number(search.infant * getInfantTaxPrice())).toFixed(
                                  2
                                )}
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="priceRemarks"
                      className="fare_tabs"
                      transition={true}
                    >
                      <div className="FareDetailsDiv">
                        {result?.fareGroups[index]?.priceRemarks &&
                          result?.fareGroups[index]?.priceRemarks.length && (
                            <>
                              <div className="pad1vw fs14 fontCabin">
                                {result.fareGroups[index].priceRemarks
                                  .split("\n")
                                  .map((item, index) =>
                                    index === 0 ? (
                                      <h6>{t(item)}</h6>
                                    ) : (
                                      <p className="m0">{t(item)}</p>
                                    )
                                  )}
                              </div>
                            </>
                          )}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="baggages"
                      className="fare_tabs"
                      transition={true}
                    >
                      <Row>
                        <div className="fullHeight baggDetails">
                          {baggageError === "" ? (
                            <div>
                              {fareSegGroups.map((segGroup, segGroupIndex) =>
                                segGroup.segs.map((item, index) =>
                                  baggageList &&
                                    baggageList.length &&
                                    baggageList.filter(
                                      (t) =>
                                        t.cityPair ==
                                        item.origin + item.destination
                                    ).length > 0 ? (
                                    <table className="fullHeight fareDetailsTable mb-3">
                                      <tr>
                                        <th className="text-start" colSpan={3}>
                                          {item.origin} - {item.destination}
                                        </th>
                                      </tr>
                                      <tr>
                                        <th className="text-start">{t("Baggages")}</th>
                                        <th>{t("Check In")}</th>
                                        <th>{t("Cabin")}</th>
                                      </tr>
                                      {baggageList
                                        .filter(
                                          (t) =>
                                            t.cityPair ==
                                            item.origin + item.destination
                                        )
                                        .sort(function (a, b) {
                                          if (a.paxType < b.paxType) {
                                            return -1;
                                          }
                                          if (a.paxType > b.paxType) {
                                            return 1;
                                          }
                                          return 0;
                                        })
                                        .map((listItem, index) => (
                                          <tr>
                                            <td
                                              width={150}
                                              className="text-start"
                                            >
                                              {listItem.paxType}
                                            </td>
                                            {/* <td width={500}>{listItem.baggageInfo === undefined ?( listItem.checkInBag?listItem.checkInBag:'NIL' ): listItem.baggageInfo}</td> */}
                                            {/* <td width={250} > Weight: 23 Kilogram, Weight: 51 Pound, Quantity:1, PieceMeasurements:1</td>
                                                                            <td width={250}> Weight: 23 Kilogram, Weight: 51 Pound, Quantity:1, PieceMeasurements:1</td> */}
                                            {/* <td width={250} style={{maxWidth: "250px !important",whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}> Weight: 23 Kilogram, Weight: 51 Pound, Quantity:1, PieceMeasurements:1</td>
                                                                            <td width={250} style={{maxWidth: "250px !important",whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}> Weight: 23 Kilogram, Weight: 51 Pound, Quantity:1, PieceMeasurements:1</td> */}
                                            <td width={250}>
                                              {listItem.baggageInfo ===
                                                undefined
                                                ? listItem.checkInBag
                                                  ? listItem.checkInBag
                                                  : "NIL"
                                                : listItem.baggageInfo
                                                  ? listItem.baggageInfo
                                                  : "NIL"}
                                            </td>
                                            <td width={250}>
                                              {/* {result.vendor === "1A" ||
                                                result.vendor === "1S" ||
                                                result.vendor === "1G"
                                                ? "7KG"
                                                :  */
                                                listItem.cabinBaggageInfo ===
                                                  undefined
                                                  ? listItem.cabinBag
                                                    ? listItem.cabinBag
                                                    : "NIL"
                                                  : listItem.cabinBaggageInfo
                                                    ? listItem.cabinBaggageInfo
                                                    : "NIL"}
                                            </td>
                                          </tr>
                                        ))}
                                    </table>
                                  ) : (
                                    <table className="fullHeight fareDetailsTable mb-3">
                                      <tr>
                                        <th className="text-start" colSpan={3}>
                                          {item.origin} - {item.destination}
                                        </th>
                                      </tr>
                                      <tr>
                                        <th className="text-start">{t("Baggages")}</th>
                                        <th>{t("Check In")}</th>
                                        <th>{t("Cabin")}</th>
                                      </tr>
                                      {
                                        <tr>
                                          <td
                                            width={150}
                                            className="text-start"
                                          >
                                            {result.adtNum > 0 &&
                                              result.chdNum > 0 &&
                                              result.infNum > 0
                                              ? t("ADT,CHD,INF")
                                              : result.adtNum > 0 &&
                                                result.chdNum > 0
                                                ? t("ADT,CHD")
                                                : result.adtNum > 0 &&
                                                  result.infNum > 0
                                                  ? t("ADT,INF")
                                                  : result.chdNum > 0 &&
                                                    result.infNum > 0
                                                    ? t("CHD,INF")
                                                    : result.adtNum > 0
                                                      ? t("ADT")
                                                      : result.chdNum > 0
                                                        ? t("CHD")
                                                        : result.infNum > 0
                                                          ? t("INF")
                                                          : t("ADT")}
                                          </td>
                                          <td width={250}>NIL</td>
                                          <td width={250}>
                                            {/* {result.vendor === "1A" ||
                                              result.vendor === "1S" ||
                                              result.vendor === "1G"
                                              ? "7KG"
                                              : "NIL"} */}
                                          </td>
                                        </tr>
                                      }
                                    </table>
                                  )
                                )
                              )}
                            </div>
                          ) : (
                            <div className="constants_col text_center">
                              {t(baggageError)}
                            </div>
                          )}
                        </div>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="cancel"
                      className="fullHeight fare_tabs"
                      transition={true}
                    >

                      {miniRuleError === "" ? (
                        <>
                          {result && (
                            <>
                              <MiniRulesComponent
                                value={"Cancellation"}
                                miniRules={result.fareGroups[selectedItinerary]?.miniRules}
                                currentTicket={result}
                                vendor={result.vendor}
                                currencyCode={currencyCode}
                                selectedItinerary={selectedItinerary}
                                additionalClientmarkup={additionalClientmarkup}
                                traceId={traceId}
                                clientId={clientId}
                                flag={flag}
                                refundable={result.fareGroups[selectedItinerary]?.refundable}
                                adtCount={result.adtNum}
                                chdCount={result.chdNum}
                                infCount={result.infNum}
                              />
                            </>
                          )}
                        </>

                      ) : (
                        <div className="constants_col text_center">
                          {t(miniRuleError)}
                        </div>
                      )}
                    </Tab.Pane>

                    <Tab.Pane
                      eventKey="change"
                      className="fullHeight fare_tabs"
                      transition={true}
                    >
                      {miniRuleError === "" ? (
                        <>
                          {result && (
                            <>
                              <MiniRulesComponent
                                value={"Date Change"}
                                miniRules={result.fareGroups[selectedItinerary]?.miniRules}
                                currentTicket={result}
                                vendor={result.vendor}
                                currencyCode={currencyCode}
                                selectedItinerary={selectedItinerary}
                                additionalClientmarkup={additionalClientmarkup}
                                traceId={traceId}
                                clientId={clientId}
                                flag={flag}
                                refundable={result.fareGroups[selectedItinerary]?.refundable}
                                adtCount={result.adtNum}
                                chdCount={result.chdNum}
                                infCount={result.infNum} />
                            </>
                          )}
                        </>
                      ) : (
                        <div className="constants_col text_center">
                          {t(miniRuleError)}
                        </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Row>
        )}
      </>
    );
  };


  const spliceBagtext = (input) => {
    // console.log(input);
    let text = input.replace(/['"]+/g, "");
    return text;
  };
  const modTime = (departure) => {
    let text =
      moment(
        departure.includes("+00:00")
          ? departure.replace("+00:00", "")
          : departure
      ).format("ddd,Do MMM  YYYY") +
      " " +
      moment(
        departure.includes("+00:00")
          ? departure.replace("+00:00", "")
          : departure
      ).format("HH:mm");
    return text;
  };
  function getAirportCityByCode(code) {
    const element = airportDetails.find(
      (element) => element.city_code === code
    );
    return element.city;
  }
  function getAirportData(code) {
    const element = airportDetails.find(
      (element) => element.city_code === code
    );
    return element;
  }
  async function copyToClipboard(e) {
    let itenaryText = "";
    let flighttext = "";
    let origintext = "";
    let destinationText = "";
    let durationText = "";
    let viaText = "";
    let fareText = "";
    let masterText = "";
    let layover = "";
    let layovertext = "";
    const transformedData = result.segGroups.forEach(
      (segGroup, segGroupIndex) => {
        getViaStops(segGroup.segs)
          ? (viaText = `(Via: ${getViaStops(segGroup.segs)})`)
          : (viaText = "");
        itenaryText = `${findAirportbyCode(
          segGroup.origin
        )} → ${findAirportbyCode(segGroup.destination)}  ${viaText ? viaText : ""
          }`;

        return segGroup.segs.forEach((item, index) => {
          layover =
            segGroup.segs.length - 1 !== index &&
            getLayoverTime(segGroup.segs[index], segGroup.segs[index + 1]);
          layovertext = layover ? `Layover Time: ${layover}` : "";
          item.isReturn
            ? (flighttext = `Return Flight: ${findAirlinebyCode(
              item.mrkAirline
            )} ${item.mrkAirline}-${item.flightNum}`)
            : (flighttext = `Onward Flight: ${findAirlinebyCode(
              item.mrkAirline
            )} ${item.mrkAirline}-${item.flightNum}`);
          origintext = `From: ${getAirportCityByCode(
            item.origin
          )} ${"|"} ${modTime(item.departureOn)}`;
          destinationText = `To: ${getAirportCityByCode(
            item.destination
          )} ${"|"}${modTime(item.arrivalOn)}`;
          durationText = `Total Duration: ${getDuration(item.duration)}`;
          // getViaStops(segGroup.segs)
          //   ? (viaText = `Via: ${getViaStops(segGroup.segs)}`)
          //   : (viaText = "");
          fareText = `Fare: ${currencyCode} ${getTotalFare(
            result.fareGroups[selectedItinerary],
            search.adult,
            search.children,
            search.infant
          ).total.toFixed(2)}`;
          if (index === 0) {
            viaText
              ? (masterText = `${masterText} 
${itenaryText}
${" "}
${flighttext}
${origintext}
${destinationText}
${durationText} 
${layovertext ? layovertext : ""}`)
              : (masterText = `${masterText} 
${itenaryText}
${" "}
${flighttext} 
${origintext}
${destinationText}
${durationText}
${layovertext ? layovertext : ""}`);
          } else {
            viaText
              ? (masterText = `${masterText}
${" "}
${flighttext}
${origintext}
${destinationText}
${durationText}
${layovertext ? layovertext : ""}`)
              : (masterText = `${masterText}
${" "}
${flighttext}
${origintext}
${destinationText}
${durationText}
${layovertext ? layovertext : ""}`);
          }
        });
      }
    );

    masterText = `${masterText}
 ${fareText}`;
    await navigator.clipboard
      .writeText(masterText)
      .then(() => {
        setSnackBarOpen(true);
        // console.log("Master Text", masterText);
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
      });
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const getAirlineNoOnHover = () => {
    const onwardFlightNums = result?.segGroups[0]?.segs.map(seg => `${seg.mrkAirline} - ${seg.flightNum}`).join(" , ");
    const returnFlightNums = result?.segGroups.length > 1 && result?.segGroups[1] && result?.segGroups[1].segs ? result?.segGroups[1]?.segs.map(seg => `${seg.mrkAirline} - ${seg.flightNum}`).join(" , ") : null;

    // Join onward and return flight numbers with '|' if both exist
    const AirlineNo = onwardFlightNums && returnFlightNums
      ? onwardFlightNums + " | " + returnFlightNums
      : onwardFlightNums;
    return AirlineNo;
    // Extract onward flight numbers, add opAirline if it differs from result.airline
    // const onwardFlightNums = result?.segGroups[0]?.segs.map(seg =>
    //   seg.opAirline !== result.airline
    //     ? `${seg.opAirline}-${seg.flightNum}`
    //     : seg.flightNum
    // ).join(",");

    // // Extract return flight numbers, add opAirline if it differs from result.airline
    // const returnFlightNums = result?.segGroups.length > 1 && result?.segGroups[1] && result?.segGroups[1].segs
    //   ? result?.segGroups[1]?.segs.map(seg =>
    //     seg.opAirline !== result.airline
    //       ? `${seg.opAirline}-${seg.flightNum}`
    //       : seg.flightNum
    //   ).join(",")
    //   : null;

    // // Join onward and return flight numbers with '|' if both exist
    // const AirlineNo = onwardFlightNums && returnFlightNums
    //   ? `${onwardFlightNums} | ${returnFlightNums}`
    //   : onwardFlightNums;

    // return AirlineNo;
  }
  return (
    <>
      <React.Fragment>
        <Dialog
          open={airportChangeAlertOpen}
          // onClose={setAirportChangeAlertOpen((airportChangeAlertOpen) => false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="airportChangeAlert"
        >
          <DialogTitle className="airportChangeAlert-title textCenter">
            <span>{t("Sector Change Alert")}</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div
                className={`${search.type === "OneWay"
                  ? "airportChangeAlert-top-oneway"
                  : "airportChangeAlert-top-return"
                  }`}
              >
                <div className="airportChangeAlert-left">
                  {/* <span>
                    <svg
                      id="Layer_2"
                      height="50"
                      viewBox="0 0 64 64"
                      width="50"
                      className="airportChangeAlert-left-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 2"
                    >
                      <path
                        d="m35.072 4.181a25.225 25.225 0 0 0 -3.072-.181v.01a25.225 25.225 0 0 0 -3.072.181c-10.179 1.248-16.928 10.826-16.928 20.48 0 6.237 3.126 11.993 6.319 17.321a148.248 148.248 0 0 0 10.806 15.413c.731.927 1.726 2.523 2.875 2.595v-.01c1.149-.072 2.144-1.668 2.875-2.595a148.248 148.248 0 0 0 10.806-15.413c3.193-5.328 6.319-11.082 6.319-17.321 0-9.654-6.749-19.232-16.928-20.48z"
                        fill="#d32f2f"
                      />
                      <circle cx="32" cy="24" fill="#fff" r="17" />
                      <path
                        d="m35.118 18.478v.964a.425.425 0 0 1 -.432.418h-1.686a.3.3 0 0 1 -.308-.3v-1.2a.3.3 0 0 1 .308-.3h1.683a.425.425 0 0 1 .435.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.179 15.478v.964a.425.425 0 0 1 -.431.418h-1.683a.3.3 0 0 1 -.309-.3v-1.2a.3.3 0 0 1 .309-.3h1.683a.425.425 0 0 1 .431.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m35.36 21.72-6.266-9.72h-.336v.179l-1.318-.008 1.312 2.365-.176.044 1.514 4.32.303-.06.182.84-.295.053.234 2.827h2.786l-.023-.041z"
                        fill="#d32f2f"
                      />
                      <path
                        d="m35.118 29.522v-.964a.425.425 0 0 0 -.432-.418h-1.686a.3.3 0 0 0 -.308.3v1.2a.3.3 0 0 0 .308.3h1.683a.425.425 0 0 0 .435-.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.179 32.522v-.964a.425.425 0 0 0 -.431-.418h-1.683a.3.3 0 0 0 -.309.3v1.2a.3.3 0 0 0 .309.3h1.683a.425.425 0 0 0 .431-.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.277 25.481.024-.041h-2.787l-.234 2.827.295.053-.182.84-.303-.06-1.514 4.32.176.044-1.312 2.365 1.318-.008v.179h.336l6.266-9.72z"
                        fill="#d32f2f"
                      />
                      <path
                        d="m22.41 27.6 1.7-2.14v.01l6.326.488h.033l2.311.178h.016.016l.09.009.046.006 2.29.3a1.8 1.8 0 0 1 1.212-.751l4.488-.12c3.181-.051 3.062-1.57 3.062-1.57v-.01-.01s.12-1.519-3.059-1.579l-4.488-.12a1.8 1.8 0 0 1 -1.212-.751l-2.29.3-.046.006-.09.009h-.015-.016l-2.311.178h-.033l-6.326.488v.01l-1.7-2.14-1.1-1.38h-.838l.295 1.38.707 3.3.063.3-.063.3-.707 3.3-.295 1.38h.838z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m26.4 24c0-.2-1.433-.356-3.2-.356-.634 0-1.225.021-1.723.057-.888.063-1.477.173-1.477.3 0 .126.589.236 1.477.3.5.036 1.089.057 1.723.057 1.767-.002 3.2-.158 3.2-.358z"
                        fill="#d32f2f"
                      />
                      <path
                        d="m42.363 25.046c1.138-.354 1.165-.96 1.163-1.046 0-.086-.025-.692-1.163-1.046a.283.283 0 0 0 -.2.528l.2.093c.2.09.532.259.54.425-.008.166-.343.335-.54.425l-.2.093a.283.283 0 0 0 .2.528z"
                        fill="#fff"
                      />
                    </svg>
                  </span> */}
                  <svg
                    id="Layer_2"
                    height="50"
                    viewBox="0 0 64 64"
                    width="50"
                    className="airportChangeAlert-right-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 2"
                  >
                    <path
                      d="m35.072 4.181a25.225 25.225 0 0 0 -3.072-.181v.01a25.225 25.225 0 0 0 -3.072.181c-10.179 1.248-16.928 10.826-16.928 20.48 0 6.237 3.126 11.993 6.319 17.321a148.248 148.248 0 0 0 10.806 15.413c.731.927 1.726 2.523 2.875 2.595v-.01c1.149-.072 2.144-1.668 2.875-2.595a148.248 148.248 0 0 0 10.806-15.413c3.193-5.328 6.319-11.082 6.319-17.321 0-9.654-6.749-19.232-16.928-20.48z"
                      fill="#307d01"
                    />
                    <circle cx="32" cy="24" fill="#fff" r="17" />
                    <path
                      d="m35.118 18.478v.964a.425.425 0 0 1 -.432.418h-1.686a.3.3 0 0 1 -.308-.3v-1.2a.3.3 0 0 1 .308-.3h1.683a.425.425 0 0 1 .435.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m33.179 15.478v.964a.425.425 0 0 1 -.431.418h-1.683a.3.3 0 0 1 -.309-.3v-1.2a.3.3 0 0 1 .309-.3h1.683a.425.425 0 0 1 .431.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m35.36 21.72-6.266-9.72h-.336v.179l-1.318-.008 1.312 2.365-.176.044 1.514 4.32.303-.06.182.84-.295.053.234 2.827h2.786l-.023-.041z"
                      fill="#307d01"
                    />
                    <path
                      d="m35.118 29.522v-.964a.425.425 0 0 0 -.432-.418h-1.686a.3.3 0 0 0 -.308.3v1.2a.3.3 0 0 0 .308.3h1.683a.425.425 0 0 0 .435-.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m33.179 32.522v-.964a.425.425 0 0 0 -.431-.418h-1.683a.3.3 0 0 0 -.309.3v1.2a.3.3 0 0 0 .309.3h1.683a.425.425 0 0 0 .431-.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m33.277 25.481.024-.041h-2.787l-.234 2.827.295.053-.182.84-.303-.06-1.514 4.32.176.044-1.312 2.365 1.318-.008v.179h.336l6.266-9.72z"
                      fill="#307d01"
                    />
                    <path
                      d="m22.41 27.6 1.7-2.14v.01l6.326.488h.033l2.311.178h.016.016l.09.009.046.006 2.29.3a1.8 1.8 0 0 1 1.212-.751l4.488-.12c3.181-.051 3.062-1.57 3.062-1.57v-.01-.01s.12-1.519-3.059-1.579l-4.488-.12a1.8 1.8 0 0 1 -1.212-.751l-2.29.3-.046.006-.09.009h-.015-.016l-2.311.178h-.033l-6.326.488v.01l-1.7-2.14-1.1-1.38h-.838l.295 1.38.707 3.3.063.3-.063.3-.707 3.3-.295 1.38h.838z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m26.4 24c0-.2-1.433-.356-3.2-.356-.634 0-1.225.021-1.723.057-.888.063-1.477.173-1.477.3 0 .126.589.236 1.477.3.5.036 1.089.057 1.723.057 1.767-.002 3.2-.158 3.2-.358z"
                      fill="#307d01"
                    />
                    <path
                      d="m42.363 25.046c1.138-.354 1.165-.96 1.163-1.046 0-.086-.025-.692-1.163-1.046a.283.283 0 0 0 -.2.528l.2.093c.2.09.532.259.54.425-.008.166-.343.335-.54.425l-.2.093a.283.283 0 0 0 .2.528z"
                      fill="#fff"
                    />
                  </svg>
                  <h4>
                    {getAirportData(search?.from?.city_code)?.city +
                      " (" +
                      search?.from?.city_code +
                      ")"}
                  </h4>
                </div>
                <div className="airportChangeAlert-right">
                  <span>
                    <svg
                      id="Layer_2"
                      height="50"
                      viewBox="0 0 64 64"
                      width="50"
                      className="airportChangeAlert-right-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 2"
                    >
                      <path
                        d="m35.072 4.181a25.225 25.225 0 0 0 -3.072-.181v.01a25.225 25.225 0 0 0 -3.072.181c-10.179 1.248-16.928 10.826-16.928 20.48 0 6.237 3.126 11.993 6.319 17.321a148.248 148.248 0 0 0 10.806 15.413c.731.927 1.726 2.523 2.875 2.595v-.01c1.149-.072 2.144-1.668 2.875-2.595a148.248 148.248 0 0 0 10.806-15.413c3.193-5.328 6.319-11.082 6.319-17.321 0-9.654-6.749-19.232-16.928-20.48z"
                        fill="#307d01"
                      />
                      <circle cx="32" cy="24" fill="#fff" r="17" />
                      <path
                        d="m35.118 18.478v.964a.425.425 0 0 1 -.432.418h-1.686a.3.3 0 0 1 -.308-.3v-1.2a.3.3 0 0 1 .308-.3h1.683a.425.425 0 0 1 .435.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.179 15.478v.964a.425.425 0 0 1 -.431.418h-1.683a.3.3 0 0 1 -.309-.3v-1.2a.3.3 0 0 1 .309-.3h1.683a.425.425 0 0 1 .431.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m35.36 21.72-6.266-9.72h-.336v.179l-1.318-.008 1.312 2.365-.176.044 1.514 4.32.303-.06.182.84-.295.053.234 2.827h2.786l-.023-.041z"
                        fill="#307d01"
                      />
                      <path
                        d="m35.118 29.522v-.964a.425.425 0 0 0 -.432-.418h-1.686a.3.3 0 0 0 -.308.3v1.2a.3.3 0 0 0 .308.3h1.683a.425.425 0 0 0 .435-.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.179 32.522v-.964a.425.425 0 0 0 -.431-.418h-1.683a.3.3 0 0 0 -.309.3v1.2a.3.3 0 0 0 .309.3h1.683a.425.425 0 0 0 .431-.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.277 25.481.024-.041h-2.787l-.234 2.827.295.053-.182.84-.303-.06-1.514 4.32.176.044-1.312 2.365 1.318-.008v.179h.336l6.266-9.72z"
                        fill="#307d01"
                      />
                      <path
                        d="m22.41 27.6 1.7-2.14v.01l6.326.488h.033l2.311.178h.016.016l.09.009.046.006 2.29.3a1.8 1.8 0 0 1 1.212-.751l4.488-.12c3.181-.051 3.062-1.57 3.062-1.57v-.01-.01s.12-1.519-3.059-1.579l-4.488-.12a1.8 1.8 0 0 1 -1.212-.751l-2.29.3-.046.006-.09.009h-.015-.016l-2.311.178h-.033l-6.326.488v.01l-1.7-2.14-1.1-1.38h-.838l.295 1.38.707 3.3.063.3-.063.3-.707 3.3-.295 1.38h.838z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m26.4 24c0-.2-1.433-.356-3.2-.356-.634 0-1.225.021-1.723.057-.888.063-1.477.173-1.477.3 0 .126.589.236 1.477.3.5.036 1.089.057 1.723.057 1.767-.002 3.2-.158 3.2-.358z"
                        fill="#307d01"
                      />
                      <path
                        d="m42.363 25.046c1.138-.354 1.165-.96 1.163-1.046 0-.086-.025-.692-1.163-1.046a.283.283 0 0 0 -.2.528l.2.093c.2.09.532.259.54.425-.008.166-.343.335-.54.425l-.2.093a.283.283 0 0 0 .2.528z"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                  <h4>
                    {getAirportData(search?.to?.city_code)?.city +
                      " (" +
                      search?.to?.city_code +
                      ")"}
                  </h4>
                </div>
              </div>
              <div className="airportChangeAlert-content">
                <p className="m0 textCenter" style={{ color: "#212529" }}>
                  {t("you have searched for")}
                </p>
                {/* <br></br> */}
                <p className="m0 textCenter colorRed">
                  {" "}
                  <b> {airportChangeAlertSearchText}</b>
                </p>
                {/* <br></br> */}
                <p className="m0 textCenter" style={{ color: "#212529" }}>
                  {t("but You have selected")}{" "}
                </p>
                {/* <br></br> */}

                {airportChangeAlertSelectedOnwardText &&
                  airportChangeAlertSelectedOnwardText.length > 0 &&
                  airportChangeAlertSelectedReturnText &&
                  airportChangeAlertSelectedReturnText.length > 0 ? (
                  <p className="m0 textCenter colorGreen">
                    <b>
                      {" "}
                      <span
                        className="colorBlack"
                        style={{ paddingRight: "5px" }}
                      >
                        {t("Onward")}:
                      </span>{" "}
                      {airportChangeAlertSelectedOnwardText},
                    </b>
                    <br></br>
                    <b>
                      {" "}
                      <span
                        className="colorBlack"
                        style={{ paddingRight: "5px" }}
                      >
                        {t("Return")}:
                      </span>
                      {airportChangeAlertSelectedReturnText}
                    </b>
                  </p>
                ) : airportChangeAlertSelectedOnwardText &&
                  airportChangeAlertSelectedOnwardText.length > 0 &&
                  !airportChangeAlertSelectedReturnText &&
                  airportChangeAlertSelectedReturnText.length === 0 ? (
                  <p className="m0 textCenter colorGreen">
                    <b>
                      {" "}
                      <span
                        className="colorBlack"
                        style={{ paddingRight: "5px" }}
                      >
                        {t("Onward")}:
                      </span>
                      {airportChangeAlertSelectedOnwardText}
                    </b>
                    {/* <br></br>
                      <b> {airportChangeAlertSelectedReturnText},</b> */}
                  </p>
                ) : !airportChangeAlertSelectedOnwardText &&
                  airportChangeAlertSelectedOnwardText.length === 0 &&
                  airportChangeAlertSelectedReturnText &&
                  airportChangeAlertSelectedReturnText.length > 0 ? (
                  <p className="m0 textCenter colorGreen">
                    {/* <b> {airportChangeAlertSelectedOnwardText},</b>
                        <br></br> */}
                    <b>
                      {" "}
                      <span
                        className="colorBlack"
                        style={{ paddingRight: "5px" }}
                      >
                        {t("Return")}:
                      </span>
                      {airportChangeAlertSelectedReturnText}
                    </b>
                  </p>
                ) : (
                  ""
                )}

                {/* <br></br>
                <br></br> */}
                <p className="m0 textCenter" style={{ color: "#212529" }}>
                  {t("Do you want to proceed with the selected sectors?")}{" "}
                </p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="airportChangeAlert-footer">
            <Button
              className="srn-btn1"
              variant="contained"
              onClick={cancelContinueToReval}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={checkforEqpTypeAlert}
              autoFocus
              variant="contained"
              className="srn-btn marginBR5"
            >
              {t("Proceed")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={eqpTypeAlertOpen}
          // onClose={setAirportChangeAlertOpen((airportChangeAlertOpen) => false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="airportChangeAlert"
        >
          <DialogTitle className="airportChangeAlert-title textCenter">
            <span>{t("Alert")}</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div
                className={`${search.type === "OneWay"
                  ? "airportChangeAlert-top-oneway"
                  : "airportChangeAlert-top-return"
                  }`}
              >
                <div className="airportChangeAlert-left">
                  <svg
                    id="Layer_2"
                    height="50"
                    viewBox="0 0 64 64"
                    width="50"
                    className="airportChangeAlert-right-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 2"
                  >
                    <path
                      d="m35.072 4.181a25.225 25.225 0 0 0 -3.072-.181v.01a25.225 25.225 0 0 0 -3.072.181c-10.179 1.248-16.928 10.826-16.928 20.48 0 6.237 3.126 11.993 6.319 17.321a148.248 148.248 0 0 0 10.806 15.413c.731.927 1.726 2.523 2.875 2.595v-.01c1.149-.072 2.144-1.668 2.875-2.595a148.248 148.248 0 0 0 10.806-15.413c3.193-5.328 6.319-11.082 6.319-17.321 0-9.654-6.749-19.232-16.928-20.48z"
                      fill="#307d01"
                    />
                    <circle cx="32" cy="24" fill="#fff" r="17" />
                    <path
                      d="m35.118 18.478v.964a.425.425 0 0 1 -.432.418h-1.686a.3.3 0 0 1 -.308-.3v-1.2a.3.3 0 0 1 .308-.3h1.683a.425.425 0 0 1 .435.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m33.179 15.478v.964a.425.425 0 0 1 -.431.418h-1.683a.3.3 0 0 1 -.309-.3v-1.2a.3.3 0 0 1 .309-.3h1.683a.425.425 0 0 1 .431.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m35.36 21.72-6.266-9.72h-.336v.179l-1.318-.008 1.312 2.365-.176.044 1.514 4.32.303-.06.182.84-.295.053.234 2.827h2.786l-.023-.041z"
                      fill="#307d01"
                    />
                    <path
                      d="m35.118 29.522v-.964a.425.425 0 0 0 -.432-.418h-1.686a.3.3 0 0 0 -.308.3v1.2a.3.3 0 0 0 .308.3h1.683a.425.425 0 0 0 .435-.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m33.179 32.522v-.964a.425.425 0 0 0 -.431-.418h-1.683a.3.3 0 0 0 -.309.3v1.2a.3.3 0 0 0 .309.3h1.683a.425.425 0 0 0 .431-.418z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m33.277 25.481.024-.041h-2.787l-.234 2.827.295.053-.182.84-.303-.06-1.514 4.32.176.044-1.312 2.365 1.318-.008v.179h.336l6.266-9.72z"
                      fill="#307d01"
                    />
                    <path
                      d="m22.41 27.6 1.7-2.14v.01l6.326.488h.033l2.311.178h.016.016l.09.009.046.006 2.29.3a1.8 1.8 0 0 1 1.212-.751l4.488-.12c3.181-.051 3.062-1.57 3.062-1.57v-.01-.01s.12-1.519-3.059-1.579l-4.488-.12a1.8 1.8 0 0 1 -1.212-.751l-2.29.3-.046.006-.09.009h-.015-.016l-2.311.178h-.033l-6.326.488v.01l-1.7-2.14-1.1-1.38h-.838l.295 1.38.707 3.3.063.3-.063.3-.707 3.3-.295 1.38h.838z"
                      fill="#b1eafc"
                    />
                    <path
                      d="m26.4 24c0-.2-1.433-.356-3.2-.356-.634 0-1.225.021-1.723.057-.888.063-1.477.173-1.477.3 0 .126.589.236 1.477.3.5.036 1.089.057 1.723.057 1.767-.002 3.2-.158 3.2-.358z"
                      fill="#307d01"
                    />
                    <path
                      d="m42.363 25.046c1.138-.354 1.165-.96 1.163-1.046 0-.086-.025-.692-1.163-1.046a.283.283 0 0 0 -.2.528l.2.093c.2.09.532.259.54.425-.008.166-.343.335-.54.425l-.2.093a.283.283 0 0 0 .2.528z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div className="airportChangeAlert-right">
                  <span>
                    <svg
                      id="Layer_2"
                      height="50"
                      viewBox="0 0 64 64"
                      width="50"
                      className="airportChangeAlert-right-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 2"
                    >
                      <path
                        d="m35.072 4.181a25.225 25.225 0 0 0 -3.072-.181v.01a25.225 25.225 0 0 0 -3.072.181c-10.179 1.248-16.928 10.826-16.928 20.48 0 6.237 3.126 11.993 6.319 17.321a148.248 148.248 0 0 0 10.806 15.413c.731.927 1.726 2.523 2.875 2.595v-.01c1.149-.072 2.144-1.668 2.875-2.595a148.248 148.248 0 0 0 10.806-15.413c3.193-5.328 6.319-11.082 6.319-17.321 0-9.654-6.749-19.232-16.928-20.48z"
                        fill="#307d01"
                      />
                      <circle cx="32" cy="24" fill="#fff" r="17" />
                      <path
                        d="m35.118 18.478v.964a.425.425 0 0 1 -.432.418h-1.686a.3.3 0 0 1 -.308-.3v-1.2a.3.3 0 0 1 .308-.3h1.683a.425.425 0 0 1 .435.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.179 15.478v.964a.425.425 0 0 1 -.431.418h-1.683a.3.3 0 0 1 -.309-.3v-1.2a.3.3 0 0 1 .309-.3h1.683a.425.425 0 0 1 .431.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m35.36 21.72-6.266-9.72h-.336v.179l-1.318-.008 1.312 2.365-.176.044 1.514 4.32.303-.06.182.84-.295.053.234 2.827h2.786l-.023-.041z"
                        fill="#307d01"
                      />
                      <path
                        d="m35.118 29.522v-.964a.425.425 0 0 0 -.432-.418h-1.686a.3.3 0 0 0 -.308.3v1.2a.3.3 0 0 0 .308.3h1.683a.425.425 0 0 0 .435-.418z"
                        fill="#b1eafc"
                      />

                      <path
                        d="m33.179 32.522v-.964a.425.425 0 0 0 -.431-.418h-1.683a.3.3 0 0 0 -.309.3v1.2a.3.3 0 0 0 .309.3h1.683a.425.425 0 0 0 .431-.418z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m33.277 25.481.024-.041h-2.787l-.234 2.827.295.053-.182.84-.303-.06-1.514 4.32.176.044-1.312 2.365 1.318-.008v.179h.336l6.266-9.72z"
                        fill="#307d01"
                      />
                      <path
                        d="m22.41 27.6 1.7-2.14v.01l6.326.488h.033l2.311.178h.016.016l.09.009.046.006 2.29.3a1.8 1.8 0 0 1 1.212-.751l4.488-.12c3.181-.051 3.062-1.57 3.062-1.57v-.01-.01s.12-1.519-3.059-1.579l-4.488-.12a1.8 1.8 0 0 1 -1.212-.751l-2.29.3-.046.006-.09.009h-.015-.016l-2.311.178h-.033l-6.326.488v.01l-1.7-2.14-1.1-1.38h-.838l.295 1.38.707 3.3.063.3-.063.3-.707 3.3-.295 1.38h.838z"
                        fill="#b1eafc"
                      />
                      <path
                        d="m26.4 24c0-.2-1.433-.356-3.2-.356-.634 0-1.225.021-1.723.057-.888.063-1.477.173-1.477.3 0 .126.589.236 1.477.3.5.036 1.089.057 1.723.057 1.767-.002 3.2-.158 3.2-.358z"
                        fill="#307d01"
                      />
                      <path
                        d="m42.363 25.046c1.138-.354 1.165-.96 1.163-1.046 0-.086-.025-.692-1.163-1.046a.283.283 0 0 0 -.2.528l.2.093c.2.09.532.259.54.425-.008.166-.343.335-.54.425l-.2.093a.283.283 0 0 0 .2.528z"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="">
                {
                  <p className="m0 textCenter" style={{ color: "#212529" }}>
                    {t("The chosen itinerary is for a")}{" "}
                    <span className="eqptype">{t(eqpType)}</span> {t("service")}
                  </p>
                }

                <p className="m0 textCenter" style={{ color: "#212529" }}>
                  {t("Would you like to proceed with this selection or opt for a different flight?")}{" "}
                </p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="airportChangeAlert-footer">
            <Button
              className="srn-btn1"
              variant="contained"
              onClick={eqptypecancelContinueToReval}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => continueToReval()}
              autoFocus
              variant="contained"
              className="srn-btn marginBR5"
            >
              {t("Proceed")}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
          position
        >
          {t("Content Copied....")}
        </Alert>
      </Snackbar>

      <Col
        className={`itinerary_container ${result.isHighlightedItem && result.isTopHighlightedItem ? "topHighlightedItem" : result.isHighlightedItem && !result.isTopHighlightedItem ? "highlightedItem" : ""}`}
        id="itinerary_container"
        key={`ItineraryDetails_` + arrayOf}
      >
        <Row className="pb-2">
          <Col className="airline_details col-auto">
            <BootstrapTooltip
              title={
                result.vendor !== "NotAssigned" || result.vendor !== undefined
                  ? result.content + " - " + result.vendor
                  : result.content
                    ? result.content
                    : ""
              }
            >
              <span>
                {result.airline !== undefined ? (
                  <>
                    <AirlineLogo val={result.airline} />
                  </>
                ) : (
                  <FlightTakeoffIcon
                    className="primary-fill"
                    fontSize="small"
                  />
                )}
              </span>
            </BootstrapTooltip>

            <BootstrapTooltip title={getAirlineNoOnHover()
            }

            >
              <span>{(result?.segGroups && result?.segGroups?.length > 0 && result?.segGroups[0]?.segs && result?.segGroups[0]?.segs?.length > 0 && result?.segGroups[0]?.segs[0].flightNum) ? " | " + result?.segGroups[0]?.segs[0].flightNum : ''}</span>
            </BootstrapTooltip>
          </Col>
          <Col className="pad0">
            <span
              className="flight_details_btn"

            // data-bs-toggle="collapse"
            // data-bs-target={`#airlineDetails_` + arrayOf}
            >
              {" "}
              <span
                onClick={() => setToggleFlightDetails(!toggleFlightDetails)}
              >
                {t("Flight Details")}
                {toggleFlightDetails ? (
                  <KeyboardArrowUpRoundedIcon fontSize="medium" />
                ) : (
                  <KeyboardArrowDownRoundedIcon fontSize="medium" />
                )}{" "}
              </span>
              <span>
                <BootstrapTooltip title={"Copy"}>
                  <ContentCopyIcon
                    onClick={copyToClipboard}
                    className="contentCopyIcon"
                  />
                </BootstrapTooltip>
              </span>
            </span>
          </Col>
        </Row>
        <Row
          className={"collapse " + (toggleFlightDetails ? " show" : "hide")}
          id={`airlineDetails_` + arrayOf}
        >
          <div className="col-12 flt-dtls-section">
            {result.vendor === "TF" ? (
              <>
                <Row className="mt-3 mx-2 pb-0">
                  <div className="col-12 flt-highlight-label px-0">
                    <div className="badge flt-imp-badge-label mx-2">
                      {t("Important Notice")}
                    </div>
                    <div className="flt-imp-msg px-2">
                      {t("** Instant Purchase fare.")}
                      {t("Fares not guaranteed until ticketed.")}
                      {t("For Check IN & Boarding formalities kindly refer to Terms & Conditions as mentioned on the booked Airline website .")}
                      {t("Many global low cost carriers may have mandatory web check-in requirement. **")}
                    </div>
                  </div>
                </Row>
              </>
            ) : (
              ""
            )}
            <Tickets
              result={result}
              fareGroups={result.fareGroups[0]}
              segGroups={result.segGroups}
            ></Tickets>
          </div>
        </Row>
        <Row>
          {result.segGroups && result.segGroups.length === 1 ? (
            <ItineraryConstants
              details={result.segGroups}
              localIndex={0}
              type={"oneway"}
            />
          ) : (
            <>
              <ItineraryConstants
                details={result.segGroups}
                localIndex={0}
                type={"_return"}
              />
              <ItineraryConstants
                details={result.segGroups}
                localIndex={1}
                type={"_return"}
              />
            </>
          )}
        </Row>
        {isBrandedFare ? (
          <BrandedFareComponent
            arrayof={arrayOf}
            result={result}
            selectedItinerary={selectedItinerary}
            setSelectedItinerary={setSelectedItinerary}
            filterAndSortBaggage={filterAndSortBaggage}
            spliceBagtext={spliceBagtext}
            currencyCode={currencyCode}
            traceId={traceId}
            clientId={clientId}
            toggleFDIcon={toggleFDIcon}
            getFareDetails={getFareDetails}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            fareSegGroups={fareSegGroups}
            baggageLis={baggageList}
            getAdultPrice={getAdultPrice}
            getChildrenPrice={getChildrenPrice}
            getInfantPrice={getInfantPrice}
            getAdultTaxPrice={getAdultTaxPrice}
            getChildrenTaxPrice={getChildrenTaxPrice}
            getInfantTaxPrice={getInfantTaxPrice}
            getMiniRulesData={getMiniRulesData}
            filterAdultCancellation={filterAdultCancellation}
            filterInfantCancellation={filterInfantCancellation}
            filterChildCancellation={filterChildCancellation}
            filterAdultChangeDate={filterAdultChangeDate}
            filterInfantChangeDate={filterInfantChangeDate}
            filterChildChangeDate={filterChildChangeDate}
            proceedToBook={proceedToBook}
            showNetfare={showNetfare}
            itineraryMarkup={itineraryMarkup}
            setItineraryMarkup={setItineraryMarkup}
            adultcount={adultcount}
            childcount={childcount}
            infantcount={infantcount}
          />
        ) : (
          <>
            {result.fareGroups && result.fareGroups.length > 0 && (
              <FareRows
                individualFare={result.fareGroups[0]}
                index={0}
                showToggleBtn={
                  result.fareGroups && result.fareGroups.length > 1
                    ? true
                    : false
                }
              />
            )}
            {result.fareGroups && result.fareGroups.length > 1 && (
              <div
                // className="collapse more_fare_details"
                className={
                  "collapse more_fare_detail" +
                  (toggleShowHideFareDtls ? " show" : "hide")
                }
                id={`multiFareDetails_` + arrayOf}
              >
                {result.fareGroups.map((individualFare, index) => {
                  return (
                    <>
                      <FareRows
                        individualFare={individualFare}
                        index={index}
                        showToggleBtn={false}
                      />
                    </>
                  );
                })}
              </div>
            )}
          </>
        )}
      </Col>

      {showLoader ? (
        <RiseLoading isLoading={showLoader} isBaggageLoading={true} />
      ) : (
        ""
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={markupSnackbar}
        message={t("Markup Added!")}
      />
    </>
  );
}
