import React, { useEffect, useState } from "react";
import "./DatePicker.css";
import { DayPicker, SelectSingleEventHandler } from "react-day-picker";
import { formatDistance, isBefore, subDays } from "date-fns";
import "react-day-picker/dist/style.css";
import Button from "@material-ui/core/Button";
import ArrowRightAltRoundedIcon from "@material-ui/icons/ArrowRightAltRounded";
import Alert from "@material-ui/lab/Alert";
import months from "../../data/Months";
import moment from "moment";
import { useTranslation } from "react-i18next";
export default function DateRangePicker({
  destination,
  search,
  setSearch,
  closeMenu,
  flag,
}) {
  const numberOfMonths = 2;
  const { t } = useTranslation();
  const [rangeDay, setRangeDay] = useState({
    from: null,
    to: null,
    enteredTo: null,
  });
  const [showAlert, setShowAlert] = useState(false);
  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const modifiers = { start: new Date(rangeDay.from), end: rangeDay.enteredTo };
  let condition =
    search?.depart && flag === "Return"
      ? { before: moment(search?.depart) }
      : moment(search?.depart) && flag === "Depart"
        ? { before: new Date() }
        : { before: new Date() };
  const disabledDays = { condition };
  let selectedDays = [
    new Date(rangeDay.from),
    { from: new Date(rangeDay.from), to: rangeDay.enteredTo },
    rangeDay.to,
  ];
  const [mode, setMode] = useState("");
  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && isBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };
  useEffect(() => {
    if (new Date(rangeDay.from) && rangeDay.to) {
      setShowAlert(false);
    } else if (!new Date(rangeDay.from) || !rangeDay.to) {
      setShowAlert(true);
    }
    console.log("rangeday", rangeDay);
  }, [rangeDay]);
  useEffect(() => {

    console.log("mode", mode);
  }, [mode]);
  useEffect(() => {
    let from = search?.depart ? (search?.depart) : moment()
    let to = (search?.return) ? (search?.return) : moment(search?.depart).add(1, 'd')
    // if (from && to) {
    setRangeDay({
      from: from,
      to: to,
      enteredTo: to,
    });
    // }
  }, [search]);
  useEffect(() => {

    console.log("flag", flag);
    setMode(flag);
  }, [flag]);

  const handleDayClick = (day) => {
    const obj = {
      from: null,
      to: null,
      enteredTo: null,
    };
    const existingSearch = { ...search };

    const clickedDate = new Date(day);
    const fromDate = new Date(rangeDay.from);
    const toDate = new Date(rangeDay.to);

    if (mode === "Depart") {
      if (fromDate && toDate) {
        if (isSelectingFirstDay(fromDate, toDate, clickedDate)) {
          obj.from = day;
          obj.to = null;
          obj.enteredTo = null;
          setRangeDay(obj);
          setMode("Return");
        } else if (clickedDate <= fromDate || clickedDate >= toDate) {
          obj.from = day;
          obj.to = null;
          obj.enteredTo = null;
          setRangeDay(obj);
          setMode("Return");
        } else {
          obj.from = day;
          obj.to = rangeDay.to;
          obj.enteredTo = rangeDay.to;
          setRangeDay(obj);
        }
      } else {
        obj.from = day;
        obj.to = null;
        obj.enteredTo = null;
        setRangeDay(obj);
        setMode("Return");
      }
    } else if (mode === "Return") {
      if (fromDate) {
        if (clickedDate >= fromDate) {
          obj.from = fromDate;
          obj.to = day;
          obj.enteredTo = day;
          setRangeDay(obj);
        } else {
          obj.from = day;
          obj.to = null;
          obj.enteredTo = null;
          setRangeDay(obj);
          setMode("Depart");
        }
      } else {
        obj.from = day;
        obj.to = null;
        obj.enteredTo = null;
        setRangeDay(obj);
      }
    }

    existingSearch.depart = obj.from;
    existingSearch.return = obj.to;
    setSearch(existingSearch);

    if (search?.type === "RoundTrip") {
      if (existingSearch?.depart && existingSearch?.return) {
        closeMenu();
      } else {
        setMode("Return");
      }
    } else {
      closeMenu();
    }
  };


  const handleDayMouseEnter = (day) => {
    if (!isSelectingFirstDay(new Date(rangeDay.from), rangeDay.to, day)) {
      setRangeDay({
        from: new Date(rangeDay.from),
        to: rangeDay.to,
        enteredTo: day,
      });
    }
  };

  const handleResetClick = () => {
    // setShowAlert(true);
    setRangeDay({
      from: null,
      to: null,
      enteredTo: null,
    });
  };

  const handleSingleDateClick = (day) => {
    const existingSearch = { ...search };
    if (
      destination &&
      Object.keys(destination).length > 0 &&
      existingSearch?.destinations.length > 0
    ) {
      const currentDestinationIndex = existingSearch?.destinations.findIndex(
        (item) => item.id === destination.id
      );
      existingSearch.destinations[currentDestinationIndex].depart = day;
    } else {
      existingSearch.depart = day;
    }
    checkSuccessorJourneyDates(existingSearch);
    closeMenu();
  };

  const checkSuccessorJourneyDates = (existingSearch) => {
    if (existingSearch?.destinations.length > 0) {
      let currentDestinationIndex = -1;
      if (destination) {
        currentDestinationIndex = existingSearch?.destinations.findIndex(
          (item) => item.id === destination.id
        );
      }
      for (
        let startIndex = currentDestinationIndex + 1;
        startIndex < existingSearch?.destinations.length;
        startIndex++
      ) {
        if (
          isTodayOrAfter(
            currentDestinationIndex !== -1
              ? existingSearch.destinations[currentDestinationIndex].depart
              : existingSearch.depart,
            existingSearch.destinations[startIndex].depart
          )
        ) {
          existingSearch.destinations[startIndex].depart =
            currentDestinationIndex !== -1
              ? existingSearch?.destinations[currentDestinationIndex].depart
              : existingSearch?.depart;
        }
      }
    }
    setSearch(existingSearch);
  };

  const isTodayOrAfter = (firstDate, secondDate) => {
    return firstDate.getTime() >= secondDate.getTime();
  };

  const getDisabledDates = () => {
    const existingSearch = { ...search };
    if (
      JSON.stringify(existingSearch?.destinations[0]) ===
      JSON.stringify(destination)
    ) {
      return existingSearch?.depart;
    } else {
      const currentDestinationIndex = existingSearch?.destinations.findIndex(
        (item) => item.id === destination.id
      );
      return existingSearch?.destinations[currentDestinationIndex - 1].depart;
    }
  };

  const getToMonths = () => {
    const currentMonth = new Date().getMonth();
    const date = new Date().getDate();
    let year = new Date().getFullYear();
    let totalMonths = currentMonth + 11;
    if (totalMonths > 11) {
      totalMonths = totalMonths - 11;
      year = year + 1;
    }
    return new Date(year, totalMonths - 1, date);
  };

  return (
    <div>
      {search?.type == "RoundTrip" ? (
        <div className="date-range-picker-header">
          <span style={{ float: "left", padding: "10px" }}>
            {t("Select Journey Date")}
          </span>
          {/* {
              search?.type === "RoundTrip" && !showAlert ? <Button variant="outlined" color="primary" style={{ float: 'right' }} onClick={() => {
                updateSearch();
              }}>Done</Button>
                : 
                <Alert severity="error">
                  {alertText}
                </Alert>
            } */}
          <div style={{ textAlign: "end" }}>
            {new Date(rangeDay.from) === null || new Date(rangeDay.from) === "" ? (
              ""
            ) : (
              <span
                className={
                  mode === "Depart"
                    ? "defaultDate currentSelection"
                    : "defaultDate"
                }
              >
                {" "}
                {search?.depart && weekday[(new Date(search?.depart)).getDay()]},
                {new Date(rangeDay.from) && new Date(rangeDay.from).getDate()},
                {new Date(rangeDay.from) && months[new Date(rangeDay.from).getMonth()]}
              </span>
            )}
            {new Date(rangeDay.from) === null || new Date(rangeDay.from) === "" ? (
              ""
            ) : (
              <span className={"defaultDate"}>-</span>
            )}

            {new Date(rangeDay.from) === null || new Date(rangeDay.from) === "" ? (
              ""
            ) : rangeDay.to === null || rangeDay.to === "" ? (
              <span
                className={"defaultDate currentSelection"}
                style={{ opacity: "0.7" }}
              >
                Return Date
              </span>
            ) : (
              <span
                className={
                  mode === "Return"
                    ? "defaultDate currentSelection"
                    : "defaultDate"
                }
              >
                {weekday[new Date(rangeDay.to).getDay()]},{new Date(rangeDay.to).getDate()},
                {months[new Date(rangeDay.to).getMonth()]}
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className="date-range-picker-header">
          <span style={{ float: "left", padding: "10px" }}>
            {t("Select Journey Date")}
          </span>
        </div>
      )}
      <div className="date-range-picker-">
        {search?.type === "RoundTrip" ? (
          <DayPicker
            className="Range"
            renderDay={renderDay}
            navbarElement={<Navbar />}
            numberOfMonths={2}
            fromMonth={new Date()}
            toMonth={getToMonths()}
            selectedDays={selectedDays}
            // disabledDays={disabledDays}
            disabled={[{ before: new Date() }]}
            modifiers={modifiers}
            onDayClick={handleDayClick}
            onDayMouseEnter={handleDayMouseEnter}
          />
        ) : (
          <DayPicker
            numberOfMonths={numberOfMonths}
            navbarElement={<Navbar />}
            toMonth={getToMonths()}
            fromMonth={new Date()}
            selectedDays={moment(search?.depart)}
            // selectedDays={
            //   destination && Object.keys(destination).length > 0
            //     ? destination.depart
            //     : search?.depart
            // }
            onDayClick={handleSingleDateClick}
            renderDay={(day, modifiers) =>
              renderDay(day, search?.type, modifiers)
            }
            // disabledDays={[
            //   {
            //     before:
            //       destination && Object.keys(destination).length > 0
            //         ? getDisabledDates()
            //         : new Date(),
            //   },
            // ]}
            disabled={[{ before: new Date() }]}

          />
        )}
      </div>
    </div>
  );
}

function getToMonths() {
  const currentMonth = new Date().getMonth();
  const date = new Date().getDate();
  let year = new Date().getFullYear();
  let totalMonths = currentMonth + 11;
  if (totalMonths > 11) {
    totalMonths = totalMonths - 11;
    year = year + 1;
  }
  return new Date(year, totalMonths, date);
}

function Navbar({
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
  month,
}) {
  return (
    <div className={className}>
      <div className="custom-navigation-header">
        <div>
          <ArrowRightAltRoundedIcon
            onClick={() => onPreviousClick()}
            className={`reverse ${new Date(month).getTime() <= new Date().getTime()
              ? " disabled events-none"
              : "pointer"
              }`}
          />
        </div>
        <div>
          <ArrowRightAltRoundedIcon
            onClick={() => onNextClick()}
            className={`${(new Date(month).getMonth() === getToMonths().getMonth() - 1 &&
              new Date(month).getYear() === getToMonths().getYear()) ||
              (new Date(month).getMonth() === getToMonths().getMonth() &&
                new Date(month).getYear() === getToMonths().getYear())
              ? " disabled events-none"
              : "pointer"
              }`}
          />
        </div>
      </div>
    </div>
  );
}

function renderDay(day, searchType, modifiers) {
  const date = day.getDate();
  return (
    <div
      className={`cell-style ${modifiers &&
        Object.keys(modifiers).length > 0 &&
        "disabled" in modifiers
        ? "restrict-cursor-events"
        : ""
        }`}
    >
      <div className="date-style">{date}</div>
    </div>
  );
}
