import React from "react";
import { useTranslation } from "react-i18next";
export default function Terms() {
        const { t } = useTranslation();
        return (
                <div style={{ margin: " 0 45px" }}>
                        <h4 style={{ marginTop: "10px", marginBottom: "20px", }}>{t("Terms of Use")} </h4>
                        <h6>{t("PLEASE READ THESE TERMS OF USE CAREFULLY; THIS IS A BINDING CONTRACT")}</h6>
                        <p style={{ marginTop: "2px" }}> {t("The terms 'we' and 'us' refer to Getfares.com (Akbar Travels of India S.R.L) and its affiliates, and 'you' refers to the customer booking a affiliates through whom we provide services.")}
                                {t("These terms describe the terms and conditions applicable to your access and use of this website.")}
                                {t("We reserve the right to change these terms,conditions and notices and you agree to accept and be bound by the terms, conditions and notices that are in effect at the time of your use of our website and facilities.")}</p>
                        <h6>{t("INFORMATION AVAILABLE ON THIS WEBSITE")}</h6>
                        <p style={{ marginTop: "2px", fontSize: "10" }}>
                                {t("The content available on this website is intended to be a general information resource and is provided solely on an “AS IS” and “AS AVAILABLE” basis. You should not construe our publication of the content on this website as a warranty or guarantee of the quality or availability of any travel package, plans, or advice.")}
                        </p>
                        <h6 >
                                {t("USE OF THIS WEBSITE")}
                        </h6>
                        <p style={{ marginTop: "2px" }}>
                                {t("This website is for your personal and non-commercial use.")} {t("Accordingly, as a condition of using this website, you agree not to use this website or its contents or information for any commercial or non-personal purpose (direct or indirect). Although you are welcome to view, use and download a limited copies of your travel itinerary (and related documents) for travel or services available on this website, you agree not to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, content, or services obtained from this website. You also warrant to us that you will not use this website for any purpose that is unlawful or prohibited by these terms, conditions, and notices.")}
                                {t("You warrant that you are at least 18 years of age and possess the legal authority to enter into this agreement and to use this website in accordance with all terms and conditions herein. You agree to supervise all usage by minors of this website under your name or account. You also warrant that all information supplied by you or members of your household in using this website is true and accurate. Without limitation, any speculative, false or fraudulent reservation or any reservation in anticipation of demand is prohibited. You agree that the products and services of this website shall be used only to make legitimate reservations or purchases for you or for another person for whom you are legally authorized to act. You understand that abuse of the products or services of this website may result in you being denied access to this website. You shall be completely responsible for payment/ reimbursement of all charges, fees, duties, taxes (including service tax), and assessments arising out of the use of this website.")}
                        </p>
                        <h6 >
                                {t("LIMITATION OF LIABILITY")}
                        </h6>
                        <p style={{ marginTop: "2px" }}>
                                {t("We shall make every commercially reasonable effort to ensure this website contains accurate and correct information.")}
                                {t("However, we do not warrant the validity, quality and pricing of the products and services offered at this website.")}
                        </p>
                        <h6 >{t("RIGHT TO CANCELLATION")}</h6>
                        <p style={{ marginTop: "2px" }}>
                                {t("The User expressly undertakes to provide to Getfares.com only correct and valid information while requesting for any services under this agreement, and not to make any misrepresentation of facts at all.")}
                                {t("Any default on part of the User would vitiate this agreement and shall disentitle the User from availing the services from Akbar Travels of India S.R.L. In case Getfares.com discovers or has reasons to believe at any time during or after receiving a request for services from the User that the request for services is either unauthorized or the information provided by the User or any of them is not correct or that any fact has been misrepresented by him, Getfares.com in its sole discretion shall have the unrestricted right to take any steps against the User(s), including cancellation of the bookings, etc. ")}
                                {t("without any prior intimation to the User. In such an event, Getfares.com shall not be responsible or liable for any loss or damage that may be caused to the User or any of them as a consequence of such cancellation of booking or services.")}
                                {t("The User unequivocally indemnifies Getfares.com of any such claim or liability and shall not hold Akbar Travels of India S.R.L responsible for any loss or damage arising out of measures taken by Akbar Travels of India S.R.L for safeguarding its own interest and that of its genuine customers")}
                        </p>
                        <h6 >{t("INDEMNITY")}</h6>
                        <p>
                                {t("You hereby indemnify us and shall continue to keep us indemnified including any of our officers, directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:")}
                                <p style={{ margin: 0, padding: 0 }}>(a) {t("your breach of these terms of use")};</p>
                                <p style={{ margin: 0, padding: 0 }}>(b) {t("your violation of any law or the rights of a third party;")} {t("or")}</p>
                                (c){t(" your use of this website.")}
                        </p>
                        <h6 >{t("SOFTWARE AVAILABLE ON THIS WEB SITE")}</h6>
                        <p style={{ marginTop: "2px" }}>
                                {t("Any software that is made available to download from this website ('Software') is our copyrighted work")}.
                                {t("Your use of the Software is governed by the terms of the end user license agreement, if any, which accompanies or is included with the Software ('License Agreement').")}
                        </p>
                        <h6 >{t("DEVICES")}</h6>
                        <p style={{ marginTop: "2px" }}>
                                {t("You agree that you will not use any robot, spider, other automatic device, or manual process to monitor or copy our Web pages or the content contained herein without our prior expressed written permission.")}
                                {t("You agree that you will not use any device, software or routine to interfere or attempt to interfere with the proper working of the our site or any listing, offer or transaction being conducted on our site. ")}
                                {t("You agree that you will not take any action that imposes an unreasonable or disproportionately large load on our infrastructure.")}
                        </p>
                        <h6 >{t("FEES PAYMENT")}</h6>
                        <p style={{ marginTop: "2px" }}>
                                {t("Getfares.com reserves the right to charge listing fees for certain listings, as well as transaction fees based on certain completed transactions using the services.")}
                                {t("hold Akbar Travels of India S.R.L further reserves the right to alter any and all fees from time to time, without notice.")}
                                {t("The User shall be completely responsible for all charges, fees, duties, taxes, and assessments arising out of the use of the services.")}
                                {t("In case, there is a short charging by Getfares.com for listing, services or transaction fee or any other fee or service because of any technical or other reason, it reserves the right to deduct/charge/claim the balance subsequent to the transaction at its own discretion.")}

                        </p>
                        <h6 >{t("PROPRIETARY RIGHTS")}</h6>
                        <p style={{ marginTop: "2px" }}>
                                {t("Getfares.com may provide the User with contents such as sound, photographs, graphics, video or other material contained in sponsor advertisements or information.")}
                                {t("This material may be protected by copyrights, trademarks, or other intellectual property rights and laws.")}
                                {t("The User may use this material only as expressly authorized by hold Akbar Travels of India S.R.L and shall not copy, transmit or create derivative works of such material without express authorization.")}
                                {t("The User acknowledges and agrees that he/she shall not upload, post, reproduce, or distribute any content on or through the Services that is protected by copyright or other proprietary right of a third party, without obtaining the permission of the owner of such right.")}
                                {t("Any copyrighted or other proprietary content distributed with the consent of the owner must contain the appropriate copyright or other proprietary rights notice.")}
                                {t("The unauthorized submission or distribution of copyrighted or other proprietary content is illegal and could subject the User to personal liability or criminal prosecution.")}
                        </p>
                        <h6 >{t("COPYRIGHT")}</h6>
                        <p style={{ marginTop: "2px" }}>
                                {t("All content on this website is Copyright of Getfares.com. Except for your information, personal, non-commercial use as authorized above, you may not modify, reproduce or distribute the content, design or layout of this website, or individual sections of the content, design or layout of this website without our express written permission.")}
                        </p>
                        <h6 >{t("GOVERNING LAW AND DISPUTE RESOLUTION")}</h6>
                        <p style={{ marginTop: "2px" }}>
                                {t("The use of this website shall be governed by the laws of Italy and shall be subject to the exclusive jurisdiction of the courts in Mumbai.")}
                                {t("Any dispute, difference, controversy or claim arising out of or relating to your use of this website shall be referred to arbitration of a sole arbitrator, who shall be appointed by [•], Getfares.com (Note to draft: Please provide the designation of the senior most person of Getfares.com).")}
                                {t("All such arbitration proceedings shall be held in Italy .")}
                                {t("All our products detailed in this website are sold subject to these Terms and Conditions and the same shall govern the contract between Client & Akbar Travels of India S.R.L.")}
                                {t("In the event of booking of any third party tours or travel products or services like flights, hotel, bus, rail, cruises, sightseeing etc, the Terms and Conditions specified by such Operator, including their payment schedule, cancellation, refund etc. shall be applicable, in addition to our Terms and Conditions.")}
                                {t("When you reserve or purchase travel services through Getfares.com, we must provide certain of your personal information to the airline, hotel, car - rental agency, travel agency or other involved third party to enable the successful fulfilment of your travel arrangements.")}
                                {t("The services are provided by independent entities that own or operate airlines, surface transportation, hotels, cruises, etc.")}
                                {t("Though we select reputed suppliers after we inspect their infrastructure, however, we have no direct control over such services or persons providing such services or their staff.")}
                                {t("In case of third party products displayed on our website, we take no responsibility for the contents, quality or safety of the product in any way.")}
                                {t("Akbar Travels of India S.R.L would not be in any way liable or responsible for any loss, damage or injury sustained by the User as a result of availing such products and services advertised by third parties on the website.")}
                                {t("However, in case a client is not satisfied with a particular service, we can take up the matter with the supplier if we are intimated immediately as per the process described in this document.")}
                                {t("As we book services in advance and are bound to honour the commitments as to payment to the suppliers, cancellation of services earmarked results in the Company losing money depending upon the time of communication of cancellation to the supplier and therefore, the cancellation schedule, which is based on the above amongst other factors becomes applicable on cancellation.")}

                        </p>
                </div>
        )
}