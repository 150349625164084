import React from "react";
import {
  Grid,
  Container,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import hero from "../../assets/hero.jpg";
import LuggageIcon from "@mui/icons-material/Luggage";
import feature from "../../assets/feature1.png";
import LockIcon from "@mui/icons-material/Lock";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ManIcon from "@mui/icons-material/Man";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FlightIcon from "@mui/icons-material/Flight";
import RecommendIcon from "@mui/icons-material/Recommend";
import { Table } from "@mui/material";
import get2ltaly from "../../assets/get2ltaly.jpg";
import get2ltalyit from "../../assets/get2ltalyit.jpg";
import { useTranslation } from "react-i18next";

function SignInHero() {
  const { t } = useTranslation();
  const goToRegister = () => {
    window.open("https://register.getfares.com/", "_blank");
  };
  return (
    <React.Fragment>
      <div id="Home" style={{ backgroundColor: "white", padding: "0 1vw" }}>
        {/* <Container> */}
        <Grid container>
          <Grid item xs={7} style={{ margin: "3vw 0" }}>
            {/* <img alt="italy ad banner" src="{hero}" style={{ width: "100%" }}/> */}

            <p
              style={{
                fontSize: "16px",
                color: "#a0a9b1",
                fontFamily: "Rubik,sans-serif",
                lineHeight: "1.8",
                marginTop: "0vw",
              }}
            >
              {t("EXPLORE GETFARES TODAY")}
            </p>
            <h1
              style={{
                fontSize: "2.5rem",
                color: "#343a40",
                fontFamily: "Rubik,sans-serif",
                lineHeight: "1.2",
                margin: "0px",
              }}
            >
              {t("top_text")}{" "}
              <span style={{ color: "#9f155a" }}> {t("GetFares")}</span>
            </h1>
            <p
              style={{
                color: "#a0a9b1",
                fontSize: "15px",
                fontFamily: "Rubik,sans-serif",
                lineHeight: "1.6",
              }}
            >
               {t("getfares_goal")}
            </p>
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <Button
                color="primary"
                variant="contained"
                style={{
                  textTransform: "none",
                  width: "140px",
                  fontWeight: "600px",
                }}
                onClick={goToRegister}
              >
                 {t("Register Here")}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                style={{
                  textTransform: "none",
                  backgroundColor: "#FFBFDF",
                  width: "140px",
                  marginLeft: "10px",
                  fontWeight: "600px",
                }}
              >
                 {t("Learn More")}
              </Button>
            </div>
            <div className="get2ItalyBannerDiv" style={{ marginTop: "3vh" }}>
              <Table>
                <a
                  href="https://www.get2italy.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  <img
                    className="get2ItalyBanner"
                    alt="https://www.get2italy.com/"
                    src={localStorage.getItem("i18nextLng") === 'en'  ? get2ltaly : get2ltalyit}
                  />
                </a>
                {/* <TableBody>
                <TableCell>
                  <p>
                    Please scan the QR or click on the above image for
                    enquiries.
                  </p>                   
                  <img
                    className="G2IRetailQR"
                    src={G2IRetailQR}
                    alt="G2IRetailQR"
                  ></img>
                </TableCell>
              </TableBody> */}
              </Table>
            </div>
          </Grid>
          <Grid item xs={5}>
            <img
              alt="company logo"
              style={{
                width: "42vw",
              }}
              src={hero}
            />
          </Grid>
        </Grid>
        {/* </Container> */}
      </div>
      <div
        id="Features"
        style={{ backgroundColor: "#f8fafe", padding: "50px" }}
      >
        <Container>
          <Box sx={{ justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              <h5
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  color: "#9f155a",
                  fontFamily: "Rubik,sans-serif",
                  textTransform: "uppercase",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "10px",
                }}
              >
                 {t("Features")}
              </h5>
              <h4
                style={{
                  fontSize: "1.5rem",
                  color: "#343a40",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                 {t("Key features of the product")}
              </h4>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              ></p>
            </div>
          </Box>
          <Box sx={{ justifyContent: "center" }} style={{ marginTop: "100px" }}>
            <Grid container>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <LockIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("How does single sign-on works?")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                     {t("The Trusted certificate been used to sign identity information that is being sent from the identity provider to the Getfares system.")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <AirplaneTicketIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("Automation")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                     {t("Ticketing and post-ticketing have been integrated into one system, such as ticketing and the ability to reissue, cancel, or void a ticket.")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <LuggageIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    {('Ancillary Airline products')}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                     {t("Selling seats can be transformed into bundling several products for a single price.")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <AccountBalanceWalletIcon
                        color="primary"
                        fontSize="large"
                      />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("Payments Made Easy")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                     {t("The GetFares website accepts payments in several currencies.Your account can be loaded in the currency of your choice.")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <ChatBubbleIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("24/7 Support")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                     {t("Using AI/ML,GetFares is able to comprehend what people are saying, respond appropriately, and assign an agent accordingly.")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <RecommendIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("Customer Friendly")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                     {t("Getfares is easy to use, simple, and trusted by customers.")}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <div id="Solutions" style={{ backgroundColor: "white", padding: "50px" }}>
        <Container>
          <Box sx={{ justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              <h5
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  color: "#9f155a",
                  fontFamily: "Rubik,sans-serif",
                  textTransform: "uppercase",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "10px",
                }}
              >
                 {t("Solutions")}
              </h5>
              <h4
                style={{
                  fontSize: "1.5rem",
                  color: "#343a40",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                 {t("Travel Management made easy")}
              </h4>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              ></p>
            </div>
          </Box>
          <Box sx={{ justifyContent: "center" }} style={{ marginTop: "100px" }}>
            <Grid container>
              <Grid item xs={6}>
                <div style={{ padding: "1.5rem" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <TouchAppIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("Control and visibility are at your fingertips")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                     {t("View expenses and reports from a user-friendly dashboard")}.
                  </p>
                </div>
              </Grid>
              <Grid item xs={6}>
                <img
                  alt="company logo"
                  style={{
                    width: "500px",
                    marginLeft: "100px",
                  }}
                  src={feature}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "50px" }}>
              <Grid item xs={6}>
                <img
                  alt="company logo"
                  style={{
                    width: "500px",
                  }}
                  src={feature}
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ padding: "1.8rem", marginLeft: "55px" }}>
                  <div>
                    <Button
                      style={{
                        backgroundColor: "#fbecf4",
                        padding: "12px",
                        borderRadius: "50px",
                      }}
                    >
                      <ManageSearchIcon color="primary" fontSize="large" />
                    </Button>
                  </div>
                  <h5
                    style={{
                      color: "#343a40",
                      fontSize: "18px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("Travel management made easy")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "15px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      margin: "10px",
                    }}
                  >
                     {t("Access actionable insights anytime, anywhere.")}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      <div style={{ backgroundColor: "#9f155a", padding: "50px" }}>
        <Container>
          <Box sx={{ justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              <h4
                style={{
                  fontSize: "1.5rem",
                  color: "White",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                 {t("Best Solutions for your Business")}
              </h4>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              >
                {t('With GetFares, customers can book flights and non-flight content online.')}
              </p>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              ></p>
            </div>
          </Box>
          <Box sx={{ justifyContent: "center" }} style={{ marginTop: "100px" }}>
            <Grid container>
              <Grid item xs={3}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <ManIcon sx={{ color: "#FFBFDF", fontSize: 60 }} />
                  </div>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "40px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                    {t(" 5000 +")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "20px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "0",
                      margin: "10px",
                    }}
                  >
                     {t("Clients")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <FlightIcon sx={{ color: "#FFBFDF", fontSize: 60 }} />
                  </div>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "40px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("600 +")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "20px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "0",
                      margin: "10px",
                    }}
                  >
                     {t("Airlines")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <PointOfSaleIcon sx={{ color: "#FFBFDF", fontSize: 60 }} />
                  </div>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "40px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("30 +")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "20px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "0",
                      margin: "10px",
                    }}
                  >
                     {t("POS")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div style={{ textAlign: "center", padding: "1.5rem" }}>
                  <div>
                    <CalendarTodayIcon
                      sx={{ color: "#FFBFDF", fontSize: 60 }}
                    />
                  </div>
                  <h5
                    style={{
                      color: "white",
                      fontSize: "40px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "1.6",
                      fontWeight: "500px",
                      margin: "10px",
                    }}
                  >
                     {t("1978")}
                  </h5>
                  <p
                    style={{
                      color: "#a0a9b1",
                      fontSize: "20px",
                      fontFamily: "Rubik,sans-serif",
                      lineHeight: "0",
                      margin: "10px",
                    }}
                  >
                     {t("Since")}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
      
      <div
        id="getstarted"
        style={{ backgroundColor: "white", padding: "50px" }}
      >
        <Container>
          <Box sx={{ justifyContent: "center" }}>
            <div style={{ textAlign: "center" }}>
              <h5
                style={{
                  fontSize: "14px",
                  letterSpacing: "1px",
                  color: "#9f155a",
                  fontFamily: "Rubik,sans-serif",
                  textTransform: "uppercase",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "10px",
                }}
              >
                 {t("Register Here")}
              </h5>
              <h4
                style={{
                  fontSize: "1.5rem",
                  color: "#343a40",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.2",
                  fontWeight: "500px",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                 {t("An excellent journey begins here,")}
              </h4>
              <p
                style={{
                  color: "#a0a9b1",
                  fontSize: "15px",
                  fontFamily: "Rubik,sans-serif",
                  lineHeight: "1.6",
                  margin: "10px",
                }}
              >
                 {t("The mission of our company is to help businesses achieve their goals.")}
              </p>
            </div>
          </Box>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <TextField
                id="outlined-basic"
                label={t("enter your email here")}
                variant="outlined"
                style={{ width: "500px" }}
              />
            </div>
            <div style={{ marginLeft: "20px" }}>
              <Button color="primary" variant="contained">
                 {t("Register Here")}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default SignInHero;
