import React, { useState, useRef, useEffect } from "react";
import "./SearchImportBookingCard.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import supplierList from "../../../data/Supplier";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';
import { FormLabel } from "@mui/joy";
import clientFilterOptions from "../../../functions/clientFilterOptions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function SearchImportBookingCard({
  setsupplierName,
  setPCC,
  setPNR,
  setclientId,
  clientProfiles,
  pccList,
  loadPNRDetails,
  loadPCCDetails,
  clientId,
  supplierName,
  pcc,
  pnr,
  setClientAvailableBalance,
  clientAvailableBalance,
  clientBalanceSH,
  showLoader,
  setShowLoader,
  clientCurrency,
  setBookingType,
  bookingType,
  setReissue,
  passengerFirstName,
  passengerLastName,
  setPassengerFirstName,
  setPassengerLastName,
  productType, mode,
}) {
  const [disableImport, setDisableImport] = useState(false)
  const searchPnrSubmit = useRef();
  const [checked, setChecked] = useState(false);
  const handleSearchSubmit = () => {
    searchPnrSubmit.current && searchPnrSubmit.current.submit();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (clientId > 0) {
      getClientBalance();
    }

  }, [clientId]);

  useEffect(() => {
    if (clientId && supplierName && pcc && pnr) {
      console.log('go', clientId, supplierName, pcc, pnr)
      setDisableImport(false)
    }
    else {
      setDisableImport(true)
    }
  }, [clientId, supplierName, pcc, pnr]);

  async function getClientBalance() {
    if (clientId !== "") {
      await axios
        .get(
          process.env.REACT_APP_CORE_URL +
          "api/Configuration/Transactions/GetAccountBanalce?clientId=" +
          clientId
        )
        .then((response) => {
          setClientAvailableBalance(response.data.balance);
        });
    } else {
      setClientAvailableBalance(0);
    }
  }

  const classes = useStyles();
  return (
    <ValidatorForm
      ref={searchPnrSubmit}
      onError={(errors) => console.log(errors)}
      onSubmit={() => loadPNRDetails()}
    >
      <div className="create-search-contents">
        {mode !== "importPnr" && <div className="p-3">
          <div className="importTripTypeDiv">
            <FormControl>
              <FormLabel>{(t("Issuance Type"))}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Fresh"
                row
                name="radio-buttons-group"
                style={{ paddingLeft: "10px" }}
              >
                <FormControlLabel
                  value="Fresh"
                  onClick={() => { setReissue((bookingType) => false) }}
                  control={<Radio />}
                  label={(t("Fresh"))}
                />
                <FormControlLabel
                  value="ReIssue"
                  onClick={() => { setReissue((bookingType) => true) }}
                  control={<Radio />}
                  label={(t("Re-Issue"))}
                />
              </RadioGroup>
            </FormControl>
            <FormControl>
              <FormLabel>{(t("Booking Type"))}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="fitBooking"
                row
                name="radio-buttons-group"
                style={{ paddingLeft: "10px" }}
              >
                <FormControlLabel
                  value="fitBooking"
                  onClick={() => { setBookingType((bookingType) => "fitBooking") }}
                  control={<Radio />}
                  label={(t("FIT Booking"))}
                />
                <FormControlLabel
                  value="groupBooking"
                  onClick={() => { setBookingType((bookingType) => "groupBooking") }}
                  control={<Radio />}
                  label={(t("Group Booking"))}
                />
              </RadioGroup>
            </FormControl>
          </div>{" "}
        </div>}
        <div className="add-search-if-items-container">
          <div className="add-search-if-item flex-1-input margin-right-16">
            {/* <Autocomplete
              id="combo-box-demo"
              value={
                clientProfiles &&
                (clientProfiles.find((v) => v.id === clientId) || null)
              }
              onChange={(event, newValue) => {
                setclientId(newValue ? newValue.id : "");
              }}
              filterSelectedOptions={true}
              filterOptions={clientFilterOptions}
              options={clientProfiles}
              getOptionLabel={(option) => option.clientName}
              // style={{ width: 500, padding: "30px" }}
              renderInput={(params) => (
                <TextValidator
                  className="bg-light"
                  value={clientId}
                  {...params}
                  label="Select Client"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  validators={["required"]}
                  errorMessages={["required"]}
                />
              )}
            /> */}
            <Autocomplete
              id="Client select"
              value={
                clientProfiles &&
                (clientProfiles.find((v) => v.id === clientId) || null)
              }
              onChange={(event, newValue) => {
                setclientId(newValue ? newValue.id : "");
              }}
              options={clientProfiles}
              getOptionLabel={(option) => `${option.clientName} (${option.id})`}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  option.clientName.toLowerCase().includes(inputValue.toLowerCase().trim()) ||
                  option.id.toString().includes(inputValue.trim())
                )
              }
              renderInput={(params) => (
                <TextValidator
                  className="bg-light"
                  {...params}
                  value={clientId}
                  label={(t("Client"))}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  validators={["required"]}
                  errorMessages={["required"]}
                />
              )}
            />
          </div>
          {
            productType === "Flight" &&
            <>
              <div className="add-search-if-item flex-1-input margin-right-16">
                <Autocomplete
                  id="supplier"
                  value={
                    supplierName !== undefined
                      ? supplierList.find((v) => v.value === supplierName)
                      : ""
                  }
                  onChange={(event, newValue) => {
                    setsupplierName(newValue ? newValue.value : "");
                    loadPCCDetails(newValue ? newValue.value : "");
                  }}
                  options={supplierList}
                  getOptionLabel={(option) =>
                    option.value + " (" + option.label + ")"
                  }
                  renderInput={(params) => (
                    <TextValidator
                      className="bg-light"
                      {...params}
                      label={(t("Supplier"))}
                      value={
                        supplierName !== undefined
                          ? supplierList.find((v) => v.value === supplierName)
                          : ""
                      }
                      variant="outlined"
                      color="secondary"
                      size="small"
                      validators={["required"]}
                      errorMessages={["required"]}
                    />
                  )}
                />
              </div>
              <div className="add-search-if-item flex-1-input margin-right-16">
                <Autocomplete
                  id="Pseudo Code"
                  value={
                    pcc !== undefined ? pccList.find((v) => v.value === pcc) : ""
                  }
                  onChange={(event, newValue) => {
                    setPCC(newValue ? newValue.value : "");
                  }}
                  options={pccList}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextValidator
                      className="bg-light"
                      {...params}
                      value={
                        pcc !== undefined
                          ? pccList.find((v) => v.value === pcc)
                          : ""
                      }
                      label={(t("Pseudo Code"))}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      validators={["required"]}
                      errorMessages={["required"]}
                    />
                  )}
                />
              </div>
            </>
          }{
            productType === 'Rail' &&
            <>
              <div className="add-search-if-item flex-1-input margin-right-16">
                <TextValidator
                  className="bg-light"
                  label={(t("Supplier"))}
                  value={supplierName}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  disabled
                // validators={["required"]}
                // errorMessages={["required"]}
                />

              </div>
              <div className="add-search-if-item flex-1-input margin-right-16">
                <TextValidator
                  className="bg-light"
                  value={pcc}
                  label={(t("Pseudo Code"))}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  disabled
                // validators={["required"]}
                // errorMessages={["required"]}
                />
              </div>
            </>

          }
          {
            productType === 'Rail' &&
            (
              <>
                <div className="add-search-if-item flex-1-input margin-right-16">
                  <TextValidator
                    className="bg-light"
                    variant="outlined"
                    label={(t("First Name"))}
                    size="small"
                    color="secondary"
                    value={passengerFirstName}
                    validators={["required", "matchRegexp:^[a-zA-Z]+( [a-zA-Z]+)*$"]}
                    errorMessages={["required", "No special characters allowed"]}
                    onChange={(event) => setPassengerFirstName(event.target.value)}
                  />
                </div>
                <div className="add-search-if-item flex-1-input margin-right-16">
                  <TextValidator
                    variant="outlined"
                    className="bg-light"
                    label={(t("Last name"))}
                    size="small"
                    color="secondary"
                    value={passengerLastName}
                    validators={["required", "matchRegexp:^[a-zA-Z]+( [a-zA-Z]+)*$"]}
                    errorMessages={["required", "No special characters allowed"]}
                    onChange={(event) => setPassengerLastName(event.target.value)}
                  />
                </div>
              </>
            )
          }
          <div className="add-search-if-item flex-1-input margin-right-16">
            <TextValidator
              className="bg-light"
              variant="outlined"
              label="PNR"
              size="small"
              color="secondary"
              value={pnr}
              validators={["required", "matchRegexp:^[a-zA-Z0-9]+$"]}
              errorMessages={["required", "No special characters allowed"]}
              onChange={(event) => setPNR(event.target.value)}
            />
          </div>
        </div>
        {/* {clientAvailableBalance > 0 ? (
          <> */}
        <div className="text-start">
          <strong>
            {(t("Client Available Balance"))}: {clientCurrency ? clientCurrency : " "}{" "}
            {clientAvailableBalance}
          </strong>
        </div>
        {/* </>
        ) : (
          ""
        )} */}
        <div className="importbooking-add-search-button-container importbooking-button-table-padding">
          {/* <div className="add-search-button" onClick={() => loadPNRDetails()} >Search PNR</div> */}
          {mode !== "importPnr" ?
            (<Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                //marginLeft: "405px",
                // marginRight: "25px",
                // marginBottom: "25px",
                // width: "500px",
              }}
              type="submit"
            // onClick={() => handleSearchSubmit()}
            >
              {(t("Search PNR"))}
            </Button>) :
            (<Button
              variant="outlined"
              color="primary"
              size="large"
              style={{
                textTransform: "none",
                //marginLeft: "405px",
                // marginRight: "25px",
                // marginBottom: "25px",
                // width: "500px",
              }}
              // type="submit"
              onClick={() => loadPNRDetails()}
              disabled={disableImport}
            >
              {(t("Import"))}
            </Button>)
          }

        </div>
      </div>
    </ValidatorForm>
  );
}
