const supplier = [
  { value: "Galileo", label: "1G" },
  { value: "Sabre", label: "1S" },
  { value: "Travelfusion", label: "TF" },
  { value: "Amadeus", label: "1A" },
  { value: "ACH", label: "1C" },
  { value: "AkbarRomeNDC", label: "AR" },
  { value: "AkbarHoustonNDC", label: "AH" },
  { value: "AkasaAir", label: "QP" },
  { value: "ClearTrip", label: "CT" },
  { value: "Brightsun", label: "BS" },
  { value: "Herms", label: "HM" },
  { value: "Tripovy", label: "TV" },
  { value: "Tripjack", label: "TJ" },
  { value: "GetFares", label: "GF" },
  { value: "NavitaireSpiceJet", label: "SJ" },
  { value: "NavitaireIndigo", label: "IG" },
  { value: "NavitaireGoAir", label: "GA" },
  { value: "NavitaireAirAsia", label: "AA" },
  { value: "khalti", label: "KT" },
  { value: "RodexTrip", label: "RT" },
  { value: "TripinDeal", label: "TD" },
  { value: "Benlabs", label: "BL" },
  { value: "Duffel", label: "DF" },
  { value: "BenzyIN", label: "BI" },
  { value: "BenzyUAE", label: "BU" },
  { value: "Citibond", label: "CB" },
  { value: "Akbar", label: "AK" },
  { value: "Jazeera", label: "J9" },
  { value: "AirArabia", label: "G9" },
  { value: "YetiAirlines", label: "YT" },
  { value: "PETTour", label: "PT" },
  { value: "Flyadeal", label: "F3" },
  { value: "ATrip", label: "AT" },
  { value: "Biyaheko", label: "BK" },
  { value: "FlightRoutes", label: "FR" },
  { value: "Kiwi", label: "KW" },
  { value: "Dida", label: "DD" },
  { value: "PKFare", label: "PK" },
  { value: "RateHawk", label: "RH" },
  { value: "SabreRest", label: "1SR" },
  { value: "Akbar online KSA", label: "AP" },
  { value: "ItaloRail", label: "ITR" },
  { value: "Easyres", label: "ER" },
  { value: "AmadeusNDC", label: "1AN" },
  { value: "AERTicket", label: "AER" },
];
export default supplier;
